<template>
  <div class="content">
    <div class="container-fluid">
      <stock-man-table :objects="objects" :header="'Склад'"
                       :downloading="downloading"
                       :header-title="'Остатки по складу'" :hide-price="true"
                       @update="getPlansLeft"></stock-man-table>
    </div>
  </div>
</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'

export default {
  name: 'stock-man',
  data () {
    return {
      objects: [],
      key: authHeader(),
      filter: new Map,
      tableKey: 0,
      downloading: {state: false, history: {state: false}},

    }
  },
  methods: {
    async getPlansLeft() {
      this.objects = [];
      await this.$repo.getList('stockcell/', 'cell', this.objects, null, this.downloading);
    }
  },
  created() {
    this.getPlansLeft();
  }
}
</script>
<style>
</style>
