<template>

    <div v-if="downloading && !downloading.data && !downloading.single" class="row" style="height: 80px; margin: 0">
      <div class="col-12" style="background-color: lightgrey">
        <div class="loader">
          <div class="inner one"></div>
          <div class="inner two"></div>
          <div class="inner three"></div>
        </div>
      </div>
    </div>
    <div v-else-if="downloading && !downloading.data && downloading.single">
      Загрузка...
    </div>
    <div v-else-if="downloading && downloading.data">
      <div class="row" style="margin: 20px 0;background: #fffafa; font-size: small">
        <div class="col-md-12">
          <div class="row" style="height: 2rem; background: #1f6962; text-align: right; padding-top: 0.5rem; padding-right: 0.6rem; color: white">
            <div class="col-md-12">
              <i v-on:click="closeMsg" class="nc-icon nc-simple-remove"/>
            </div>
          </div>
          <div class="row" style="padding-top: 1rem; text-align: center">
            <div class="col-md-1"/>
            <div class="col-md-10">
              <span v-for="(text, index) in downloading.data.header" :key="index">{{text}}<br/></span>
              <button v-if="downloading.data.keys" v-for="(item, index) in downloading.data.keys" :key="index"
                      class="btn btn-light btn-fill btn-usm" style="margin: 20px 10px; font-size: small"
                      v-on:click="returnAction(item.action)">
                {{ item.name }}
              </button>
            </div>
            <div class="col-md-1"/>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

export default {

  props:{
    downloading: null,
    single: false,
  },
  methods: {
    closeMsg() {
      this.downloading.state = false;
      this.downloading.data = null;
    },
    returnAction(action) {
      if ('close' !== action) {
        this.$emit(action);
      }
      this.closeMsg();
    }
  },
}
</script>
<style>
</style>
