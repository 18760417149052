<template>
  <div class="modal" :id="id">
    <div class="modal-content-25">
      <div class="row" style="width: 100%; height: 120%; background: #fffafa; font-size: small">
        <div class="col-md-12">
          <div class="row" style="height: 2rem; background: #1f6962; text-align: right; padding-top: 0.5rem; padding-right: 0.6rem; color: white">
            <div class="col-md-12">
              <i v-on:click="closeModal" class="nc-icon nc-simple-remove"/>
            </div>
          </div>
          <div class="row" style="padding-top: 1rem; text-align: center">
            <div class="col-md-1"/>
            <div class="col-md-10">
              <span v-for="(text, index) in header" :key="index">{{text}}<br/></span>
            </div>
            <div class="col-md-1"/>
          </div>
        </div>
      </div>
      <div class="row" style="width: 100%; height: 30%; background: #fffafa; font-size: small">
        <div class="col-md-12" style="text-align: center">
          <button v-if="bottomKeys" v-for="(item, index) in bottomKeys" :key="index"
                  class="btn btn-light btn-fill btn-usm" style="margin-right: 10px; margin-left: 10px; font-size: small"
                  v-on:click="returnAction(item.action)">
            {{ item.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      header: null,
      call: false,
      bottomKeys: null,
      startId: null
    },
    data () {
      return {
        id: null
      }
    },
    methods: {
      callModal() {
        let modal = document.getElementById(this.id);
        modal.style.display = "block";
      },
      closeModal() {
        let modal = document.getElementById(this.id);
        modal.style.display = "none";
        this.$emit('close');
      },
      returnAction(action) {
        if ('close' === action) {
          this.closeModal();
        } else {
          this.$emit(action);
          this.closeModal();
        }
      }
    },
    mounted() {
      if (this.startId) {
        this.id = this.startId;
      } else {
        this.id = this._uid;
      }
    },
    watch: {
      call: function() {
        if (this.call) {
          this.callModal();
          this.$emit('called')
        }
      }
    }
  }
</script>
<style>
</style>
