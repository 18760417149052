<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id > 0" class="row row-title" v-on:click="fullView">
      <div class="col-8">
        {{ object.name}}
      </div>
      <div class="col-4">
        {{ asDate }}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher">
      <div class="col-3">
        <a class="input-title">
          Закупка
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.name + ' от ' + asDate }}
      </div>
      <div v-else class="col-9">
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Закупка от ' + asDate" :select="select"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="state = 'edit'" @select="selectObject">
                Header
              </full-view-header>

              <div class="row">
                <div class="col-3">
                  Название:
                </div>
                <div class="col-9">
                  {{ object.name }}
                </div>
              </div>
              <div v-if="!select">

                <div class="row enquire-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showEnquire" v-on:click="getEnquire">Заявки</span>
                    <enquiries-table v-if="showEnquire" @element="hideElement('enquire')" :header="'Заявки'"
                                     :downloading="downloading.enquiry"
                                     :header-title="'Заявки испоняемые в закупке'" @select="addEnquire"
                                     :objects="enquires" :parent="asParent">Enquiries</enquiries-table>
                  </div>
                </div>

                <div class="row light-grey-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showEnquireConsolidate" v-on:click="getEnquireConsolidate">Формирование запроса поставщикам</span>
                    <supplier-request-table v-if="showEnquireConsolidate" @element="hideElement('enquire_consolidate')"
                                            :downloading="downloading.enquireConsolidate"
                                            :parent="asParent"
                                            :header="'Формирование запроса поставщикам'"
                                            :header-title="'Консолидированный список по заявке'"
                                            :objects="enquireConsolidate" >EnquiresConsolidated</supplier-request-table>
                  </div>
                </div>

                <div class="row document-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showDocument" v-on:click="getDocument">Входящие документы</span>
                    <div v-if="showDocument">
                      <div class="row">
                        <div class="col-md-12">
                          <button class="btn btn-light btn-fill btn-sm float-right margin-right-icon"
                                  style="margin: 5px" v-on:click="addExistingDocument">
                            Привязать документ
                          </button>

                          <div v-if="showDocumentSelect" :id="id" class="full-width">
                            <document-table v-bind:objects="selectPool" v-bind:header="'Документы'" @add="addDocument"
                                            :downloading="downloading.ds"
                                            @closeModal="showDocumentSelect = false"
                                            v-bind:header-title="'Документы на отгрузки и выполненные работы'" :select="true"></document-table>

                          </div>

                        </div>
                      </div>
                      <document-table @element="hideElement('document')" :header="'Документы'"
                                      :header-title="'Входящие документы по закупке'"
                                      :downloading="downloading.document"
                                      :call-select="callSelectDocument" @callSelectBack="callSelectDocument = false"
                                      @add = "addDocument"
                                      :objects="documents" :parent="asParent">Documents</document-table>
                    </div>
                  </div>
                </div>

                <div class="row note-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showNote" v-on:click="getNote">Заметки</span>
                    <note-table v-if="showNote" @element="hideElement('note')" :header="'Заметки'"
                                :downloading="downloading.note"
                                :header-title="'Заметки по заявке'" :show-all="true"
                                :notes="notes" :parent="asParent">Notes</note-table>
                  </div>
                </div>

                <div class="row lnk-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showLnk" v-on:click="getLnk">Счета и прочие документы</span>
                    <lnk-table v-if="showLnk" @element="hideElement('lnk')" :header="'Файлы'"
                               :downloading="downloading.lnk"
                               :header-title="'Счета и прочие документы по заявке'"
                               :objects="lnks" :parent="asParent">Lnks</lnk-table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Описание закупки:'" class="full-width"></input-row>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
        <purchases-table v-bind:objects="selectPool" v-bind:header="'Закупки'" @select="selectObjectFromTable"
                         :downloading="downloading.select"
                         @closeModal="closeModal"
                         v-bind:header-title="'Закупки материалов и комплектующих'" :select="select"></purchases-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {DOCUMENT, ENQUIRE, LNK, NOTE, PURCHASE} from "@/repository/repository";

export default {
  components: {
    CompaniesTable,
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    }
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      enquires: [],
      showEnquire: false,
      documents: [],
      showDocument: false,
      notes: [],
      showNote: false,
      lnks: [],
      showLnk: false,
      enquireConsolidate: [],
      showEnquireConsolidate: false,
      id: null,
      selectPool: [],
      asParent: {},
      selectZone: 0,
      callSelectDocument: false,
      viewKey: 0,
      flag: this.$flag,
      showSelect: false,
      showDocumentSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}, enquiry: {state: false},
        document: {state: false, ds: {state: false}}, note: {state: false}, lnk: {state: false}, enquireConsolidate: {state: false},
        request: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    create: false
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else if (this.create && !this.object) {
        this.createModel();
      } else {
        this.fullView()
      }
    },
    async fullView() {
      let id = this.object.id;
      if (id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
        if (this.create) {
          this.object = null;
          this.state = 'text';
        }
      }
    },
    async createModel() {
      let url = 'purchase/model';
      if (this.parent) {
        url += '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.object = await this.$repo.justGetData(url, this.downloading);
      this.state = 'edit';
    },
    async getNote() {
      this.notes = [];
      this.showNote = true;
      await this.$repo.getList('purchase/note/' + this.object.id, NOTE, this.notes, {key: 'date', asc: false}, this.downloading.note);
    },
    async getEnquire() {
      this.enquires = [];
      this.showEnquire = true;
      await this.$repo.getList('purchase/enquire/' + this.object.id, ENQUIRE, this.enquires, {key: 'date', asc: true}, this.downloading.enquiry);
    },
    async getDocument() {
      this.documents = [];
      this.showDocument = true;
      await this.$repo.getList('purchase/document/' + this.object.id, DOCUMENT, this.documents, {key: 'date', asc: true}, this.downloading.document);
    },
    async getLnk() {
      this.lnks = [];
      this.showLnk = true;
      await this.$repo.getList('purchase/lnk/' + this.object.id, LNK, this.lnks, {key: 'date', asc: true}, this.downloading.lnk);
    },
    async getEnquireConsolidate() {
      this.enquireConsolidate = [];
      this.showEnquireConsolidate = true;
      await this.$repo.justGetList('purchase/enquire/consolidate/' + this.object.id, this.enquireConsolidate, null, this.downloading.enquireConsolidate);
    },
    async addExistingDocument() {
      this.selectPool = [];
      this.showDocumentSelect = true
      await this.$repo.justGetList('document/purchase/' + this.object.id, this.selectPool, null, this.downloading.document.ds);
    }
    ,
    hideElement(element) {
      if (element === NOTE) {
        this.showNote = false;
        this.notes = [];
      } else if (element === ENQUIRE) {
        this.showEnquire = false;
        this.enquires = [];
      } else if (element === DOCUMENT) {
        this.showDocument = false;
        this.documents = [];
      } else if (element === LNK) {
        this.showLnk = false;
        this.lnks = [];
      } else if (element === 'enquire_consolidate') {
        this.showEnquireConsolidate = false;
        this.enquireConsolidate = [];
      }
    },
    async addDocument(document) {
      this.showDocumentSelect = false;
      this.documents.unshift(await this.$repo.save('document/purchase/' + this.object.id, DOCUMENT, document, this.downloading));
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setSupplier(company) {
      this.object.supplier = company;
      this.selectZone += 1;
    },
    save() {
      this.$repo.uniSave(this, PURCHASE, 'purchase/', this.downloading);
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    setImage(image) {
      this.object.image = image;
    },
    addEnquire(enquire) {/*
      this.enquires.unshift(enquire);
      console.log(666);
      console.log(enquire);
      this.viewKey++;*/
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectBounds = this.getBounds();
      this.callModal();
      await this.$repo.getSelectPoll(PURCHASE, this.selectBounds, this.selectPool, this.downloading.select);
    },
    getBounds() {
      if (this.parent) {
        return [{includes: true, type: this.parent.type, id: this.parent.id}]
      } else {
        return undefined;
      }
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(PURCHASE, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (!this.object) {
      let url = 'purchase/model';
      if (this.parent) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.object = await this.$repo.justGetData(url, this.downloading);
    }
    if (!this.default_state){
      this.default_state = this.start_state;
    }
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: PURCHASE, id: this.object.id};
      }
    },
    state: function (value) {
      this.$repo.watchState(this, PURCHASE, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, PURCHASE, val);
    }
  }
}
</script>
<style>
</style>
