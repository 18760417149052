<template>
  <div>
    <div class="content">
      <div class="container-fluid">
        <certificate-counter-table :header="'Счетчики паспортов качества'"
                                   :downloading="downloading.counter"
                                   :objects="counters"
                                   :header-title="'Счетчики нумерации паспортов качества с привязкой к типам продукции'"
                                   @element="updateData"
        >
          Certificate counter
        </certificate-counter-table>
      </div>
    </div>

    <div class="content">
      <div class="container-fluid">
        <certificate-pattern-table :header="'Шаблоны'"
                                   :downloading="downloading.pattern"
                                   :objects="patterns"
                                   :header-title="'Шаблоны паспортов качества с привязкой к типам продукции'"
                                   @element="updateData"
        >
          Certificate pattern
        </certificate-pattern-table>
      </div>
    </div>

  </div>

</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'
import {CERTIFICATE_COUNTER, CERTIFICATE_PATTERN, warning} from "@/repository/repository";

export default {
  data () {
    return {
      counters: [],
      patterns: [],
      downloading: {counter: {state: false}, pattern: {state: false}},
    }
  },
  methods: {
    async updateData(flag) {
      if (flag === CERTIFICATE_COUNTER) {
        await this.getCounters();
      } else if (flag === CERTIFICATE_PATTERN) {
        await this.getPatterns();
      }
    },
    async getCounters() {
      await this.$repo.getList(CERTIFICATE_COUNTER + '/', CERTIFICATE_COUNTER, this.counters, 'name', this.downloading.counter)
    },
    async getPatterns() {
      await this.$repo.getList(CERTIFICATE_PATTERN + '/', CERTIFICATE_PATTERN, this.patterns, 'name', this.downloading.pattern)
    }
  },
  watch: {

  },
  async mounted() {
    await this.getCounters();
    await this.getPatterns();
  }
}
</script>
<style>
</style>
