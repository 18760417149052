<template>
  <div class="row">
    <div class="col-12">
      <card body-classes="table-full-width" style="margin: 5px">
        <base-input v-model="textSearch" style="margin-left: 15px; width: 50%;"></base-input>
        <template slot="header">
          <h4 class="card-title unselectable" v-on:click="getObjects">{{ header }}</h4>
          <p class="card-category unselectable">{{ headerTitle }}</p>
        </template>
        <table class="table table-hover table-striped">
          <thead>
          <slot name="columns">
            <tr>
              <th class="row-title">
                <div v-if="realStock" class="row" style="width: 100%">
                  <div class="col-4 unselectable">
                        <span v-on:click="sortBy = {key: 'item.name', asc: !sortBy.asc}">
                          Номенклатура
                        </span>
                    <item-category @filter="catchFilter" v-bind:field="'item.category'"> Category Selector </item-category>
                  </div>
                  <div class="col-2 unselectable">
                        <span v-on:click="sortBy = {key: 'stock.contact.name', asc: !sortBy.asc}">
                          Размещено в работу / получено
                        </span>
                  </div>
                  <div class="col-2 unselectable">
                        <span>
                          В производстве / изготовлено
                        </span>
                  </div>
                  <div class="col-2 unselectable">
                    <span v-on:click="sortBy = {key: 'stockData', asc: !sortBy.asc}">
                      Складские данные / учетные данные /
                    </span>
                    <span v-on:click="sortBy = {key: 'item.minimum', asc: !sortBy.asc}">
                          неснижаемый ост.
                    </span>
                  </div>
                  <div class="col-2 unselectable">
                        <span>
                          Запрошено по счетам
                        </span>
                  </div>
                </div>
              </th>
            </tr>
          </slot>
          </thead>
          <tbody :key="tableKey">
          <tr v-for="(object, index) in objects" :key="index" v-if="fitFilter(object)">
            <td>
              <div class="row full-width">
                <smd :start_object="object"></smd>
              </div>
            </td>
          </tr>
          <tr v-if="downloading && downloading.state">
            <downloading :downloading="downloading">
              Downloading
            </downloading>
          </tr>
          </tbody>
        </table>
      </card>
    </div>
  </div>
</template>
<script>
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter, textContainsText} from '@/services'
import SidebarPlugin from "@/components";

export default {
  components: {
    SidebarPlugin,
    LTable,
    Card
  },
  props: {
    header: '',
    headerTitle: ''
  },
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'item.name', asc: true},
      filter: new Map,
      tableKey: 0,
      realStock: true,
      textSearch: '',
      objects: [],
      downloading: {state: false, history: {state: false}},
    }
  },
  methods: {
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter) && textContainsText(object.item.name, this.textSearch);
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    selectObject(object) {
      this.$emit('select', object);
    },
    headerClick() {
      if (this.select) {
        this.objects = []
        this.$emit('closeModal');
      } else {
        this.$emit('element');
        this.$emit('update');
      }
    },
    async getObjects() {
      this.objects = await this.$repo.justGetData('stockcell/sm_data/', this.downloading);
      this.tableKey++;
    }
  },
  async mounted() {
    await this.getObjects();
  },
  watch: {
    sortBy: function () {
      this.sort()
    }
  }
}
</script>
<style>
</style>
