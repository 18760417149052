import { userService } from '@/services/user.service';

export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken && user.expired > new Date().getTime() - 600000) {
        return { 'Authorization': 'Bearer ' + user.accessToken };
    } else {
        localStorage.removeItem('user');
        return {};
    }
}
