<template>
  <div class="full-width">
    <div v-if="object" class="row row-title">
      <div class="col-4">
        {{ object.item.name }}
      </div>
      <div class="col-4">
        {{ object.item.category.name }}
      </div>
      <div class="col-2">
        <active-input-element :object="object" :field="'value'" :number="true"/>
      </div>
      <div class="col-2">
        {{ object.item.measure }}
      </div>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import ItemCategorySelect from "../selectable/ItemCategorySelect";

export default {
  data () {
    return {
      key: authHeader()
    }
  },
  props: {
    object: {},
    state: '',
    select: false,
    parent: null
  },
  methods: {

  }
}
</script>
<style>
</style>
