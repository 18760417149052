<template>

  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object" class="row row-title" v-on:click="fullView">
      <div class="col-3">
        {{ object.name }}
      </div>
      <div class="col-1">
        {{ toDate(object.date) }}
      </div>
      <div class="col-2">
        {{ object.consumer ? (object.consumer.name+'('+object.consumer.contact.name+')') : '-'}}
      </div>
      <div class="col-2">
        {{ object.supplier ? object.supplier.name : '-'}}
      </div>
      <div class="col-3">
        <span :style="object.confirmed ? 'color: #0a6715' : 'color: red'">{{object.confirmed ? 'Подтвержден /': 'Х /'}}</span>
        <span :style="object.signed ? 'color: #0a6715' : 'color: red'">{{object.signed ? ' Подписан /': ' Х /'}}</span>
        <span :style="object.order ? 'color: #0a6715' : 'color: red'">{{object.order ? ' В работе': ' Х'}}</span>
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          Спецификация:
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.name }}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Спецификация'" :select="select"
                                :historical="true"
                                @regularState="state = default_state ? default_state : start_state"
                                :extra-keys="object.order ? null : [{action: 'create_order', name: 'Создать заказ'}]"
                                @create_order="createOrder"
                                @history="irc.controlHistory()"
                                @editState="irc.startEdit()" @select="selectObject">
              </full-view-header>

              <row :header="'Наименование:'" :value="object.name" :copyable="true"></row>

              <row :header="'Дата ввода в систему:'" :value="toDate(object.date)"></row>

              <div class="row">
                <div class="col-3">
                  Текущий статус:
                </div>
                <div class="col-9">
                  <span :style="object.confirmed ? 'color: #0a6715' : 'color: red'">{{object.confirmed ? 'Подтвержден /': 'Х /'}}</span>
                  <span :style="object.signed ? 'color: #0a6715' : 'color: red'">{{object.signed ? ' Подписан /': ' Х /'}}</span>
                  <span :style="object.order ? 'color: #0a6715' : 'color: red'">{{object.order ? ' В работе': ' Х'}}</span>
                </div>
              </div>

              <div v-if="object.consumer" class="row stock-bcg margin-for-inner-obj">
                <stock :start_object="object.consumer" :header="'Заказчик'"
                       :start_state="'text'" :default_state="'text'"></stock>
              </div>

              <div v-if="object.supplier"class="row company-bcg margin-for-inner-obj">
                <company :start_object="object.supplier" :header="'Поставщик'"
                         :start_state="'text'" :default_state="'text'"></company>
              </div>

              <div v-if="object.order" class="row order-bcg margin-for-inner-obj">
                <order :start_object="object.order" :header="'Заказ'"
                       :start_state="'text'" :default_state="'text'"></order>
              </div>

              <lnk v-if="object.document && object.document.id > 0" :lnk_start="object.document" :state_start="'asImage'"></lnk>

              <div v-if="!select">
                <div class="row lnk-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showLnk" v-on:click="getLnk(true)">Файлы</span>
                    <lnk-table v-if="showLnk" @element="hideElement" :header="'Файлы'"
                               :downloading="downloading.lnk"
                               :header-title="'Связные файлы и изображения'"
                               :objects="lnks" :parent="asParent">Lnk</lnk-table>
                  </div>
                </div>
                <div class="row note-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showNote" v-on:click="getNote(true)">Заметки</span>
                    <note-table v-if="showNote" @element="hideElement" :header="'Заметки'"
                                :downloading="downloading.note"
                                :header-title="'Заметки и прочее'" :showAll="true"
                                :notes="notes" :parent="asParent">Content</note-table>
                  </div>
                </div>

                  <event-table v-if="irc.history.show && irc.history.data"
                               :objects="irc.history.data" :downloading="downloading.history"></event-table>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Наименование:'" class="full-width"></input-row>

            <div class="row" v-if="object && object.id > 0">
              <div class="col-2">
                <span class="input-title">
                  Согласовано
                  <input type="checkbox" v-model="object.confirmed" checked>
                  <span style="margin-left: 10px">
                  {{object.confirmed ? "Да" : "Нет"}}
                  </span>
                </span>
              </div>
              <div class="col-2">
                <span class="input-title">
                  Подписано
                  <input type="checkbox" v-model="object.signed" checked>
                  <span style="margin-left: 10px">
                  {{object.signed ? "Да" : "Нет"}}
                </span>
                </span>
              </div>
              <div class="col-1">

              </div>
            </div>
            <br/>

            <div v-if="object && object.id > 0" class="row margin-for-inner-obj">
              <stock :start_object="object.consumer" :start_state="'text'" :default_state="'text'"
                     :select="true" @select="setConsumer" :header="'Заказчик:'"> Заказчик </stock>
            </div>

            <div v-if="object && object.id > 0" class="row margin-for-inner-obj">
              <company :start_object="object.supplier" :start_state="'text'" :default_state="'text'"
                       :select="true" @select="setSupplier" :header="'Поставщик:'"> Поставщик </company>
            </div>

            <div v-if="object && object.id > 0" class="row margin-for-inner-obj">
              <order :start_object="object.order" :start_state="'text'" :default_state="'text'"
                     :select="true" @select="setOrder" :header="'Заказ:'"> Заказ </order>
            </div>

            <div>
              <div v-if="object.document && object.document.id">
                <lnk :lnk_start="object.document" :select-pool="lnks" :select="true" :state_start="'asImage'"
                     @select="setImage" @callData="giveData"> </lnk>
              </div>
              <div v-else-if="!object.document && object.id < 1">
                <form id="uploadForm" enctype="multipart/form-data">
                  <input type="file" id="file" name="file">
                </form>
              </div>
            </div>
            <br/>
            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>
          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <items-table v-bind:objects="selectPool" v-bind:header="'Номенклатура'"
                   :downloading="downloading.select"
                   @select="selectObjectFromTable" @closeModal="closeModal"
                   v-bind:header-title="'Перечень продукции и материалов'" :select="select"></items-table>
    </div>

  </div>
</template>
<script>

import {authHeader, vue} from "@/auth";
import ItemCategorySelect from "../selectable/ItemCategorySelect";
import ContentsTable from "@/tables/ContentsTable";
import Table from "@/components";
import {ElementInterface} from "@/models/elementInteface";
import {CERTIFICATE, ENQUIRE, ITEM, LNK, NOTE, ORDER, PLAN, Repo, SPECIFICATION, STORE} from "@/repository/repository";
import {asDate, userService} from "@/services";
import Checker from "@/components/Checker";

export default {
  components: {
    Table,
    ContentsTable,
    ItemCategorySelect
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      contents: [],
      showContent: false,
      lnks: [],
      showLnk: false,
      notes: [],
      showNote: false,
      portions: [],
      showPortion: false,
      id: null,
      selectPool: [],
      asParent: {},
      access: false,
      viewKey: 0,
      flag: this.$flag,
      certificate: null,
      showCertificate: false,
      irc: null,
      showSelect: false,
      downloading: {state: false, select: {state: false}, content: {state: false}, lnk: {state: false}, note: {state: false}, history: {state: false}, portion: {state: false}},
      checkerCols: [{first: 1, second: 1, name: 'Согласовано', field: 'confirmed', yes: 'Да', no: 'Нет'},
                    {first: 1, second: 1, name: 'Подписано', field: 'signed', yes: 'Да', no: 'Нет'}],
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    toDate(date) {
      return asDate(date);
    },
    startEdit() {
      this.state = 'edit';
      this.showHistory = false;
    },
    fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    async getLnk() {
      this.lnks = [];
      this.showLnk = true;
      await this.$repo.getList(`${SPECIFICATION}/lnk/${this.object.id}`, LNK, this.lnks, null, this.downloading.lnk);
    },
    async getNote() {
      this.notes = [];
      this.showNote = true;
      await this.$repo.getList(`${SPECIFICATION}/note/${this.object.id}`, NOTE, this.notes, null, this.downloading.note);
    },
    hideElement(element) {
      if (element === 'lnk') {
        this.showLnk = false;
        this.lnks = [];
      } else if (element === 'note') {
        this.showNote = false;
        this.notes = [];
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setSupplier(supplier) {
      this.object.supplier = supplier;
    },
    setConsumer(consumer) {
      this.object.consumer = consumer;
    },
    setOrder(order) {
      console.log("order came " + order.name)
      this.object.order = order;
    },
    async save() {
      if (this.object.id > 0) {
        await this.$repo.uniSave(this, SPECIFICATION, `${SPECIFICATION}/`, this.downloading);
      } else {
        try {
          let url = `${SPECIFICATION}/create?name=${this.object.name}`;
          let up = document.querySelector('#file');
          let formData = new FormData();
          formData.append("file", up.files[0]);
          this.object = await this.$repo.uploadFile(url, formData, this.downloading);
          this.fullView();
          this.$emit("updated", this.object);
        } catch (e) {
          console.log('ups...')
        }
      }
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    setImage(image) {
      this.object.document = image;
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.callModal();
      await this.$repo.getSelectPoll(SPECIFICATION, this.selectBounds, this.selectPool, this.downloading.select);
    },
    giveData(type) {
      if (type === LNK) {
        this.getLnk(false);
      }
    },
    async createOrder() {
      let order = await this.$repo.post(`${ORDER}/create/specification${this.object.id}`, null, this.downloading);
      if (order) {
        this.object.order = order;
      }
    },
    async controlHistory() {
      await this.$repo.getHistory(this, SPECIFICATION, this.object.id, this.downloading.history);
    }
  },
  mounted: async function () {
    this.irc = new ElementInterface(this, SPECIFICATION, this.downloading.history);
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(SPECIFICATION, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    this.access = userService.userHasAccess('ADMIN');
    if (!this.object) {
      this.object = await this.$repo.justGetData(`${SPECIFICATION}/model`, this.downloading);
    }
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: SPECIFICATION, id: this.object.id}
      }
    },
    state: async function (value) {
      this.$repo.watchState(this, SPECIFICATION, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, SPECIFICATION, val);
    }
  }
}
</script>
<style>
</style>
