<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object" class="row row-title" v-on:click="fullView()">
      <div class="col-12">
        {{ object.name }}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{header}}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.name }}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Оборудование'" :select="select"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="state = 'edit'" @select="selectObject">
                Header
              </full-view-header>

              <div class="row">
                <div class="col-3">
                  Наименование
                </div>
                <div class="col-9">
                  {{ object.name }}
                </div>
              </div>

              <div v-if="!select">
                <div class="row work-time-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showWorkTime" v-on:click="getWorkTime">Рабочеее время</span>
                    <work-times-table v-if="showWorkTime" @element="hideElement('worktime')" :header="'Рабочее время'"
                                      :downloading="downloading.workTime"
                                      :header-title="'Использовние рабочего времени в часах'"
                                      :objects="workTime" :parent="asParent">Content</work-times-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Наименование:'" class="full-width"></input-row>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <equipments-table v-bind:objects="selectPool" v-bind:header="'Оборудование'" @select="selectObjectFromTable"
                        :downloading="downloading.select"
                        @closeModal="closeModal"
                        v-bind:header-title="'Перечень станков и оборудования'" :select="select"></equipments-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import ContentsTable from "@/tables/ContentsTable";
import Table from "@/components";
import {EQUIPMENT, getList, getSingle, ITEM, PLAN, save, WORK_TIME} from "@/repository/repository";
import Equipments from "@/pages/Equipments";

export default {
  components: {
    Table,
    ContentsTable
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      workTime: [],
      showWorkTime: false,
      id: null,
      selectPool: [],
      asParent: {},
      viewKey: 0,
      flag: this.$flag,
      showSelect: false,
      downloading: {state: false, select: {state: false}, workTime: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    header: null
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    async fullView() {
      if (this.object.id > 0) {
        if (this.select) {
          this.selectObjectFromTable(this.object);
        } else {
          this.state = 'full';
        }
      } else {
        this.$emit('close', true);
      }
    },
    async getWorkTime() {
      this.workTime = [];
      this.showWorkTime = true;
      await this.$repo.getList('worktime/equipment/' + this.object.id, WORK_TIME, this.workTime, {key: 'date', asc: false}, this.downloading.workTime);
    },
    hideElement(element) {
      if (element === WORK_TIME) {
        this.showWorkTime = false;
        this.workTime = [];
      }
    },
    save() {
      this.$repo.uniSave(this, EQUIPMENT, 'equipment/', this.downloading);
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      await this.$repo.getList('equipment/all', EQUIPMENT, this.selectPool, {key: 'name', asc: true}, this.downloading.select);
      this.callModal();
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(EQUIPMENT, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: EQUIPMENT, id: this.object.id};
      }
    },
    state: function (value) {
      this.$repo.watchState(this, EQUIPMENT, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, EQUIPMENT, val);
    }
  }
}
</script>
