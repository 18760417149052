<template>
  <div class="content">
    <div class="container-fluid">
      <purchases-table :objects="objects" :header="'Закупки'"
                       :downloading="downloading"
                       :header-title="'Закупки материалов и комплектующих'"
                       @update="getObjects">
      </purchases-table>
    </div>
  </div>
</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'
import ItemsTable from "../tables/ItemsTable";

export default {
  name: 'purchases',
  data () {
    return {
      objects: [],
      key: authHeader(),
      sortBy: {key: 'date', asc: true},
      filter: new Map,
      tableKey: 0,
      downloading: {state: false, history: {state: false}},
    }
  },
  methods: {
    async getObjects() {
      this.objects = [];
      await this.$repo.getList('purchase/','purchase', this.objects, this.sortBy, this.downloading);
    },
    sort() {
      sorter(this.date, this.sortBy);
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    }
  },
  created() {
    this.getObjects();
  }
}
</script>
<style>
</style>
