<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright text-center">
        &copy; Coded with
        <i class="fa fa-heart heart"></i> by Ilya.
        Designed by Creative Tim.
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
