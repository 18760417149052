<template>
  <div class="content">
    <DxScheduler
      id="crmScheduler"
      :key="lineKey"
      :visible="true"
      @initialized="hideTooltip"
      time-zone-offset="-4"
      :data-source="dataSource"
      :current-date="currentDate"
      :views="views"
      :height="600"
      :cell-duration="40"
      :start-day-hour="8"
      :end-day-hour="20"
      :first-day-of-week="0"
      :onAppointmentClick="doSample"
      :onAppointmentUpdated="updateTask"
      :useDropDownViewSwitcher="false"
      current-view="timelineMonth"
    >
      <DxResource
        :allow-multiple="true"
        :data-source="usersData"
        :use-color-as-default="true"
        field-expr="ownerId"
        label="Owner"
      />

    </DxScheduler>

    <br/>
    <div class="row full-width full-title" :key="viewKey">
      <div class="col-12">
        <full-view-header
          :header="taskStatus"
          :save-and-cancel="changes"
          :hide-edit="true"
          :extra-keys = "extraKeys"
          :hide-update-state = "true"
          @save="save"
          @cancel="cancelChanges"
          @new-task="newTask"
          @done="dc('done')" @undone="dc('undone')"
          @close="dc('close')" @restart="dc('restart')">
          Header
        </full-view-header>
        <br/>
        <div v-if="object" :key="objectKey">
          <div class="row">
            <div class="col-2">
              Наименование:
            </div>
            <div class="col-6">
              <active-input-element :object="object" :field="'name'" @update="changes = true"/>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              Описание:
            </div>
            <div class="col-9">
              <active-input-element :object="object" :field="'description'" @update="changes = true"/>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              Дата начала:
            </div>
            <div class="col-4">
              <active-input-element :object="object" :field="'startDate'" :moment="true" @update="changes = true"/>
            </div>
            <div class="col-2">
              Дата исполнения:
            </div>
            <div class="col-4">
              <active-input-element :object="object" :field="'endDate'" :moment="true" @update="changes = true"/>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              Исполнитель:
            </div>
            <div class="col-4">
              <user :start_object="object.owner" :start_state="'col'" :col="4" :select="true" :hide_login="true"
                    @select="setOwner"></user>
            </div>
            <div class="col-2">
              Автор задачи:
            </div>
            <div class="col-4">
              {{object.author ? object.author.name : '-'}}
            </div>
          </div>
          <note-table v-if="object && object.id > 0" :notes="notes" :header="'События'"
                      :header-title="'События и заметки по задаче'"
                      :limit="4" :parent="asParent">Notes and logs</note-table>
          <lnk-table v-if="object && object.id > 0" :header="'Файлы'"
                     :header-title="'Документы, файлы и изображения'"
                     :objects="lnks" :parent="asParent">Lnks</lnk-table>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import DxScheduler, { DxResource, DxView, DxScrolling } from 'devextreme-vue/scheduler'
import {TASK} from "@/repository/repository";

export default {
  components: {
    DxScheduler,
    DxResource,
    DxView,
    DxScrolling,
  },
  data () {
    return {
      views: ['timelineDay', 'timelineWeek', 'timelineWorkWeek', 'timelineMonth'],
      groups: ['ownerId'],
      currentDate: new Date(),
      lineKey: 0,
      viewKey: 1000000,
      objectKey: 9999999999,
      object: null,
      state: '',
      selected: {},
      changes: false,
      changedObj: null,
      /*
      item: {
        text: 'Google AdWords Strategy',
        ownerId: [2],
        startDate: new Date('2021-02-01T16:00:00.000Z'),
        endDate: new Date('2021-02-01T17:30:00.000Z'),
        priority: 1,
      },*/
      text: 'Nothing',
      usersData: [],
      sortBy: {key: 'name', asc: true},
      priorityData: [],
      dataSource: [],
      notes: [],
      lnks: [],
      asParent: {},
      scheduleRefKey: 'CRM_line'
    }
  },
  computed: {
    height: function () {
      //console.log(await this.$el)
      return 800
    },
    extraKeys: function () {
      let rsl = [];
      if (!this.object || this.object.id !== 0) {
        rsl.push({name: 'Новая задача', action: 'new-task'});
      }
      if (this.object && this.object.did) {
        if (this.object.done) {
          rsl.push({name: 'Не выполнено', action: 'undone'});
        } else {
          rsl.push({name: 'Выполнено', action: 'done'});
        }
      }
      if (this.object && this.object.close) {
        if (this.object.completed) {
          rsl.push({name: 'Возобновить задачу', action: 'restart'});
        } else {
          rsl.push({name: 'Закрыть задачу', action: 'close'});
        }
      }
      return rsl;
    },
    taskStatus: function () {
      if (this.object) {
        if (this.object.completed) {
          return 'ЗАКРЫТА';
        } else if (this.object.done && this.object.done > 0) {
          return 'ВЫПОЛНЕНА';
        }
        return 'В РАБОТЕ'
      }
      return '';
    },
  }
  ,methods: {
    doSample(a) {
      this.object = JSON.parse(JSON.stringify(a.appointmentData));
      if (this.changedObj) {
        this.updateDataPool(this.changedObj);
        this.changedObj = null;
      }
    },
    updateTask(a) {
       this.object = JSON.parse(JSON.stringify(a.appointmentData));
       this.changes = true;
       this.save();
    },
    matchHeight() {
     // this.height = this.$refs.infoBox.clientHeight;
    },
    async getData() {
      this.usersData = [];
      this.dataSource = [];
      await this.$repo.justGetList('task/active', this.dataSource);
      /*
      for await (let task of this.dataSource) {
        task.owner = await this.$repo.get('user', task.owner.id);
        task.author = await this.$repo.get('user', task.author.id);
      }*/
      await this.$repo.justGetList('task/users', this.usersData, this.sortBy);
      this.usersData.forEach(obj => obj.text = obj.name);
      this.lineKey++;
    },
    openSelectModal() {

    },
    async save() {
      this.object.text = this.object.name;
      this.object = await this.$repo.save('task/', TASK, this.object);
      this.updateDataPool(this.object);
      this.changes = false;
      this.changedObj = null;
    },
    async dc(action) {
      this.object.text = this.object.name;
      this.object = await this.$repo.post(`task/${action}/${this.object.id}`);
      this.updateDataPool(this.object);
      this.changes = false;
      this.changedObj = null;
    },
    updateDataPool(object) {
      let i = this.dataSource.findIndex(obj => obj.id === object.id);
      if (i >= 0) {
        for (let prop in object) {
          this.dataSource[i][prop] = JSON.parse(JSON.stringify(object[prop]));
        }
      } else {
        this.dataSource.push(JSON.parse(JSON.stringify(object)));
      }
      this.lineKey++;
    },
    async cancelChanges() {
      if (this.object.id === 0) {
        await this.newTask();
      } else {

      }
      this.changes = false;
    },
    async newTask() {
      this.object = await this.$repo.justGetData('task/model');
      this.lnks = [];
      this.notes = [];
      this.objectKey++;
    },
    setOwner(obj) {
      if (obj && obj.id && obj.id > 0) {
        this.object.owner = obj;
        this.object.ownerId = obj.id;
        this.changes = true;
      }
    },
    hideTooltip(e) {
      e.component.hideAppointmentTooltip();
    }
  },
  watch: {
    object: async function () {
      if (this.object && this.object.id > 0) {
        this.asParent = {type: TASK, id: this.object.id}
        this.notes = [];
        this.lnks = [];
        await this.$repo.justGetList(`task/note/${this.object.id}`, this.notes,{key: 'date', asc: false});
        await this.$repo.justGetList(`task/lnk/${this.object.id}`, this.lnks,{key: 'date', asc: false});
      }
    },
  },
  created() {
    this.matchHeight();
  },
  async mounted() {
    await this.getData();
    //this.$refs[this.scheduleRefKey].hideAppointmentTooltip;

  }
}
</script>
<style>
</style>
