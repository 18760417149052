<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state"
                 :single="true" :downloading="downloading" @order="makeOrder">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id > 0" class="row row-title" v-on:click="fullView()">
      <div class="col-2">
        {{ object.number + 'р' }}
      </div>
      <div class="col-2">
        {{ asDate }}
      </div>
      <div class="col-3">
        {{ object.contact.name }}
      </div>
      <div class="col-5">
        {{ object.consumer.contact.name + (object.consumer.contact.name !== object.consumer.name ? (" " + object.consumer.name) : '')}}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          Счет на оплату
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ '№' + object.number + 'р от ' + asDate + 'г.'}}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Счет на оплату'" :select="select"
                                @regularState="state = default_state ? default_state : start_state; zeroKey = zeroKeyInitial"
                                @editState="state = 'edit'; zeroKey = zeroKeyInitial" @select="selectObject"
                                :extra-keys="extraKeys"
                                @print="getForm"
                                @predeactivate="predeactivate"
                                @deactivate="deactivate"
                                @create_order="createOrder">

                Header
              </full-view-header>

              <div class="row">
                <div class="col-12">
                  {{ '№' + object.number + 'р от ' + asDate + 'г.'}}
                </div>
              </div>

              <div class="row company-bcg margin-for-inner-obj">
                <company :start_object="object.contact" :header="'Продавец'"
                         :start_state="'text'" :default_state="'text'"></company>
              </div>

              <div class="row stock-bcg margin-for-inner-obj">
                <stock :start_object="object.consumer" :header="'Покупатель'"
                       :start_state="'text'" :default_state="'text'"></stock>
              </div>

              <div class="row">
                <div class="col-3">
                  Условия оплаты и поставки:
                </div>
                <div class="col-8">
                  {{ object.terms ? object.terms : '-' }}
                </div>
                <div class="col-1"/>
              </div>

              <div v-if="object.order && object.order.id > 0" class="row order-bcg margin-for-inner-obj">
                <order :start_object="object.order" :header="'Относится к заказу'"
                       :start_state="'text'" :default_state="'text'"></order>
              </div>

              <div>

                <div class="row content-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showPlan" v-on:click="getPlan()">Позиции по счету</span>
                    <plan-table v-if="showPlan" @element="hideElement('plan')" :header="'Позиции'"
                                :header-title="'Изделия, материалы и услуги по счету'"
                                :objects="plans" :parent="asParent">Orders</plan-table>
                  </div>
                </div>

              </div>
              <br/>
              <sign :start_state="'text'" :start_object="object.sign" @select="setSign"
                    :header="'Подпись в документе'" :select="true" :parent="asParent">Sign</sign>

            </div>
          </div>

        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <div :key="selectZone">
              <div class="row margin-for-inner-obj">
                <company :start_object="object.contact" :start_state="'text'" :default_state="'text'"
                         :parent="asParent"
                         :select="true" @select="setContact" :header="'Продавец:'"> Sender </company>
              </div>

              <div class="row margin-for-inner-obj">
                <stock :start_object="object.consumer" :start_state="'text'" :default_state="'text'"
                       :parent="asParent"
                       :select="true" @select="setConsumer" :header="'Покупатель:'"> Receiver </stock>
              </div>
              <br/>
              <input-row v-model="object.number" :number="true" :header="'Номер:'"
                         class="full-width"></input-row>

              <input-row v-model="object.date" :header="'Дата:'" :date="true" class="full-width"></input-row>

              <div class="row">
                <div class="col-3">
                <span class="input-title">
                  Условия оплаты и поставки:
                </span>
                </div>
                <div class="col-9" style="padding-right: 10px">
                  <textarea v-model="object.terms" rows="6" class="full-width" ></textarea>
                </div>
              </div>
              <br/>

              <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>
            </div>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <invoices-table :companies="selectPool" :header="'Счета'" @select="selectObjectFromTable"
                      :downloading="downloading.select"
                      @closeModal="closeModal"
                      :header-title="'Выставленные счета на оплату'" :select="select"></invoices-table>
    </div>
  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {INVOICE, ITEM, ORDER, PLAN} from "@/repository/repository";

export default {
  components: {
    CompaniesTable,
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
    extraKeys: function () {
      let rsl = this.object.order ? [] : [{name: 'Создать заказ', action: 'create_order'}];
      if (this.object.active) {
        rsl.unshift(this.zeroKey);
      }
      if (this.object.sign) {
        rsl.unshift({name: 'Печать', action: 'print'});
      }
      return rsl;
    },
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      plans: [],
      showPlan: false,
      id: null,
      selectPool: [],
      asParent: {},
      selectZone: 0,
      showNumber: false,
      showWarning: false,
      warningKeys: null,
      warningHeader: '',
      headerKey: 0,
      viewKey: 0,
      flag: this.$flag,
      zeroKeyInitial: {name: 'Анулировать', action: 'predeactivate'},
      zeroKey: {name: 'Анулировать', action: 'predeactivate'},
      showSelect: false,
      downloading: {state: false, select: {state: false}, plan: {state: false}, history: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    async fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    async getPlan() {
      this.plans = [];
      this.showPlan = true;
      await this.$repo.getList('invoice/plan/' + this.object.id, PLAN, this.plans, null, this.downloading.plan);
    },
    hideElement(element) {
      if (element === PLAN) {
        this.showPlan = false;
        this.plans = [];
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    async setContact(contact) {
      this.object.contact = contact;
      this.selectZone += 1;
      if (contact.id > 0) {
        this.object.number = await this.$repo.justGetData('invoice/number/' + contact.id, this.downloading);
      }
    },
    setConsumer(stock) {
      this.object.consumer = stock;
      this.selectZone += 1;
    },
    setOrder(order) {

    },
    async save() {
      if (this.object.contact && this.object.consumer && this.object.date) {
        await this.$repo.uniSave(this, INVOICE, 'invoice/', this.downloading);
        if (this.object) {
          this.showNumber = false;
        }
        if (this.object.number === -100) {
          this.object.number = 0;
          this.showNumber = true;
        }
      } else {
        this.warningKeys = [{name: 'Понятно', action: 'close'}];
        this.warningHeader = ['Заполните следующие данные:'];
        if (!this.object.contact) {
          this.warningHeader.push(' - Выберите поставщика');
        }
        if (!this.object.date) {
          this.warningHeader.push(' - Введите дату');
        }
        if (!this.object.consumer) {
          this.warningHeader.push(' - Выберите покупателя');
        }
        this.showWarning = true;
      }
    },
    setSign(sign) {
      this.object.sign = sign;
      this.save();
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    setImage(image) {
      this.object.image = image;
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      await this.$repo.getList('invoice/active', INVOICE, this.selectPool, this.downloading.select);
      this.selectPool = this.selectPool.filter(invoice => !invoice.order);
      this.callModal();
    },
    getForm() {
      this.$repo.downloadFile('invoice/form/' + this.object.id, 'Счет №' + this.object.number + 'р ' + (this.object.consumer.legalShortName ? this.object.consumer.legalShortName : this.object.consumer.name) + '.xls', this.downloading);
    },
    predeactivate() {
      this.zeroKey = {name: 'Точно анулировать', action: 'deactivate'};

    },
    async deactivate() {
      this.object = await this.$repo.justGetData(`${INVOICE}/deactivate/${this.object.id}`, this.downloading);
      this.$emit("updated", object);
    },
    async createOrder() {
      let plans = [];
      let data = {};
      await this.$repo.getList('invoice/plan/' + this.object.id, PLAN, plans, null, this.downloading);
      if (this.object.contact && this.object.consumer && this.object.date && plans.length > 0) {
        data.keys = [{name: 'Создать', action: 'order'}, {name: 'Отмена', action: 'close'}];
        data.header = ['Создаем новый заказ на базе счета?'];
      } else {
        data.keys = [{name: 'Понятно', action: 'close'}];
        data.header = ['Заполните следующие данные:'];
        if (!this.object.date) {
          data.header.push(' - Введите дату');
        }
        if (plans.length === 0) {
          data.header.push(' - Добавьте позиции по счету');
        }
      }
      this.downloading.data = data;
      this.downloading.state = true;
    },
    async makeOrder() {
      this.object.order = {id: -100};
      try {
        this.object.order = await this.$repo.save('invoice/new_order', ORDER, this.object, this.downloading);
        this.headerKey++;
        this.viewKey++;
      } catch (err) {
        this.object.order = undefined;
      }
    }

  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(INVOICE, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
    if (this.object && !this.object.terms) {
      this.object.terms = '';
    }
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: INVOICE, id: this.object.id};
      }
    },
    state: function (value) {
      this.$repo.watchState(this, INVOICE, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, INVOICE, val);
    }
  }
}
</script>
<style>
</style>
