<template>
  <div class="row">
    <div class="col-12">
      <card body-classes="table-full-width" style="margin: 5px">
        <template slot="header">
              <span v-if="!select" class="float-right unselectable" v-on:click="addNew()">
                new
                <i class="nc-icon nc-simple-add"></i>
              </span>
          <h4 class="card-title unselectable" v-on:click="headerClick()">{{ header }}</h4>
          <p class="card-category unselectable">{{ headerTitle }}</p>
        </template>
        <table class="table table-hover table-striped">
          <thead>
          <slot name="columns">
            <tr>
              <th class="row-title">
                <div class="row" style="width: 100%">
                  <div class="col-12 unselectable">
                    <span v-on:click="sortBy = {key: 'name', asc: !sortBy.asc}">
                              Наименование
                    </span>
                  </div>
                </div>
              </th>
            </tr>
          </slot>
          </thead>
          <tbody :key="tableKey">
          <tr v-if="newObj">
            <td>
              <equipment :start_object="newObj" :start_state="'edit'" :default_state="'row'"
                         @close="cancelNew" :select="select" @updated="addToList"></equipment>
            </td>
          </tr>
          <tr v-for="(object, index) in objects" :key="index" v-if="fitFilter(object)">
            <td>
              <equipment v-if="object.id > 0" :start_object="object" :start_state="'row'" :default_state="'row'"
                    :select="select" @select="selectObject" @updated="addToList"></equipment>
            </td>
          </tr>
          <tr v-if="downloading && downloading.state">
            <downloading :downloading="downloading">
              Downloading
            </downloading>
          </tr>
          </tbody>
        </table>
      </card>
    </div>
  </div>
</template>
<script>
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import {authHeader} from "@/auth";
import Item from "../elements/Item";
import {sorter, fitFilter, updateFilter, textContainsText, addToList} from '@/services'
import Table from "@/components";
import {getData, justGetData} from "@/repository/repository";
import Equipment from "@/elements/Equipment";

export default {
  components: {
    Equipment,
    Table,
    Item,
    LTable,
    Card
  },
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'name', asc: true},
      filter: new Map,
      tableKey: 0,
      textSearch: "",
      newObj: null
    }
  },
  props: {
    objects: [],
    header: null,
    headerTitle: null,
    select: false,
    parent: null,
    downloading: null,
  }
  ,
  methods: {
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter) && textContainsText(object.name, this.textSearch);
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    async addNew() {
      if (!this.newObj) {
        this.newObj = await this.$repo.justGetData('equipment/model', this.downloading);
      }
    },
    addToList(object) {
      addToList(object, this);
    },
    cancelNew() {
      this.newObj = null;
    },
    selectObject(object) {
      this.$emit('select', object);
    },
    headerClick() {
      if (this.select) {
        this.objects = []
        this.$emit('closeModal');
      } else {
        this.$emit('update');
      }
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    },
    objects: function (){
      this.tableKey += 1;
    }
  }
}
</script>
<style>
</style>
