<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.cell" class="row row-title" v-on:click="fullView()">
      <div class="col-4">
        {{ object.cell.item.name }}
      </div>
      <div class="col-2">
        {{ object.value + ' ' + object.cell.item.measure }}
      </div>
      <div class="col-6">
        {{ object.cell.stock.contact.name + ' - ' + object.cell.stock.name }}
        <span v-if="select">
          <i class="nc-icon nc-check-2 float-right" v-on:click="selectObject()"></i>
        </span>
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.cell.item.name + ': ' + object.value + ' ' + object.cell.item.measure }}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Резервирование'" :select="select"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="state = 'edit'" @select="selectObject">
                Header
              </full-view-header>

              <div class="row item-bcg margin-for-inner-obj">
                <item :start_object="object.cell.item" :header="'Номенклатура'"
                         :start_state="'text'" :default_state="'text'"></item>
              </div>

              <div class="row stock-bcg margin-for-inner-obj">
                <stock :start_object="object.cell.stock" :header="'Склад'"
                       :start_state="'text'" :default_state="'text'"></stock>
              </div>

              <div class="row order-bcg margin-for-inner-obj">
                <order :start_object="object.order" :header="'Заказ'"
                       :start_state="'text'" :default_state="'text'"></order>
              </div>

              <div v-if="object.assembly && plan" class="row plan-bcg margin-for-inner-obj">
                <plan :start_object="plan" :header="'Конечная позиция'"
                      :start_state="'text'" :default_state="'text'"></plan>
              </div>

              <div v-if="object.assembly && object.assembly.parent" class="row assembly-bcg margin-for-inner-obj">
                <assembly :start_object="object.assembly.parent" :header="'Необходим для выпуска'"
                          :start_state="'text'" :default_state="'text'"></assembly>
              </div>


              <div class="row">
                <div class="col-3">
                  Количество:
                </div>
                <div class="col-9">
                  {{ object.value }}
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  Дата резервирования:
                </div>
                <div class="col-9">
                  {{ asDate }}
                </div>
              </div>

            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <div class="row margin-for-inner-obj" v-if="object.id === 0 && (!parent || parent.type !== 'stockcell')">
              <stock-cell :start_object="object.cell" :start_state="'text'" :default_state="'text'"
                       :select="true" @select="setCell" :header="'Номенклатура и склад'"> Stock cell </stock-cell>
            </div>

            <input-row v-model="object.value"
                       :header="object.cell ? ('Количество, ' + object.cell.item.measure + ':') : 'Количество:'"
                       :number="true" class="full-width"></input-row>

            <input-row v-model="object.date" :header="'Дата резервирования:'"
                       :date="true" class="full-width"></input-row>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <reserve-table v-bind:objects="selectPool" v-bind:header="'Резервирование'" @select="selectObjectFromTable"
                     :downloading="downloading.select"
                     @closeModal="closeModal"
                     v-bind:header-title="'Резервирование продукции и материалов'" :select="select"></reserve-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {ITEM, PLAN, RESERVE} from "@/repository/repository";

export default {
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
    plan: function () {
      let assembly = this.object.assembly;
      while (!assembly.plan && assembly.parent) {
        assembly = assembly.parent;
      }
      return assembly.plan;
    }
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      id: null,
      selectPool: [],
      asParent: {},
      viewKey: 0,
      flag: this.$flag,
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    setOrder(order) {
      this.object.order = order;
    },
    setCell(cell) {
      this.object.cell = cell;
    },
    setConsumer(cell) {
      this.object.cell = cell;
    },
    async save() {
      let url = 'reserve/';
      if (this.parent && this.object.id === 0) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      await this.$repo.uniSave(this, RESERVE, url, this.downloading);
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      await this.$repo.justGetList('reserve/', this.selectPool, null, this.downloading.select);
      this.callModal();
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(RESERVE, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: RESERVE, id: this.object.id}
      }
    },
    state: function (value) {
      this.$repo.watchState(this, RESERVE, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, RESERVE, val);
    }
  }
}
</script>
<style>
</style>
