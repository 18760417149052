<template>
  <div class="full-width" :key="key">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object" class="row row-title" v-on:click="fullView()">
      <div class="col-6">
        {{ object.name }}
      </div>
      <div class="col-3">
        {{ object.patternLnk && object.patternLnk.name ? object.patternLnk.name : '-'}}
      </div>
      <div class="col-3">
        {{ object.counter && object.counter.name ? object.counter.name : '-'}}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          Шаблон паспорта качества
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.name }}
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Шаблон паспорта качества'" :select="select"
                                @select="selectObject"
                                @editState="state = 'edit'"
                                @regularState="state = start_state">
                Header
              </full-view-header>

              <div class="row">
                <div class="col-3">
                  Наименование:
                </div>
                <div class="col-9">
                  {{ object.name }}
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  Описание:
                </div>
                <div class="col-9">
                  {{ object.description }}
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  Шаблон XLS:
                </div>
                <div class="col-9">
                  {{ object.patternLnk && object.patternLnk.id ? object.patternLnk.name : '-' }}
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  Счетчик:
                </div>
                <div class="col-9">
                  {{ object.counter && object.counter.id ? object.counter.name : '-' }}
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  Параметры:
                </div>
                <div class="col-9">
                  {{object.contentList ? object.contentList.join(', ') : '-'}}
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object && object.id >= 0" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Наименование:'" class="full-width"></input-row>

            <input-row v-model="object.description" :header="' Описание:'" class="full-width"></input-row>

            <div class="row">
              <div class="col-3">
                Шаблон XLS:
              </div>
              <div class="col-9">
                <lnk :lnk_start="object.patternLnk" :state_start="'asFile'"
                     :accept_list="'.xls'"
                     :changeable="true"
                     @updated="setPattern">
                  Выбор файла
                </lnk>
              </div>
            </div>
            <br/>

            <div class="row">
              <div class="col-3">
                Счетчик:
              </div>
              <div class="col-9">
                <certificate-counter :start="object.counter" :start_state="'text'"
                     :select="true"
                     @select="setCounter">Select counter</certificate-counter>
              </div>
            </div>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import {CERTIFICATE_PATTERN} from "@/repository/repository";

export default {
  components: {
    Table
  },
  data () {
    return {
      key: 0,
      state: 'row',
      object: {},
      id: null,
      selectPool: [],
      asParent: {},
      downloading: {state: false, select: {state: false}},
    }
  },
  computed: {
    asDate: function () {
      let date = new Date(this.object.date);
      return date.customFormat('#DD#/#MM#/#YYYY# #hh#:#mm#');
    }
  },
  props: {
    parent: null,
    start: {},
    start_state: '',
    select: false,
    selectBounds: null
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    async fullView() {
      if (this.object.id > 0) {
        this.object = await this.$repo.getSingle(CERTIFICATE_PATTERN + '/' + this.object.id, CERTIFICATE_PATTERN, this.downloading);
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    setPattern(pattern) {
      this.object.patternLnk = pattern;
    },
    setCounter(counter) {
      this.object.counter = counter;
    },
    async save() {
      let obj = await this.$repo.save(CERTIFICATE_PATTERN + '/', CERTIFICATE_PATTERN, this.object, this.downloading);
      if (obj) {
        this.$emit('updated', obj);
        if (this.start_state === 'edit') {
          this.start_state = 'row';
          this.state = 'row';
        } else {
          this.state = 'full';
        }
        this.key++;
      }
    },
    selectObject() {
      this.$emit('select', this.object);
    },
  },
  mounted: function () {
    this.state = this.start_state;
    this.object = this.start;
    this.id = this._uid;
    if (!this.object) {
      this.object = this.$repo.justGetData(CERTIFICATE_PATTERN + '/model', this.downloading);
    }
  },
  watch: {
    note: function () {
      this.asParent = {type: CERTIFICATE_PATTERN, id: this.object.id}
    }
  }
}
</script>
<style>
</style>
