<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id > 0" class="row row-title" v-on:click="fullView()">
      <div class="col-6">
        {{ object.item.name + (object.comment ? (' (' + object.comment + ')') : '') }}
      </div>
      <div class="col-3">
        <span>{{ object.value + ' / '+ ' '}}</span>
        <span style="color: #0a6715">{{ (object.shipped ? object.shipped : '-') + ' '}}</span>
        <span>{{object.item.measure }}</span>
      </div>
      <div class="col-3">
        {{ asDate }}
      </div>
    </div>

    <div v-if="state === 'commercial_row' && object && object.item" class="row row-title" v-on:click="fullView()">
      <div class="col-4">
        {{ object.item.name + (object.comment ? (' (' + object.comment + ')') : '') }}
      </div>
      <div class="col-2">
        {{ (object.value % 1 === 0 ? object.value : (Math.round(object.value * 1000) / 1000).toFixed(3)) + ' ' + object.item.measure}}
      </div>
      <div class="col-2">
        {{ (Math.round(object.price * 100) / 100).toFixed(2) }}
      </div>
      <div class="col-2">
        {{ (Math.round(object.price * object.value * 100) / 100).toFixed(2) }}
      </div>
      <div class="col-2">
        {{ asDate }}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.item.name + (object.comment ? (' (' + object.comment + ')') : '') + ' - ' + object.value + ', ' + object.item.measure + ' до ' + asDate}}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'active'" class="full-width" style="margin-top: -2px">
      <colgroup>
        <col v-for="index in cs" :style="'width:' + 100/cs + '%'" :key="index">
      </colgroup>
      <tr>
        <td class="left-border" :colspan="cs - 22">
          <div class="top-header">
            позиция к отгрузке
          </div>
          <div v-if="object && object.id > 0" v-on:click="activeView = activeView !== 'plan' ? 'plan' : null">
            {{ object.item.name + (object.comment ? (' (' + object.comment + ')') : '') + ' до ' + asDate}}
          </div>
        </td>

        <td :colspan="6">
          <div class="top-header">
            план / отгружено
          </div>
          <div v-if="object && object.id > 0" style="text-align: center">
            {{object.value + ' / ' + (object.shipped ? object.shipped : '-')}}
          </div>
        </td>

        <td v-if="!assembly" colspan="16">
          <div class="top-header">
            Компонет в работе
          </div>
          <div v-if="!assembly && activeView !== 'asmb'" style="text-align: center" v-on:click="activeView = 'asmb'">
            Создать +
          </div>
          <div v-if="!assembly && activeView === 'asmb'" style="text-align: center" v-on:click="activeView = 'null'">
            Отменить создание
          </div>
        </td>

        <td :colspan="4" v-if="assembly">
          <div class="top-header">
            потребность
          </div>
          <div style="text-align: center" v-on:click="activeView = activeView !== 'asmb' ? 'asmb' : null">
            {{assembly.value >= 0 ? assembly.value : 0}}
          </div>
        </td>
        <td :colspan="4" v-if="assembly">
          <div class="top-header">
            резерв
          </div>
          <div style="text-align: center" v-on:click="activeView = activeView !== 'asmb' ? 'asmb' : null">
            {{assembly.reserved}}
          </div>
        </td>
        <td :colspan="4" v-if="assembly">
          <div class="top-header">
            в работе
          </div>
          <div style="text-align: center" v-on:click="activeView = activeView !== 'asmb' ? 'asmb' : null">
            {{assembly.inProduction}}
          </div>
        </td>
        <td :colspan="4" v-if="assembly">
          <div class="top-header">
            в закупке
          </div>
          <div style="text-align: center" v-on:click="activeView = activeView !== 'asmb' ? 'asmb' : null">
            {{assembly.requested}}
          </div>
        </td>
      </tr>
      <tr>
        <td class="left-down-border" :colspan="cs">

          <table v-if="activeView === 'plan' && object" class="full-width">
            <tr>
              <td class="full-width plan-bcg-light">
                <div class="row full-width full-title">
                  <div class="col-12">
                    <br/>
                    <full-view-header :header="' '"
                                      @regularState="activeView = null"
                                      @editState="startEdit" @select="selectObject">
                      Header
                    </full-view-header>

                    <div class="row item-bcg margin-for-inner-obj">
                      <item :start_object="object.item" :header="'Номенклатура'"
                            :start_state="'text'" :default_state="'text'"></item>
                    </div>
                    <br/>
                    <div class="row">
                      <row-up v-if="object.comment" :header="'Коментарий:'" :value="object.comment" :col="2">Date</row-up>
                      <row-up :header="'Плановая дата отгрузки:'" :value="object.date" :as-date="true" :col="2">Date</row-up>
                      <row-up :header="'Количество, ' + object.item.measure + ':'" :col="2" :value="object.value" :as-value="true">Value</row-up>
                      <row-up :header="'Отгружено, ' + object.item.measure + ':'" :col="2" :value="object.shipped" :as-value="true">Value</row-up>
                      <row-up :header="'Цена:'" :value="object.price" :as-money="true" :col="2">Price</row-up>
                      <row-up :header="'Налог, %:'" :value="object.tax" :as-value="true" :col="2">Tax</row-up>
                    </div>
                    <br/>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <table v-if="activeView === 'planEdit' && object" class="full-width">
            <tr>
              <td class="full-width plan-bcg-light">
                <div class="full-width">
                  <edit-header @fullView="activeView = 'plan'">header</edit-header>

                  <div class="row margin-for-inner-obj">
                    <item :start_object="editObj.item" :start_state="'text'" :default_state="'text'"
                          :select="true" @select="setItem" :header="'Номенклатура:'"> Item </item>
                  </div>
                  <br/>
                  <div class="row">
                    <input-row-up v-model="editObj.comment" :header="'Комментарий:'" class="full-width" :col="10"></input-row-up>
                  </div>
                  <div class="row">

                    <input-row-up v-model="editObj.value" :header="'Количество:'" :number="true" class="full-width" :col="3"></input-row-up>

                    <input-row-up v-model="editObj.date" :header="'Дата:'" :date="true" class="full-width" :col="3"></input-row-up>

                    <input-row-up v-model="editObj.price" :header="'Цена:'" :number="true" class="full-width" :col="3"></input-row-up>

                    <input-row-up v-model="editObj.tax" :header="'Налог:'" :number="true" class="full-width" :col="3"></input-row-up>
                  </div>

                  <edit-bottom @fullView="activeView = 'plan'" @save="save" style="margin-bottom: 15px">bottom</edit-bottom>
                </div>
              </td>
            </tr>

          </table>

          <assembly v-if="assembly && activeView === 'asmb'" :start_object="assembly"
                    :start_state="'activeFull'" :default_state="'activeFull'"></assembly>
          <assembly v-if="!assembly && activeView === 'asmb'" :start_object="null" @updated="setAssembly"
                    :create="true"
                    :start_state="'active'" :default_state="'full'" :parent="asParent"></assembly>
        </td>
      </tr>
      <tr>
        <td :class="lastActive ? '' : 'left-border'" colspan="1"></td>
        <td :colspan="cs - 1">
          <assemblies-active-table v-if="assembly" :parent-assembly="assembly" :cs="cs - 1"></assemblies-active-table>
        </td>
      </tr>
    </table>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Позиция к отгрузке'" :select="select"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="state = 'edit'" @select="selectObject">
                Header
              </full-view-header>

              <div class="row item-bcg margin-for-inner-obj">
                <item :start_object="object.item" :header="'Номенклатура'"
                         :start_state="'text'" :default_state="'text'"></item>
              </div>

              <row v-if="object.comment" :header="'Комментарий:'" :value="object.comment">Comment</row>

              <div v-if="object.order && (!parent || parent.type !== 'order')" class="row item-bcg margin-for-inner-obj">
                <order :start_object="object.order" :header="'Заказ'"
                      :start_state="'text'" :default_state="'text'"></order>
              </div>

              <div v-if="object.invoice && (!parent || parent.type !== 'invoice')" class="row item-bcg margin-for-inner-obj">
                <invoice :start_object="object.invoice" :header="'Счет'"
                       :start_state="'text'" :default_state="'text'"></invoice>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <row :header="'Количество, ' + object.item.measure + ':'" :col="4" :value="object.value" :as-value="true">Value</row>
                </div>
                <div class="col-md-6">
                  <row :header="'Отгружено, ' + object.item.measure + ':'" :col="4" :value="object.shipped" :as-value="true">Value</row>
                </div>
              </div>

              <row :header="'Плановая дата отгрузки:'" :value="object.date" :as-date="true">Date</row>

              <row v-if="object.tax > 0" :header="'Цена без налога:'" :value="object.price / (1 + object.tax / 100)" :as-money="true"> Price </row>

              <row :header="'Цена:'" :value="object.price" :as-money="true">Price</row>

              <row :header="'Налог:'" :value="object.tax" :as-value="true">Tax</row>

              <div v-if="object.order" class="row assembly-bcg margin-for-inner-obj">
                <assembly :start_object="assembly" :header="'Компонеты'" :create="true"
                          :parent="this.asParent"
                          :start_state="'text'" :default_state="'text'"></assembly>
              </div>

            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <div class="row margin-for-inner-obj">
              <item :start_object="editObj.item" :start_state="'text'" :default_state="'text'"
                       :select="true" @select="setItem" :header="'Номенклатура:'"> Item </item>
            </div>

            <input-row v-model="editObj.comment" :header="'Комментарий:'" class="full-width"></input-row>

            <input-row v-model="editObj.value" :header="'Количество:'" :number="true" class="full-width"></input-row>

            <input-row v-model="editObj.date" :header="'Дата:'" :date="true" class="full-width"></input-row>

            <input-row v-model="priceTaxFree" :header="'Цена без налога:'" :number="true" class="full-width" ></input-row>

            <input-row v-model="editObj.price" :header="'Цена:'" :number="true" class="full-width"></input-row>

            <input-row v-model="editObj.tax" :header="'Налог:'" :number="true" class="full-width"></input-row>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <plan-table v-bind:objects="selectPool" v-bind:header="'Состав заказа'" @select="selectObjectFromTable"
                  :downloading="downloading.select"
                  @closeModal="closeModal"
                  v-bind:header-title="'Список позициий в заказе'" :select="select"></plan-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import {ASSEMBLY, LNK, PLAN} from "@/repository/repository";

export default {
  components: {
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      editObj: {},
      id: null,
      selectPool: [],
      asParent: {},
      assembly: null,
      viewKey: 0,
      flag: this.$flag,
      activeView: null,
      priceTaxFree: 0,
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
    cs: 0,
    lastActive: false
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    async fullView() {
      if (this.object) {
        if (this.object.id > 0) {
          this.state = this.default_state === 'active' ? 'active' : 'full';
          await this.getAssembly();
          this.viewKey++;
        } else {
          this.$emit('close', true);
        }
      }
    },
    async getAssembly() {
      let assembly = await this.$repo.getSingle('assembly/plan/' + this.object.id, ASSEMBLY, this.downloading);
      if (assembly.id > 0) {
        this.assembly = assembly;
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setItem(item) {
      this.editObj.item = item;
    },
    startEdit(){
      this.editObj = JSON.parse(JSON.stringify(this.object));
      if (this.default_state === 'active') {
        this.activeView = 'planEdit';
      } else {
        this.state = 'edit';
      }
    },
    async save() {
      let url = 'plan/' ;
      if (this.parent && this.object.id === 0) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.object = this.editObj;
      await this.$repo.uniSave(this, PLAN, url, this.downloading);
      if (this.default_state === 'active') {
        this.state = 'active';
        this.activeView = 'plan';
      }
    },
    setAssembly(object) {
      if (object && object.id > 0) {
        this.assembly = object;
      }
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      await this.$repo.getList('plan/', PLAN, this.selectPool, null, this.downloading.select);
      this.callModal();
    },
    giveData(type) {
      if (type === LNK) {
        this.getLnk(false);
      }
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(PLAN, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
    if (!this.object) {
      this.object = await this.$repo.getSingle('plan/model', PLAN, this.downloading);
    }
    this.editObj = JSON.parse(JSON.stringify(this.object));
    if (this.state === 'active') {
      await this.getAssembly();
    }
    if (this.editObj.tax > 0) {
      this.priceTaxFree = this.editObj.price / (1 + this.editObj.tax / 100);
    }
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: PLAN, id: this.object.id};
      }
    },
    priceTaxFree: function () {
      if (!this.editObj.price > 0) {
        this.editObj.tax = 20;
      }
      this.editObj.price = this.priceTaxFree * (1 + this.editObj.tax / 100);
    },
    state: function (value) {
      this.$repo.watchState(this, PLAN, value);
    },
    'flag.value': async function (val) {
      this.$repo.watchFlag(this, PLAN, val);
      if (!this.assembly && val.type === PLAN && val.id === this.object.id) {
        await this.getAssembly();
      } else {
        this.$repo.watchFlag(this, ASSEMBLY, val, 'assembly');
      }
    }
  }
}
</script>
<style>
</style>
