<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.item" class="row row-title" v-on:click="fullView()">
      <div class="col-4">
        {{ object.item.name }}
      </div>
      <div class="col-5">
        {{ object.stock.contact ? object.stock.contact.name + " - " + object.stock.name : "" }}
      </div>
      <div class="col-3">
        <span v-if="object.stock.contact">
          {{ object.inStock + ' / ' + (object.reserved ? object.reserved : '-') + ' / '}}
        </span>
        <span v-if="object.stock.contact" style="color: #0a6715">
          {{ object.stockData ? object.stockData : '-' }}
        </span>
        <span v-if="object.stock.contact">
          {{ ' ' + object.item.measure}}
        </span>
        <i v-if="select" class="nc-icon nc-check-2 float-right" v-on:click="selectObject"></i>
      </div>
    </div>

    <div v-if="state === 'row_light' && object && object.item" class="row" v-on:click="fullView()">
      <div class="col-12">
        {{ object.item.name}}
        <i v-if="select" class="nc-icon nc-check-2 float-right" v-on:click="selectObject()"></i>
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && (object.id > 0 || object.id < -100)">
        <div v-if="object.stock && object.stock.contact && object.stock.contact.name && object.stock.contact.id > 0">
          {{ object.item.name + ' (' + object.stock.contact.name + " - " + object.stock.name + '): ' +object.inStock + ' / ' + object.reserved + ' ' + object.item.measure }}
        </div>
        <div v-else>
          {{ object.item.name }}
        </div>
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Складская ячейка'" :select="select" :hide-edit="true"
                                @regularState="state = default_state ? default_state : start_state"
                                @select="selectObject">
                Header
              </full-view-header>

              <div class="row item-bcg margin-for-inner-obj">
                <item :start_object="object.item" :start_state="'text'"></item>
              </div>
              <div v-if="object.id > 0 && object.stock.contact">

                <div class="row">
                  <div class="col-3">
                    Количество / резерв / склад:
                  </div>
                  <div class="col-9">
                  <span>
                    {{ object.inStock + ' / ' + (object.reserved ? object.reserved : '-') + ' / '}}
                  </span>
                    <span style="color: #0a6715">
                    {{ object.stockData ? object.stockData : '-' }}
                  </span>
                    <span>
                     {{ ' ' + object.item.measure}}
                   </span>
                  </div>
                </div>

                <div class="row stock-bcg margin-for-inner-obj">
                  <stock :start_object="object.stock" :header="'Принадлежность'"
                         :start_state="'text'" :default_state="'text'"></stock>
                </div>

                <div v-if="!select">
                  <div class="row content-bcg margin-5">
                    <div class="col-12 unselectable">
                      <span v-if="!showReserve" v-on:click="getReserve">Резервирование</span>
                      <reserve-table v-if="showReserve" @element="hideElement('reserve')" :header="'Резервирование'"
                                     :downloading="downloading.reserve"
                                     :header-title="'Зарезервированные под выполнение заказов материалы и комплектующие'"
                                     :objects="reserves" :parent="asParent" :group-by="'assembly'">Reserves</reserve-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <stock-cell-table v-bind:objects="selectPool" v-bind:header="'Складские остатки'"
                        :downloading="downloading.select"
                        @select="selectObjectFromTable"
                        @closeModal="closeModal"
                        :header-title="'Доступные материалы и комплектующие'" :select="select"></stock-cell-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {CELL, ITEM, PLAN} from "@/repository/repository";

export default {
  components: {
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      reserves: [],
      showReserve: false,
      id: null,
      selectPool: [],
      asParent: {},
      viewKey: 0,
      flag: this.$flag,
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}, reserve: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else if (this.object.id > -100) {
        this.$emit('close', true);
      }
    },
    async getReserve() {
      this.reserves = [];
      await this.$repo.getList('stockcell/reserve/' + this.object.id, CELL, this.reserves, null, this.downloading.reserve);
      this.showReserve = true;
    },
    getBounds() {
      let bounds = undefined;
      return bounds;
    },
    hideElement(element) {
      if (element === 'reserve') {
        this.showReserve = false;
        this.reserves = [];
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      let url = 'stockcell/';
      if (this.parent) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.callModal();
      await this.$repo.getList(url, CELL, this.selectPool, null, this.downloading.select);
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    if (this.start_object) {
      this.object = (this.start_object.id && this.start_object.id > 0) ? await this.$repo.get(CELL, [this.start_object.id], this.downloading) : this.start_object;
    }
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
  },
  watch: {
    start_object: function () {
      this.viewKey++;
    },
    object: function () {
      this.asParent = {type: CELL, id: this.object ? this.object.id : 0}
    },
    state: function (value) {
      this.$repo.watchState(this, CELL, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, CELL, val);
    }
  }
}
</script>
<style>
</style>
