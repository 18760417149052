<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id > 0" class="row row-title" v-on:click="fullView()">
      <div class="col-4">
        {{ object.name }}
      </div>
      <div class="col-2">
        {{ asDate }}
      </div>
      <div class="col-3">
        {{ object.sender.name }}
      </div>
      <div class="col-3">
        {{ object.receiver.contact.name }}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          Документ
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.name }}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Документ'" :select="select"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="state = 'edit'" @select="selectObject">
                Header
              </full-view-header>

              <row :header="'Наименование:'" :value="object.name"></row>

              <row :header="'Основание:'" :value="object.basis"></row>

              <row :header="'Дата:'" :value="object.date" :as-date="true"></row>

              <div class="row company-bcg margin-for-inner-obj">
                <company :start_object="object.sender" :header="'Поставщик'"
                         :start_state="'text'" :default_state="'text'"></company>
              </div>
              <div v-if="object.stock && object.stock.contact" class="row stock-bcg margin-for-inner-obj">
                <stock :start_object="object.stock" :header="'Склад отгрузки'"
                       :start_state="'text'" :default_state="'text'"></stock>
              </div>
              <div class="row stock-bcg margin-for-inner-obj">
                <stock :start_object="object.receiver" :header="'Получатель'"
                       :start_state="'text'" :default_state="'text'"></stock>
              </div>
              <div v-if="object.order" class="row order-bcg margin-for-inner-obj">
                <order :start_object="object.order" :header="'Заказ'"
                       :start_state="'text'" :default_state="'text'"></order>
              </div>

              <div>

                <div class="row content-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span class="input-title" v-if="!showPortion" v-on:click="getPortion()">Позиции по документу</span>
                    <portion-table v-if="showPortion" @element="hideElement('portion')" :header="'Позиции'"
                                   :downloading="downloading.portion"
                                   :header-title="'Отгруженные изделия, материалы и услуги по документу '+ object.name"
                                   :objects="portions" :parent="asParent">Orders</portion-table>
                  </div>
                </div>

                <div class="row note-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span class="input-title" v-if="!showNote" v-on:click="getNote(true)">Заметки</span>
                    <note-table v-if="showNote" @element="hideElement('note')" :header="'Заметки'"
                                :downloading="downloading.note"
                                :header-title="'Заметки и примечания по документу '+ object.name"
                                :show-all="true"
                                :notes="notes" :parent="asParent">Documents out</note-table>
                  </div>
                </div>

              </div>

              <div class="row full-width rounded-card grey-bcg">
                <div class="col-md-12">
                  <sign :start_state="'text'" :start_object="object.sign" @select="setSign"
                          :header="'Подпись в документе'" :select="true" :parent="asParent">Sign</sign>

                  <div class="row" style="margin-top: 10px">
                    <div class="col-md-3" style="margin-left: 5px">
                      <span>Номер документа</span>
                    </div>
                    <div class="col-md-8">
                      <button v-if="!counter"
                        class="btn btn-light btn-fill btn-usm" v-on:click="askNumber">
                        Запросить
                      </button>
                      <active-input-element v-else :object="counter" :field="'number'" @update="saveCounter"/>

                    </div>
                  </div>

                  <div class="row" style="margin-top: 10px">
                    <div class="col-md-12">
                      <button
                              class="btn btn-light btn-fill btn-usm float-right margin-right-icon" v-on:click="getForm">
                        Печать
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Наименование:'" class="full-width"></input-row>

            <input-row v-model="object.basis" :header="'Основание:'" class="full-width"></input-row>

            <input-row v-model="object.date" :header="'Дата:'" :date="true" class="full-width"></input-row>

            <div :key="selectZone">
              <div class="row margin-for-inner-obj"
                   v-if="object.id === 0 && (!parent || (parent.type !== 'contact' && parent.type !== 'order'))">
                <company :start_object="object.sender" :start_state="'text'" :default_state="'text'"
                         :select="true" @select="setSender" :header="'Поставщик:'"> Sender </company>
              </div>

              <div class="row margin-for-inner-obj">
                <stock :start_object="object.stock" :start_state="'text'" :default_state="'text'"
                       :parent="{type: 'department', id: object.sender? object.sender.id : null}"
                       :select="true" @select="setStock" :header="'Склад отгрузки:'"> Receiver </stock>
              </div>

              <div class="row margin-for-inner-obj"
                   v-if="object.id === 0 && (!parent || (parent.type !== 'stock' && parent.type !== 'order'))">
                <stock :start_object="object.receiver" :start_state="'text'" :default_state="'text'"
                       :select="true" @select="setReceiver" :header="'Получатель:'"> Receiver </stock>
              </div>

              <edit-bottom :extra-keys="object.order && object.id === 0 ? [{name: 'Полная отгрузка', action: 'bigSave'}] : []"
                           @bigSave="save(true)" @fullView="fullView" @save="save">bottom</edit-bottom>
            </div>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <downloading v-if="downloading && downloading.state">
        Downloading
      </downloading>
      <document-table v-bind:objects="selectPool" v-bind:header="'Документы'" @select="selectObjectFromTable"
                      :downloading="downloading.select"
                      @closeModal="closeModal"
                      v-bind:header-title="'Документы на отгрузки и выполненные работы'" :select="select"></document-table>

    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {DOCUMENT} from "@/repository/repository";

export default {
  components: {
    CompaniesTable,
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
    extraKeys: function () {
      let rsl = [];
      rsl.unshift({name: 'Печать', action: 'print'});
      if (this.object.sign) {

      }
      return rsl;
    }
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      portions: [],
      showPortion: false,
      lnks: [],
      showLnk: false,
      notes: [],
      showNote: false,
      id: null,
      selectPool: [],
      asParent: {},
      selectZone: 0,
      showWarning: false,
      warningHeader: null,
      warningKeys: null,
      showNumber: false,
      counter: null,
      viewKey: 0,
      flag: this.$flag,
      showSelect: false,
      downloading: {state: false, select: {state: false}, portion: {state: false}, lnk: {state: false},
        note: {state: false}, history: {state: false}}
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    async fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    async getPortion() {
      this.portions = [];
      this.showPortion = true;
      await this.$repo.getList('document/portion/' + this.object.id,'portion', this.portions, null, this.downloading.portion);
    },
    async getNote() {
      this.notes = [];
      this.showNote = true;
      await this.$repo.getList('document/note/' + this.object.id,'note', this.notes, null, this.downloading.note);
    },
    hideElement(element) {
      if (element === 'portion') {
        this.showPortion = false;
        this.portions = [];
      } else if (element === 'note') {
        this.showNote = false;
        this.notes = [];
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setSender(contact) {
      if (this.parent && this.parent.type !== 'contact' && this.parent.type !== 'order') {
        this.object.sender = contact;
        if (this.object.order && this.object.order.contact !== contact) {
          this.object.order = undefined;
        }
      }
      this.selectZone += 1;
    },
    setStock(stock) {
      this.object.stock = stock;
    },
    setReceiver(stock) {
      if (this.parent && this.parent.type !== 'stock' && this.parent.type !== 'order') {
        this.object.receiver = stock;
        if (this.object.order && this.object.order.consumer !== stock) {
          this.object.order = undefined;
        }
      }
      this.selectZone += 1;
    },
    setOrder(order) {
      if (this.parent && this.parent.type !== 'order') {
        this.object.order = order;
        if (this.parent) {
          if (this.parent.type === 'contact') {
            this.object.receiver = order.consumer;
          }
          if (this.parent.type === 'stock') {
            this.object.sender = order.contact;
          }
        } else {
          this.object.receiver = order.consumer;
          this.object.sender = order.contact;
        }
      }
      this.selectZone += 1;
    },
    setSign(sign) {
      this.object.sign = sign;
      this.save();
    },
    save(bigSave) {
      let url = bigSave ? 'document/close' : 'document/';
      if (this.parent && this.object.id === 0) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.$repo.uniSave(this, DOCUMENT, url, this.downloading);
    },
    async getForm() {
      let url = 'document/form/' + this.object.id;
      if (this.counter.id === 0) {
        await this.saveCounter();
      }
      this.showNumber = false;
      await this.$repo.downloadFile(url, 'УПД №' + this.counter.number + ' ' + (this.object.receiver.legalShortName ? this.object.receiver.legalShortName : this.object.receiver.name) + '.xls', this.downloading);
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    setImage(image) {
      this.object.image = image;
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
    },
    async openSelectModal() {
      await this.$repo.getSelectPoll(DOCUMENT, this.selectBounds, this.selectPool, this.downloading.select);
      this.callModal()
    },
    getBounds() {
      if (this.parent) {
        return [{includes: true, type: this.parent.type, id: this.parent.id}]
      } else {
        return undefined;
      }
    },
    async askNumber() {
      this.counter = await this.$repo.justGetData('document/number/' + this.object.id, this.downloading);
    },
    async saveCounter() {
      this.counter = await this.$repo.save('dc/', 'dc', this.counter, this.downloading);
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(DOCUMENT, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (!this.object) {
      this.object = await this.$repo.justGetData('document/model', this.downloading);
    }
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: DOCUMENT, id: this.object.id};
      }
    },
    state: async function (value) {
      this.$repo.watchState(this, DOCUMENT, value);
      if (value === 'full' && this.object.id > 0) {
        let counter = await this.$repo.justGetData('document/number/' + this.object.id, this.downloading);
        if (counter && counter.id > 0) {
          this.counter = counter;
        }
      }
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, DOCUMENT, val);
    }
  }
}
</script>
<style>
</style>
