<template>
  <div class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'asImage'">
      <span v-if="select" v-on:click="askDataAndCallModal">
        <img :alt="'Файл: ' + lnk.name" v-if="lnk && lnk.id" :src="preview" class="img-320">
         <span v-else class="unselectable">
            <a class="input-title">
              Выбрать изображение
            </a>
          <i class="nc-icon nc-album-2"></i>
        </span>
      </span>
      <span v-else>

        <img :alt="'Файл: ' + lnk.name" v-if="lnk && lnk.id" :src="preview" :class="fullPreview ? '' : 'img-320'"
             v-on:click="fullPreview=!fullPreview">
        <b v-if="fullPreview" v-on:click="downloadFile" class="unselectable">
          Загрузка файла
          <i class="nc-icon nc-cloud-download-93"></i>
        </b>
      </span>
    </div>

    <div v-if="state === 'asFile'">
      <span v-if="!lnk || !lnk.id" v-on:click="state = 'edit'">
        Выбрать файл
        <i class="nc-icon nc-album-2"></i>
      </span>
      <span v-else>
        <span>
          {{ lnk.name }}
          <i class="nc-icon nc-settings-tool-66" style="margin-left: 10px" v-on:click="startSelect"></i>
        </span>
      </span>
    </div>

    <div v-if="state === 'row' && lnk" class="row row-title" v-on:click="fullView()">
      <div class="col-5">
        {{ lnk.name }}
      </div>
      <div class="col-7">
        {{ lnk.description }}
        <span v-if="select">
          <i class="nc-icon nc-check-2 float-right" v-on:click="selectLnk()"></i>
        </span>
      </div>
    </div>

    <div v-if="state === 'full' && lnk">
      <full-view-header :header="'Файл'" :select="select"
                        @regularState="state = 'row'"
                        :extra-keys="[{name: 'Обновить предосмотр', action: 'viewup'}]"
                        @viewup= "updatePreview"
                        @editState="state = 'edit'" @select="selectLnk">
        Header
      </full-view-header>

      <div class="row">
        <div class="col-3">
          Имя файла:
        </div>
        <div class="col-9">
          {{ lnk.name }}
        </div>
      </div>

      <img :alt="'Файл: ' + lnk.name"
        v-if="isImage() && lnk && lnk.id"
        :src="preview"
        :key="imgKey"
        :class="fullPreview?'':'img-320'" v-on:click="fullPreview=!fullPreview" v-on:dblclick="downloadFile">
      <div class="row">
        <div class="col-12">
          <span v-on:click="downloadFile">
            Загрузка файла
            <i class="nc-icon nc-cloud-download-93"></i>
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          Описание:
        </div>
        <div class="col-9">
          {{ lnk.description }}
        </div>
      </div>
    </div>

    <div v-if="state === 'edit' && lnk">
      <edit-header @fullView="fullView" @save="save">header</edit-header>
      <br/>
      <form v-if="!lnk.id || lnk.id === 0 || changeable" id="uploadForm" enctype="multipart/form-data">
        <input v-if="!accept_list" type="file" id="file" name="file">
        <input v-else type="file" id="file" name="file" :accept="accept_list">
      </form>

      <input-row v-model="lnk.description" :header="'Описание:'" class="full-width"></input-row>

      <edit-bottom v-if="!inSave" @fullView="fullView" @save="save">bottom</edit-bottom>

    </div>

    <div v-if="showSelect" :id="id" class="full-width">
      <lnk-table :objects="selectPool" :header="'Изображения'" @select="selectImageFromTable"
                 :downloading="downloading.select"
                 @close="closeModal" :header-title="'Связанные изображения'" :select="select"></lnk-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import ItemCategorySelect from "../selectable/ItemCategorySelect";
import {API_URL, LNK} from "@/repository/repository";

export default {
  components: {
    ItemCategorySelect
  },
  data () {
    return {
      key: authHeader(),
      id: null,
      lnk: {},
      state: '',
      api_url: API_URL,
      inSave: false,
      preview: null,
      imgKey: 0,
      showSelect: false,
      downloading: {state: false, select: {state: false}},
      fullPreview: false,
    }
  },
  props: {
    lnk_start: {},
    state_start: null,
    select: false,
    parent: null,
    selectPool: [],
    accept_list: null,
    changeable: false
  },
  methods: {
    fullView(id) {
      if (this.lnk.id > 0) {
        this.state = this.state_start !== 'asFile' ? 'full' : this.state_start;
        this.$emit('updated', this.lnk);
      } else if(this.state_start === 'asFile') {
        this.state = 'asFile';
      } else {
        this.$emit('close', id);
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    async save() {
      if (this.lnk.id > 0 && !this.changeable) {
        this.lnk = await this.$repo.save('lnk/', LNK, this.lnk, this.downloading);
        this.fullView();
      } else {
        this.lnk.id = 0;
        await this.upload();
      }
    },
    isImage() {
      let name = this.lnk.name.substring(this.lnk.name.lastIndexOf('.') + 1).toLowerCase();
      return ['jpg', 'pdf', 'png', 'jpeg', 'gif', 'bmp'].includes(name);
    },
    async upload() {
      this.inSave = true;
      try {
        let url = 'lnk/upload' + '?description=' + this.lnk.description;
        if (this.parent) {
          url = url + '&type=' + this.parent.type + '&id=' + this.parent.id;
        }
        let up = document.querySelector('#file');
        let formData = new FormData();
        formData.append("file", up.files[0]);
        this.lnk = await this.$repo.uploadFile(url, formData, this.downloading);
        this.$emit('updateNew', this.lnk);
        this.fullView();
        this.inSave = false;
      } catch (e) {
        this.inSave = false;
      }
    },
    async downloadFile() {
      let url = 'lnk/download/' + this.lnk.id + '/' + this.lnk.name;
      await this.$repo.downloadFile(url, this.lnk.name, this.downloading);
    },
    async updatePreview() {
      await this.$repo.post(`${LNK}/pr`, this.lnk, this.downloading);
      this.preview = await this.$repo.getPreview(this.lnk.id);
      this.imgKey++;
    },
    selectLnk() {
      this.$emit('select', this.lnk);
    },
    selectImageFromTable(lnk) {
      this.closeModal();
      this.lnk = lnk;
      this.selectLnk();
    },
    askDataAndCallModal() {
      this.$emit('callData', LNK);
      this.callModal();
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
    },
    startSelect() {
      this.state = 'edit';
    },
  },
  mounted: async function () {
    this.id = this._uid;
    if (this.lnk_start) {
      this.lnk = this.lnk_start;
    }
    this.preview = await this.$repo.getPreview(this.lnk.id);
    this.state = this.state_start;
  }
}
</script>
<style>
</style>
