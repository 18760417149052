<template>
  <div class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && note" class="row row-title" v-on:click="fullView()">
      <div class="col-8">
        {{ asTextPreview }}
      </div>
      <div class="col-2">
        {{ asDate }}
      </div>
      <div class="col-2">
        {{ note.user ? note.user.name : "-"}}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          Заметка
        </a>
      </div>
      <div class="col-9" v-if="note && note.id > 0">
        {{ asTextPreview }}
      </div>
    </div>

    <table v-if="state === 'full' && note" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Заметка'" :select="select" :hide-edit="true"
                                @regularState="state = start_state">
                Header
              </full-view-header>

              <div class="row">
                <div class="col-3">
                  Заметка:
                </div>
                <div class="col-9">
                  {{ note.note }}
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  Дата:
                </div>
                <div class="col-9">
                  {{ asDate }}
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  Автор:
                </div>
                <div class="col-9">
                  {{ note.user.name }}
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && note && note.id === 0" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="note.note" :header="'Заметка:'" class="full-width"></input-row>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import {NOTE} from "@/repository/repository";

export default {
  components: {
    Table
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      note: {},
      id: null,
      selectPool: [],
      asParent: {},
      downloading: {state: false, select: {state: false}},
    }
  },
  computed: {
    asDate: function () {
      if (this.note && this.note.date) {
        let date = new Date(this.note.date);
        return date.customFormat('#DD#/#MM#/#YYYY# #hh#:#mm#');
      }
      return "-";
    },
    asTextPreview: function () {
      if (this.note) {
        let text = '';
        if (this.note.note && this.note.note.length > 110) {
          text = this.note.note.substring(0, 110) + '...';
        } else {
          text = this.note.note;
        }
        return text;
      }
      return "-";
    }
  },
  props: {
    parent: null,
    start_note: {},
    start_state: '',
    select: false,
    selectBounds: null
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    async fullView() {
      if (this.note.id > 0) {
        this.note = await this.$repo.getSingle('note/' + this.note.id, NOTE, this.downloading);
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    async save() {
      if (this.parent && this.note.id === 0) {
        let url = 'note/new?type=' + this.parent.type + '&id=' + this.parent.id;
        let obj = await this.$repo.save(url, NOTE, this.note, this.downloading);
        if (obj) {
          this.$emit('updated', obj);
          if (this.start_state === 'edit') {
            this.state = 'row';
          }
        }
      }
    }
  },
  mounted: function () {
    this.state = this.start_state;
    this.note = this.start_note;
    this.id = this._uid;
    if (!this.note) {
      this.note = this.$repo.justGetData('note/model', this.downloading);
    }
  },
  watch: {
    note: function () {
      this.asParent = {type: NOTE, id: this.note.id}
    }
  }
}
</script>
<style>
</style>
