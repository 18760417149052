<template>
  <div class="row">
    <div class="col-12">
      <card body-classes="table-full-width" style="margin: 5px">
        <template slot="header">
              <span v-if="!select" class="float-right unselectable" v-on:click="addNew()">
                new
                <i class="nc-icon nc-simple-add"></i>
              </span>
          <h4 class="card-title unselectable" v-on:click="headerClick()">{{ header }}</h4>
          <p class="card-category">{{ headerTitle }}</p>
        </template>
        <table class="table table-hover table-striped">
          <thead>
          <slot name="columns">
            <tr>
              <th class="row-title">
                <div class="row" style="width: 100%">
                  <div class="col-8 unselectable">
                        <span>
                          Содержание
                        </span>
                  </div>
                  <div class="col-2 unselectable">
                        <span v-on:click="sortBy = {key: 'date', asc: !sortBy.asc}">
                          Дата
                        </span>
                  </div>
                  <div class="col-2 unselectable">
                        <span v-on:click="sortBy = {key: 'user.name', asc: !sortBy.asc}">
                          Автор
                        </span>
                  </div>
                </div>
              </th>
            </tr>
          </slot>
          </thead>
          <tbody :key="tableKey">
          <tr v-if="newObj">
            <td>
              <note :start_note="newObj" :start_state="'edit'" :parent="parent"
                    @close="cancelNew" :select="select" @updated="addToList"></note>
            </td>
          </tr>
          <tr v-for="(note, index) in notes" :key="index">
            <td v-if="limit === 0 || showAll || index < limit">
              <note v-if="note.id > 0" :start_note="note" :start_state="'row'"
                    :select="select" @select="selectNote"></note>
            </td>
          </tr>
          <tr v-if="downloading && downloading.state">
            <downloading :downloading="downloading">
              Downloading
            </downloading>
          </tr>
          </tbody>
        </table>
      </card>
    </div>
  </div>
</template>
<script>
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'
import {NOTE} from "@/repository/repository";

export default {
  components: {
    LTable,
    Card
  },
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'date', asc: false},
      filter: new Map,
      tableKey: 0,
      newObj: null,
    }
  },
  props: {
    notes: [],
    header: null,
    headerTitle: null,
    select: false,
    parent: null,
    limit: 0,
    showAll: false,
    downloading: null,
  }
  ,
  methods: {
    sort() {
      sorter(this.notes, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    async addNew() {
      if (!this.newObj) {
        this.newObj = await this.$repo.getSingle('note/model', NOTE, this.downloading);
      }
    },
    addToList(object) {
      if (object.id > 0) {
        this.newObj = null;
        this.notes.unshift(object);
      }
    },
    cancelNew() {
      this.newObj = null;
    },
    selectNote(item) {
      this.$emit('select', item);
    },
    headerClick() {
      if (this.select) {
        this.$emit('closeModal');
      } else if (this.limit > 0) {
        this.showAll = !this.showAll;
        this.sortBy = {key: 'date', asc: false};
      } else {
        this.$emit('element', 'note');
      }
    },
  },
  watch: {
    sortBy: function () {
      this.sort()
    },
    notes: function (){
      console.log(`tableKey=${this.tableKey}`);
      this.tableKey += 1;
    }
  },
  async mounted() {

  }
}
</script>
<style>
</style>
