<template>
  <table v-if="parentAssembly" class="full-width">
    <colgroup>
      <col v-for="index in cs" :style="'width:' + 100/cs + '%'" :key="index">
    </colgroup>
    <tr v-for="(object, index) in objects" :key="index">
      <td :colspan="cs">
        <table class="full-width" style="margin-top: -2px">
          <colgroup>
            <col v-for="index in cs" :style="'width:' + 100/cs + '%'" :key="index">
          </colgroup>
          <tr>
            <td :colspan="cs" class="left-down-border">
              <assembly :start_object="object" start_state="activeRow" default_state="activeRow" :cs="cs"> </assembly>
            </td>
          </tr>
          <tr>
            <td colspan="1" :class="index < objects.length - 1 ? 'left-border' : ''">
            </td>
            <td :colspan="cs-1">
              <assemblies-active-table :parent-assembly="object" :cs="cs - 1"></assemblies-active-table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</template>
<script>

import {addToList} from '@/services'
import {ASSEMBLY} from "@/repository/repository";
import Table from "@/components";

export default {
  components: {Table},
  data () {
    return {
      sortBy: {key: 'name', asc: true},
      filter: new Map,
      tableKey: 0,
      newObj: null,
      objects: [],
      flag: this.$flag
    }
  },
  props: {
    parentAssembly: null,
    header: null,
    headerTitle: null,
    select: false,
    parent: null,
    cs: null,
    downloading: null
  }
  ,
  methods: {
    async addNew() {
      if (!this.newObj) {
        let url = 'assembly/model';
        if (this.parent) {
          url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
        }
        this.newObj = await this.$repo.getSingle(url, 'assembly', this.downloading);
      }
    },
    addToList(object) {
      addToList(object, this);
    },
    cancelNew() {
      this.newObj = null;
    },
    selectObject(object) {
      this.$emit('select', object);
    },
    headerClick() {
      if (this.select) {
        this.objects = []
        this.$emit('closeModal');
      } else {
        this.$emit('element');
        this.$emit('update');
      }
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    },
    objects: function (){
      this.tableKey += 1;
    },
    'flag.value': async function (val) {
      if (val && ASSEMBLY === val.type && this.parentAssembly.id === val.id) {
        let newList = [];
        await this.$repo.getList('assembly/content/' + this.parentAssembly.id, ASSEMBLY, newList, {key: 'item.name', asc: true}, this.downloading);
        newList.forEach(obj => this.addToList(obj));
      }
    }
  },
  async mounted() {
    await this.$repo.getList('assembly/content/' + this.parentAssembly.id, ASSEMBLY, this.objects, {key: 'item.name', asc: true}, this.downloading);
  }
}
</script>
<style>
</style>
