<template>
  <div>
    <div v-for="(block, index) in cols" :key="index">
      <div :class="`col-${block.first}`">
        <a class="input-title">
          {{ block.name }}
        </a>
      </div>
      <div :class="`col-${block.second}`">
        <input type="checkbox" id="scales" name="scales" v-model="object[block.field]" checked>
        <span style="margin-left: 10px">
                  {{object[block.field] ? block.yes : block.no}}
                </span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      cols: [],
      object: {},
    },
    data () {
    },
    methods: {
    },
    mounted: function () {
    }
  }
</script>
<style>
</style>
