<template>
      <div class="row">
        <div class="col-12">
          <card body-classes="table-full-width" style="margin: 5px">
            <template slot="header">
              <span class="float-right unselectable" v-on:click="addNew()">
                new
                <i class="nc-icon nc-simple-add"></i>
              </span>
              <h4 class="card-title unselectable" v-on:click="hide()">{{ header}}</h4>
              <p class="card-category">{{ headerTitle }}</p>
            </template>
            <table class="table table-hover table-striped">
              <thead>
              <slot name="columns">
                <tr>
                  <th class="row-title">
                    <div class="row" style="width: 100%">
                      <div class="col-4 unselectable">
                        <span v-on:click="sortBy = {key: 'item.name', asc: !sortBy.asc}">
                          Наименование
                        </span>
                      </div>
                      <div class="col-4 unselectable">
                        <span v-on:click="sortBy = {key: 'item.category.name', asc: !sortBy.asc}">
                          Категория
                        </span>
                        <item-category @filter="catchFilter" v-bind:field="'item.category'"> Category Selector </item-category>
                      </div>
                      <div class="col-2 unselectable">
                        Количество
                      </div>
                      <div class="col-2 unselectable">
                        Единица измерения
                      </div>
                    </div>
                  </th>
                </tr>
              </slot>
              </thead>
              <tbody :key="tableKey">
              <tr v-if="newObj">
                <td>
                  <item-content :start_object="newObj" :start_state="'edit'"
                                :parent="parent" @close="cancelNew" @updated="addToList"></item-content>
                </td>
              </tr>
              <tr v-for="(object, index) in objects" :key="index">
                <td>
                  <item-content v-if="object.id > 0" :start_object="object" :start_state="'row'"
                                @close="cancelNew" @updated="addToList"></item-content>
                </td>
              </tr>
              <tr v-if="downloading && downloading.state">
                <downloading :downloading="downloading">
                  Downloading
                </downloading>
              </tr>
              </tbody>
            </table>
          </card>
        </div>
      </div>
</template>
<script>
import Card from 'src/components/Cards/Card.vue'
import {authHeader} from "@/auth";
import ItemContent from "../elements/ItemContent";
import ItemCategory from "../elements/ItemCategory";
import {sorter, fitFilter, updateFilter, addToList} from '@/services'

export default {
  name: 'contents-table',
  components: {
    ItemContent,
    Card,
    ItemCategory
  },
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'item.name', asc: true},
      filter: new Map,
      tableKey: 0,
      newObj: null
    }
  },
  props: {
    objects: null,
    header: null,
    headerTitle: null,
    parent: null,
    downloading: null,
  },
  methods: {
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    async addNew() {
      if (!this.newObj) {
        this.newObj = await this.$repo.justGetData('content/model', this.downloading);
      }
    },
    addToList(object) {
      addToList(object, this);
    },
    cancelNew() {
      this.newObj = null;
    },
    hide() {
      this.$emit('element', 'content');
    }
  },
  watch: {
    sortBy: function () {
     // this.sort()
    },
    objects: function () {
      this.tableKey += 1;
    }
  }
}
</script>
<style>
</style>
