<template>
  <div class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id > 0" class="row row-title" v-on:click="selectObject()">
      <div class="col-12">
        {{ object.name }}
        <span v-if="select">
          <i class="nc-icon nc-check-2 float-right"></i>
        </span>
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.name}}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <div v-if="state === 'selector'">
      <div v-if="object && object.name">
        <i class="nc-icon nc-simple-remove margin-right-icon" v-on:click="clearSelector()"></i>
        <a class="input-title" v-on:click="dispatcher()">
          {{object.name}}
        </a>
      </div>
      <div v-else v-on:click="dispatcher()">
        Статус заказа
      </div>
    </div>

    <div v-if="showSelect" :id="id" class="full-width">
      <order-status-table v-bind:objects="selectPool" v-bind:header="'Статус заказа'" @select="selectObjectFromTable"
                          :downloading="downloading.select"
                          @closeModal="closeModal"
                          v-bind:header-title="'Стадии выполнения заказа'" :select="select"></order-status-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {ITEM, LNK, ORDER_STATUS} from "@/repository/repository";

export default {
  components: {
    Table
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      default_state: null,
      object: {},
      id: null,
      selectPool: [],
      asParent: {},
      downloading: {state: false, select: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    start_default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
    showSelect: false,
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    async fullView() {
      if (this.object.id > 0) {
        this.object = await this.$repo.getSingle('orderstatus/' + this.object.id, ORDER_STATUS, this.downloading);
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    clearSelector() {
      this.object = 0;
      this.selectObject();
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      await this.$repo.getList('orderstatus/', ORDER_STATUS, this.selectPool, null, this.downloading.select);
      this.callModal();
    },
    giveData(type) {
      if (type === LNK) {
        this.getLnk(false);
      }
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object ? await this.$repo.get(ORDER_STATUS, [this.start_object.id], this.downloading) : null
    this.id = this._uid;
    this.default_state = this.start_default_state ? this.start_default_state : this.start_state;
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: ORDER_STATUS, id: this.object.id};
      }
    }
  },
}
</script>
<style>
</style>
