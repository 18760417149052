<template>
  <div class="form-group"
       :class="{
          'input-group': hasIcon,
          'input-group-focus': focused
       }">
    <slot name="label">
      <label v-if="label" class="control-label">
        {{label}}
      </label>
    </slot>
    <slot name="addonLeft">
      <span v-if="addonLeftIcon" class="input-group-prepend">
        <div class="input-group-text">
          <i :class="addonLeftIcon"></i>
        </div>
      </span>
    </slot>
    <slot>
      <input v-if="date"
             :value="dateData"
             type="date"
             v-bind="$attrs"
             v-on="listeners"
             class="form-control"
             aria-describedby="addon-right addon-left">
      <input v-else
             :value="value"
             v-bind="$attrs"
             v-on="listeners"
             class="form-control"
             aria-describedby="addon-right addon-left">
    </slot>
    <slot name="addonRight">
      <span v-if="addonRightIcon" class="input-group-append">
        <div class="input-group-text">
          <i :class="addonRightIcon"></i>
        </div>
      </span>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
  export default {
    inheritAttrs: false,
    name: "base-input",
    props: {
      label: {
        type: String,
        description: "Input label"
      },
      value: {
        type: [String, Number],
        description: "Input value"
      },
      addonRightIcon: {
        type: String,
        description: "Input icon on the right"
      },
      addonLeftIcon: {
        type: String,
        description: "Input icon on the left"
      },
      date: false,
      number: false
    },
    model: {
      prop: 'value',
      event: 'input'
    },
    data() {
      return {
        focused: false,
        dateData: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      }
    },
    computed: {
      hasIcon() {
        const { addonRight, addonLeft } = this.$slots;
        return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
      },
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
          blur: this.onBlur,
          focus: this.onFocus
        }
      }
    },
    methods: {
      onInput(evt) {
        if (this.date) {
          let date = new Date(evt.target.value);
          this.dateData = date.toISOString().slice(0, 10);
          this.$emit('input', date.getTime());
        } else {
          let value = evt.target.value;
          if (this.number && !this.digits.includes(evt.data)) {
            value = value.slice(0, -1);
            evt.target.value = value;
            if (!value.includes('.')) {
              value += '.';
            }
          }
          this.$emit('input', value);
        }
      },
      onFocus() {
        this.focused = true;
      },
      onBlur() {
        this.focused = false;
      }
    },
    mounted() {
      if (this.date) {
        if (this.value && this.value > 0) {
          this.dateData = new Date(this.value).toISOString().slice(0, 10);
        } else {
          this.dateData = new Date().toISOString().slice(0, 10);
          this.$emit('input', new Date().getTime());
        }
      } else if (!this.value) {
        this.$emit('input', '');
      }
    }
  }
</script>
<style>

</style>
