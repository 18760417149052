<template>
  <div class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.item" class="row row-title" v-on:click="fullView()">
      <div class="col-5">
        {{ object.item.name + (object.comment ? (' (' + object.comment + ')') : '') }}
      </div>
      <div class="col-4">
        {{ consumerName }}
      </div>
      <div class="col-1">
        {{ round(object.value - object.shipped) + ' ' + object.item.measure}}
      </div>
      <div class="col-2">
        <date-warning :value="object.date" > Date </date-warning>
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.item.name + (object.comment ? (' (' + object.comment + ')') : '') + ' - ' + object.value + ', ' + object.item.measure + ' до ' + asDate}}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Позиция к отгрузке'" :select="select" :hide-edit="true"
                                @regularState="state = default_state ? default_state : start_state">
                Header
              </full-view-header>

              <div class="row item-bcg margin-for-inner-obj">
                <item :start_object="object.item" :header="'Номенклатура'"
                         :start_state="'text'" :default_state="'text'"></item>
              </div>

              <row v-if="object.comment" :header="'Комментарий:'" :value="object.comment">Comment</row>

              <div v-if="!parent || parent.type !== 'order'" class="row order-bcg margin-for-inner-obj">
                <order :start_object="object.order" :header="'Заказ:'"
                       :start_state="'text'" :default_state="'text'"></order>
              </div>

              <row :header="'Заказчик:'" :value="consumerName">Consumer</row>

              <row :header="'Количество к отгрузке, ' + object.item.measure + ':'" :value="round(object.value - object.shipped)">Quantity</row>

              <row :header="'Плановая дата отгрузки:'" :value="asDate">Quantity</row>

              <row v-if="!hidePrice" :header="'Цена:'" :value="object.price" :as-money="true">Price</row>

              <row v-if="!hidePrice" :header="'Налог:'" :value="object.tax" :as-money="true">Price</row>

            </div>
          </div>
        </td>
      </tr>
    </table>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";

export default {
  components: {
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
    consumerName: function () {
      return this.object.order.consumer.contact.name +' (' + this.object.order.consumer.name + ')';
    }
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      id: null,
      selectPool: [],
      asParent: {},
      downloading: {state: false, select: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
    hidePrice: false
  },
  methods: {
    dispatcher() {
      this.fullView()
    },
    fullView() {
      this.state = 'full'
    },
    round(val) {
      val = (Math.round(val * 1000) / 1000);
      return val % 1 === 0 ? val : val.toFixed(3);
    }
  },
  mounted: function () {
    this.state = this.start_state;
    this.object = this.start_object;
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
  },
  watch: {
    object: function () {
      this.asParent = {type:'plan', id: this.object.id}
    }
  }
}
</script>
<style>
</style>
