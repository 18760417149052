<template>
  <div class="content">
    <div class="container-fluid">
      <items-table :objects="items" :header="'Номенклатура'"
                   :downloading="downloading"
                   :header-title="'Перечень продукции и материалов'"
                   @update="getItems">
      </items-table>
    </div>
  </div>
</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'
import ItemsTable from "../tables/ItemsTable";

export default {
  name: 'items',
  components: {
    ItemsTable,
  },
  data () {
    return {
      items: [],
      key: authHeader(),
      sortBy: {key: 'name', asc: true},
      filter: new Map,
      tableKey: 0,
      downloading: {state: false, history: {state: false}},
    }
  },
  methods: {
    async getItems() {
      this.items = [];
      await this.$repo.getList('item/', 'item', this.items, this.sortBy, this.downloading);
    },
    sort() {
      sorter(this.items, this.sortBy);
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    }
  },
  created() {
    this.getItems();
  }
}
</script>
<style>
</style>
