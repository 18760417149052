<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object" class="row row-title" v-on:click="fullView()">
      <div class="col-6">
        {{ object.name }}
      </div>
      <div class="col-6">
        {{ object.position }}
        <span v-if="select">
          <i class="nc-icon nc-check-2 float-right" v-on:click="selectObject()"></i>
        </span>
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.name + ': ' + object.position }}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Подпись'" :select="select"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="state = 'edit'" @select="selectObject">
                Header
              </full-view-header>

              <div class="row">
                <div class="col-3">
                  Ф.И.О.:
                </div>
                <div class="col-9">
                  {{ object.name }}
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  Должность:
                </div>
                <div class="col-9">
                  {{ object.position }}
                </div>
              </div>

            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name"
                       :header="'Ф.И.О.'"
                       class="full-width"></input-row>

            <input-row v-model="object.position"
                       :header="'Должность'"
                       class="full-width"></input-row>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <sign-table v-bind:objects="selectPool" v-bind:header="'Подписи'" @select="selectObjectFromTable"
                  :downloading="downloading.select"
                  @closeModal="closeModal"
                  v-bind:header-title="'ФИО и должность для пдписания документа'" :select="select"></sign-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {ITEM, PLAN, SIGN} from "@/repository/repository";

export default {
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
    plan: function () {
      let assembly = this.object.assembly;
      while (!assembly.plan && assembly.parent) {
        assembly = assembly.parent;
      }
      return assembly.plan;
    }
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      id: null,
      selectPool: [],
      asParent: {},
      viewKey: 0,
      flag: this.$flag,
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    save() {
      let url = 'sign/';
      if (this.parent && this.object.id === 0) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.$repo.uniSave(this, SIGN, url, this.downloading);
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      let url = 'sign/'
      if (this.parent) {
        url += this.parent.type + '/' + this.parent.id;
      }
      await this.$repo.justGetList(url, this.selectPool, {key: 'name', asc: true}, this.downloading.select);
      this.callModal();
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(SIGN, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: SIGN, id: this.object.id}
      }
    },
    state: function (value) {
      this.$repo.watchState(this, SIGN, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, SIGN, val);
    }
  }
}
</script>
<style>
</style>
