<template>
  <div class="row full-width">
    <div :class="'col-' + (col ? col : 3)">
      <a class="input-title">
        {{ header }}
      </a>
    </div>
    <div :class="'col-' + (col ? col : 9)">
      {{ text }}
      <img v-if="copyable" v-on:click="copyText" src="/img/icons8-copy-50.png" width="15px" style="margin-left: 10px">
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      header: null,
      value: null,
      asValue: false,
      asMoney: false,
      asDate: false,
      col: null,
      copyable: false
    },
    data () {
      return {
        text: null
      }
    },
    methods: {
      hasValue (item, column) {
        return item[column.toLowerCase()] !== 'undefined'
      },
      itemValue (item, column) {
        return item[column.toLowerCase()]
      },
      async copyText() {
        if (navigator.clipboard && window.isSecureContext) {
          await navigator.clipboard.writeText(this.text);
        } else {
          // Use the 'out of viewport hidden text area' trick
          const textArea = document.createElement("textarea");
          textArea.value = this.text;

          // Move textarea out of the viewport so it's not visible
          textArea.style.position = "absolute";
          textArea.style.left = "-999999px";

          document.body.prepend(textArea);
          textArea.select();

          try {
            document.execCommand('copy');
          } catch (error) {
            console.error(error);
          } finally {
            textArea.remove();
          }
        }
      }
    },
    mounted: function () {
      if (!this.value) {
        this.text = '-';
      } else if (this.asValue && this.value % 1 !== 0) {
        this.text = (Math.round(this.value * 1000) / 1000).toFixed(3);
      } else if (this.asMoney) {
        this.text = (Math.round(this.value * 100) / 100).toFixed(2);
      } else if (this.asDate) {
        let date = new Date(this.value);
        this.text = date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        this.text = this.value;
      }
    }
  }
</script>
<style>
</style>
