<template>
  <div class="content">
    <button class="btn btn-light btn-fill btn-sm float-right margin-right-icon" v-on:click="backUp">
      Выполнить бэкап базы
    </button>

    <div class="container-fluid">
      <users-table :objects="objects" :header="'Пользователи'"
                   :downloading="downloading"
                   :header-title="'Список пользователей системой'"
                   @update="getObjects">
      </users-table>
    </div>
  </div>
</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'
import {getList} from "@/repository/repository";

export default {
  name: 'users',
  data () {
    return {
      objects: [],
      key: authHeader(),
      sortBy: {key: 'name', asc: true},
      filter: new Map,
      tableKey: 0,
      downloading: {state: false, history: {state: false}},
    }
  },
  methods: {
    async getObjects() {
      this.objects = [];
      await this.$repo.justGetList('user/', this.objects, this.sortBy, this.downloading);
    },
    sort() {
      sorter(this.objects, this.sortBy);
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    async backUp() {
      await this.$repo.post('user/make_backup')
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    }
  },
  created() {
    this.getObjects();
  }
}
</script>
<style>
</style>
