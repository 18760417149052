<template>
  <div class="full-width" :key="fullViewKey">

    <downloading :key="dk" v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object" class="row row-title" v-on:click="fullView">
      <div class="col-6">
        {{ object.numerate }}
      </div>
      <div class="col-3">
        {{ object.pattern ? object.pattern.name : '-'}}
      </div>
      <div class="col-3">

      </div>
    </div>
    <br/>
    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher">
      <div class="col-3">
        <a class="input-title">
          {{ (parent && parent.type === 'item') || (object && object.item) ? 'Заполнение паспорта качества' : 'Паспорт качества'}}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.pattern ? object.pattern.name : '-'}}
      </div>
      <div v-else class="col-9">
        <button class="btn btn-light btn-fill btn-usm " v-on:click="create">
          {{ (parent && parent.type === 'item') || (object && object.item) ? 'Создать типовое заполнение' : 'Создать'}}
        </button>
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width" >
      <tr :key="fullViewKey">
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="(parent && parent.type === 'item') || (object && object.item) ? 'Шаблон паспорта качества' : 'Паспорт качества'" :select="select"
                                :save-and-cancel="changes"
                                :hide-edit="true"
                                :extra-keys = "changes || !object.portion ? [] : downloadKey"
                                @save="save"
                                @cancel="cancelChanges"
                                @print="print"
                                @regularState="state = start_state">
                Header
              </full-view-header>

              <div class="row">
                <div class="col-3">
                  Шаблон:
                </div>
                <div class="col-9">
                  {{ object.pattern ? object.pattern.name : '-'}}
                  <i class="nc-icon nc-bullet-list-67" style="margin-left: 10px" v-on:click="openSelectModal"></i>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  Описание:
                </div>
                <div class="col-9">
                  {{ object.description }}
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  Данные:
                </div>
                <div class="col-9">
                  <div v-if="object.contentMap" v-for="(value, key) in object.contentMap" :key="key" class="row">
                    <div class="col-4">
                      {{key}}
                    </div>
                    <div class="col-6">
                      <active-input-element :object="object.contentMap" :field="key" @update="changes = true"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <certificate-pattern-table v-bind:objects="patterns" v-bind:header="'Шаблоны'" @select="selectPattern"
                                 :downloading="downloading.pat"
                                 @closeModal="closeModal"
                                 v-bind:header-title="'Выбирете соответствующий шаблон паспорта качества'" :select="true"></certificate-pattern-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import {CERTIFICATE, CERTIFICATE_COUNTER, CERTIFICATE_PATTERN, ITEM} from "@/repository/repository";

export default {
  components: {
    Table
  },
  data () {
    return {
      downloadKey: [{name: 'Загрузить', action: 'print'}],
      key: authHeader(),
      state: 'row',
      object: {},
      id: null,
      fullViewKey: 0,
      selectPool: [],
      asParent: {},
      patterns: [],
      changes: false,
      showSelect: false,
      downloading: {state: false, select: {state: false}, pat: {state: false}},
      dk: 0,
    }
  },
  computed: {
    asDate: function () {
      let date = new Date(this.object.date);
      return date.customFormat('#DD#/#MM#/#YYYY# #hh#:#mm#');
    }
  },
  props: {
    parent: null,
    start: {},
    start_state: '',
    select: false,
    selectBounds: null,
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    async fullView() {
      if (this.object.id > 0) {
        this.object = await this.$repo.getSingle(CERTIFICATE + '/' + this.object.id, CERTIFICATE, this.downloading);
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    setPattern(pattern) {
      this.object.patternLnk = pattern;
    },
    setCounter(counter) {
      this.object.counter = counter;
    },
    async save() {
      let obj = await this.$repo.save(CERTIFICATE + '/', CERTIFICATE, this.object, this.downloading);
      if (obj) {
        this.$emit('updated', obj);
        this.changes = false;
        if (this.start_state === 'edit') {
          this.start_state = 'row';
          this.state = 'row';
        } else {
          this.state = 'full';
        }
      }
    },
    async create() {
      if (this.parent.type === ITEM) {
        await this.openSelectModal();
      } else {
        let obj = await this.$repo.justGetData(CERTIFICATE + '/model?type=' + this.parent.type + "&id=" + this.parent.id, this.downloading);
        this.dk++;
        if (obj && obj.id === 0 && !obj.item && obj.portion) {
            this.object = obj;
            this.state = 'full';
            this.changes = true;
        }
      }
    },
    cancelChanges() {
      if (!this.start || this.start.id < 1) {
        this.state = this.start_state;
      }
      this.changes = false;
      this.object = JSON.parse(JSON.stringify(this.start));;
      this.fullViewKey++;
    },
    async selectPattern(pattern) {
      if (this.parent.type === ITEM && (!this.object || !this.object.item)) {
        let obj = await this.$repo.justGetData(CERTIFICATE + '/model?type=' + ITEM + "&id=" + this.parent.id, this.downloading.pat);
        if (obj && obj.id === 0 && obj.item && !obj.portion) {
          this.object = obj;
          this.state = 'full';
        }
      }
      if (!pattern.contentList) {
        pattern.contentList = [];
      }
      this.object.pattern = pattern;
      this.object.contentMap = {};
      pattern.contentList.forEach(item => this.object.contentMap[item] = '-');
      this.changes = true;
      this.closeModal();
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      await this.$repo.getSelectPoll(CERTIFICATE_PATTERN, null, this.patterns, this.downloading.select);
      await this.callModal();
    },
    async print() {
      let url = CERTIFICATE + '/print/' + this.object.id;
      let fileName = 'Паспорт качества №' + this.object.contentMap['#номер'] + ' от ' + this.object.contentMap['#дата'].replaceAll('.', '-') + '.xls'
      await this.$repo.downloadFile(url, fileName, this.downloading);
    },
  },
  mounted: function () {
    this.state = this.start_state;
    this.object = JSON.parse(JSON.stringify(this.start));
    this.id = this._uid;
  },
  watch: {
    note: function () {
      this.asParent = {type: CERTIFICATE, id: this.object.id}
    },
    start: function () {
      this.object = this.start;
      this.fullViewKey++;
    }
  }
}
</script>
<style>
</style>
