<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id > 0" class="row row-title"
         :key="rowKey"
         v-on:click="select && multiselect ? addToSelection() : fullView()"
         v-on:dblclick="fullView">
      <div class="col-6" :style="multiselect && selected ? 'font-weight: bold' : ''">
        {{ object.name + " ( " + (object.stock ? object.stock.name + " / " + object.stock.contact.name + " )" : "") }}
      </div>
      <div class="col-2" :style="multiselect && selected ? 'font-weight: bold' : ''">
        {{ object.value.toFixed(2)}}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object">
        {{ object.name + " (" + (object.stock ? object.stock.name + " / " + object.stock.contact.name + " )" : "") }}
      </div>
      <div class="col-9" v-if="!object || !object.id">
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object && object.id > 0" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Счет  ' + object.name"
                                :select="select"
                                :historical="true"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="irc.startEdit()" @select="selectObject"
                                @history="irc.controlHistory()">
                Header
              </full-view-header>

              <div v-if="object.stock" class="row item-bcg margin-for-inner-obj">
                <stock :start_object="object.stock" :header="'Владелец'"
                      :start_state="'text'" :default_state="'text'"></stock>
              </div>

              <row :header="'Реквизиты счета:'" :value="object.data">Data</row>

              <row :header="'Остаток по счету:'" :value="object.value" :as-money="true">Sum</row>

              <div v-if="!select_modal">

                <div class="row production-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showTransaction" v-on:click="getTransactions">Транзакции</span>
                    <transactions-table v-if="showTransaction" @element="hideElement('transaction')"
                                        :downloading="downloading.transaction"
                                        :header="'Транзакции'"
                                        :header-title="'Переводы и прочие платежи'"
                                        :objects="transactions" :parent="asParent">Transactions</transactions-table>
                  </div>
                </div>

                <event-table v-if="irc.history.show && irc.history.data"
                             :downloading="downloading.history"
                             :objects="irc.history.data">
                  Events
                </event-table>

              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Наименование:'" class="full-width"></input-row>

            <input-row v-model="object.data" :header="'Реквизиты:'" class="full-width"></input-row>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <accounts-table v-bind:objects="selectPool" v-bind:header="'Платежные счета'"
                      :downloading="downloading.select"
                      @select="selectObjectFromTable"
                      @multiselect="multiSelect"
                      @closeModal="closeModal" :select_modal="true"
                      v-bind:header-title="'Банковские и прочие платежные счета'"
                      :select="select" :multiselect="multiselect"></accounts-table>

    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {ElementInterface} from "@/models/elementInteface";
import {ACCOUNT, ENQUIRE, ITEM, NOTE, PURCHASE, RECEIPT, RESERVE, TRANSACTION} from "@/repository/repository";
import AccountsTable from "@/tables/AccountsTable";

export default {
  name: 'account',
  components: {
    Table
  },
  computed: {
    plan: function () {
      let assembly = this.object.assembly;
      while (!assembly.plan && assembly.parent) {
        assembly = assembly.parent;
      }
      return assembly.plan;
    }
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      transactions: [],
      showTransaction: false,
      id: null,
      selectPool: [],
      asParent: {},
      selectZone: 0,
      viewKey: 0,
      flag: this.$flag,
      irc: null,
      showHistory: false,
      history: null,
      selected: false,
      rowKey: 0,
      showSelect: false,
      downloading: {state: false, select: {state: false}, transaction: {state: false}, history: {state: false}}
    }
  },
  props: {
    entityType: {
      default: ACCOUNT,
      type: String
    },
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    multiselect: false,
    selectBounds: null,
    parent: null,
    create: false,
    select_modal: false,
    header: {
      default: "Счет",
      type: String
    }
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else if (this.create && !this.object) {
        this.createModel();
      } else {
        this.fullView()
      }
    },
    startEdit() {
      this.state = 'edit';
      this.showHistory = false;
    },
    fullView() {
      let id = this.object.id;
      if (id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
        if (this.create) {
          this.object = null;
          this.state = 'text';
        }
      }
    },
    async createModel() {
      let url = this.entityType + '/model?type=' + this.parent.type + '&id=' + this.parent.id;
      this.object = await this.$repo.getSingle(url, this.entityType, this.downloading);
      this.state = 'edit';
    },
    async getTransactions() {
      this.transactions = [];
      this.showTransaction = true;
      await this.$repo.getList(this.entityType + '/' + TRANSACTION + '/' + this.object.id, TRANSACTION, this.transactions, {key: 'date', asc: false}, this.downloading.transaction);
    },
    hideElement(element) {
      if (element === TRANSACTION) {
        this.showTransaction = false;
        this.transactions = [];
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setStock(stock) {
      this.object.stock = stock;
      this.selectZone += 1;
    },
    save() {
      this.$repo.uniSave(this, this.entityType, this.entityType + '/', this.downloading);
    },
    selectObject(object) {
      this.$emit('select', object ? object : this.object);
    },
    async selectObjectFromTable(object) {
      if (this.start_state !== "selector") {
        this.closeModal();
        this.object = object;
        this.selectObject(this.object);
      }
    },
    multiSelect(idList) {
      this.closeModal();
      this.$emit('multiselect', idList);
    },
    setImage(image) {
      this.object.image = image;
    },
    addToSelection() {
      this.selected = !this.selected;
      if (this.selected) {
        this.$emit("multiselect", this.object)
      } else {
        this.$emit("removeselect", this.object)
      }
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      let url = this.entityType + '/';
      if (this.parent) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      await this.$repo.getList(url, this.entityType, this.selectPool, {key: 'date', asc: false}, this.downloading.select)
      this.callModal();
    },
    getBounds() {
      if (this.parent) {
        return [{includes: true, type: this.parent.type, id: this.parent.id}]
      } else {
        return undefined;
      }
    },
    async controlHistory() {
      await this.$repo.getHistory(this, this.entityType, this.object.id, this.downloading);
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.irc = new ElementInterface(this, this.entityType, this.downloading.history);
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(this.entityType, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    /*if (!this.object) {
      let url = this.entityType + '/model';
      if (this.parent) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.object = await this.$repo.justGetData(url, this.downloading);
    }*/
    if (!this.default_state){
      this.default_state = this.start_state !== 'selector' ? this.start_state : 'row';
    }
    if (this.start_state === 'selector') {
      await this.openSelectModal();
    }

  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: this.entityType, id: this.object.id};
      } else {
        this.$emit('delete')
      }
    },
    state: function (value) {
      this.$repo.watchState(this, this.entityType, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, this.entityType, val);
    }
  }
}
</script>
<style>
</style>
