<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.item" class="row row-title" v-on:click="fullView()">
      <div class="col-6">
        {{ object.item.name }}
      </div>
      <div class="col-3">
        <value-warning :value="object.value" :limit="object.item.minimum"></value-warning>
        <span>
          {{ ' / ' + (object.reserved ? object.reserved : '-') + ' / '}}
        </span>
        <span style="color: #0a6715">
          {{ object.stockData ? object.stockData : '-' }}
        </span>
        <span>
          {{ ' ' + object.item.measure}}
        </span>
      </div>
      <div class="col-3">
        {{ object.item.minimum ? (object.item.minimum + ' ' + object.item.measure) : '-' }}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.item.name + ' - ' + object.value + ' ' + object.item.measure}}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Позиция на складах'" :select="select" :hide-edit="true"
                                @regularState="state = default_state ? default_state : start_state">
                Header
              </full-view-header>

              <div class="row item-bcg margin-for-inner-obj">
                <item :start_object="object.item" :header="'Номенклатура'"
                         :start_state="'text'" :default_state="'text'"></item>
              </div>

              <div class="row">
                <div class="col-3">
                  Количество / резерв / склад:
                </div>
                <div class="col-9">
                  <span>
                  {{ object.value + ' / ' + (object.reserved ? object.reserved : '-') + ' / '}}
                  </span>
                  <span style="color: #0a6715">
                    {{ object.stockData ? object.stockData : '-' }}
                  </span>
                   <span>
                     {{ ' ' + object.item.measure}}
                   </span>
                </div>
              </div>

              <div v-if="object.item && object.item.minimum" class="row">
                <div class="col-3">
                  Неснижаемый остаток:
                </div>
                <div class="col-9">
                  {{ object.item.minimum }}
                </div>
              </div>

              <div class="row note-bcg margin-5">
                <div class="col-12 unselectable">
                  <span v-if="!showCell" v-on:click="showCell = true">Складские остатки</span>
                  <stock-cell-table v-else @element="hideElement('cell')" :header="'Остатки по складам'"
                                    :header-title="'Текущие не нулевые остатки по складу, в том числе зарезервированные'"
                                    :objects="object.cells">Stock cells</stock-cell-table>
                </div>
              </div>

            </div>
          </div>
        </td>
      </tr>
    </table>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import SidebarPlugin from "@/components";
import {CELL, ITEM} from "@/repository/repository";

export default {
  components: {
    SidebarPlugin,
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      id: null,
      selectPool: [],
      showCell: false,
      asParent: {},
      viewKey: 0,
      flag: this.$flag,
      downloading: {state: false, select: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
    hidePrice: false
  },
  methods: {
    dispatcher() {
      this.fullView()
    },
    fullView() {
      this.state = 'full'
    },
    hideElement(element) {
      if (element === 'cell') {
        this.showCell = false;
      }
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object ? await this.$repo.get(CELL, [this.start_object.id], this.downloading) : null
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
  },
  watch: {
    object: function () {
      this.asParent = {type: CELL, id: this.object.id}
    },
    state: function (value) {
      this.$repo.watchState(this, CELL, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, CELL, val);
    }
  }
}
</script>
<style>
</style>
