<template>
  <div class="content">
    <div class="container-fluid">
      <sale-man-table :header="'Сводные данные'"
                       :header-title="'Данные по остаткам, размещенным заказам и счетам к отгрузкам по заказам'" :hide-price="true">
        SalesManData
      </sale-man-table>
    </div>
  </div>
</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'
import {warning} from "@/repository/repository";

export default {
  data () {
    return {
    }
  },
  methods: {

  },
  watch: {

  },
  mounted() {
  }
}
</script>
<style>
</style>
