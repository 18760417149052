<template>
  <span :style="color">
    {{ value }}
  </span>
</template>
<script>
  export default {
    props: {
      value: null,
      limit: null
    },
    computed: {
      asDate: function () {
        if (this.value !== undefined) {
          let date = new Date(this.value);
          return date.customFormat('#DD#/#MM#/#YYYY#');
        } else {
          return '-'
        }
      },
      color: function () {
        if (this.value && this.limit) {
          let k = this.value / this.limit;
          if (k < 0.7) {
            return 'color: red;';
          } else if (k < 1.05) {
            return 'color: darkorange;';
          } else {
            return 'color: black;';
          }
        } else {
          return 'color: black;';
        }
      }
    }
  }
</script>
<style>
</style>
