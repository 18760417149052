<template>
  <div class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object" class="row row-title" v-on:click="select ? selectObject() : fullView()">
      <div class="col-12">
        {{ object.login ? `${object.name} (${object.login})` : object.name}}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ hide_login ? object.name : `${object.name} (${object.login})`}}
      </div>
      <div class="col-9" v-else-if="select" v-on:click="selectObject()">
        Выбрать
      </div>
      <div class="col-9" v-else>
        -
      </div>
    </div>

    <span v-if="start_state==='col'" v-on:click="openSelectModal()" class="unselectable">
      {{ object ? object.name : 'выбрать' }}
    </span>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Пользователь'" :select="select"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="state = 'edit'">
                Header
              </full-view-header>

              <row :header="'Имя:'" :value="object.name"> Name </row>
              <row :header="'Логин:'" :value="object.login"> login </row>
              <row :header="'Подпись:'" :value="object.sign"> Sign </row>
              <row :header="'Права:'" :value="object.authority.authority"> Authority </row>
              <row :header="'Статус:'" :value="object.enabled ? 'Имеет доступ в систему' : 'Отключен'"> Authority </row>
              <div class="row">
                <div class="col-md-3">
                  <a class="input-title">
                    Цвет пользователя в системе:
                  </a>
                </div>
                <div class="col-md-9">
                  <div :style="`width:1rem;height:1rem;background: ${object.color ? object.color : 0};`"></div>
                </div>
              </div>

              <selector-table :header="'Департаменты'" :header-title="'Доступные для просмотра и редактирования департаменты'"
                              :downloading="downloading.department"
                              :type="'contact'"
                              :objects-link="'user/contact/' + object.id"
                              :select-link="'contact/departments'"
                              :add-link="'user/' + object.id + '/contact/$id/add'"
                              :drop-link="'user/' + object.id + '/contact/$id/drop'">
                Available Companies
              </selector-table>

              <selector-table :header="'Категории продукции'" :header-title="'Доступные для просмотра и редактирования категории номенклатурыы'"
                              :downloading="downloading.category"
                              :type="'itemCategory'"
                              :objects-link="'user/category/' + object.id"
                              :select-link="'item_category/all'"
                              :add-link="'user/' + object.id + '/category/$id/add'"
                              :drop-link="'user/' + object.id + '/category/$id/drop'">
                Available Companies
              </selector-table>

              <selector-table :header="'Склады'" :header-title="'Доступные для просмотра и редактирования склады и места хранения'"
                              :downloading="downloading.stock"
                              :type="'stock'"
                              :objects-link="'user/stock/' + object.id"
                              :select-link="'stock/departments'"
                              :add-link="'user/' + object.id + '/stock/$id/add'"
                              :drop-link="'user/' + object.id + '/stock/$id/drop'"
                              :name-function="stockNameFunction">
                Available Companies
              </selector-table>

            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Имя:'" class="full-width"></input-row>

            <input-row v-model="object.login" :header="'Логин:'" class="full-width"></input-row>

            <input-row v-model="object.sign" :header="'Подпись:'" :rows=4 class="full-width"></input-row>

            <div class="row">
              <div class="col-3">
                <span class="input-title">
                  Права:
                </span>
              </div>
              <div class="col-9">
                <select v-model="object.authority">
                  <option v-for="(auth, index) in authorities" :key="index" v-bind:value="auth"
                          > {{ auth.authority }}</option>
                </select>
              </div>
            </div>

            <br/>
            <div class="row">
              <div class="col-md-3">
                <a class="input-title">
                  Цвет пользователя в системе:
                </a>
              </div>
              <div class="col-md-1">
                <color-picker :object="object" :field="'color'"></color-picker>
              </div>
            </div>

            <br/>
            <input-row v-model="object.password" :header="'Пароль:'" class="full-width"></input-row>
            <input-row v-model="password" :header="'Пароль повторно:'" class="full-width"></input-row>


            <div class="row">
              <div class="col-3">
                <span class="input-title">
                  Доступ в систему:
                </span>
              </div>
              <div class="col-9">
                <input type="checkbox" id="checkbox" v-model="object.enabled" style="margin-right: 10px">
                <label for="checkbox">{{ object.enabled ? 'Имеет доступ в систему' : 'Отключен'}}</label>
              </div>
            </div>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <users-table v-bind:objects="selectPool" v-bind:header="'Пользователи'" @select="selectObjectFromTable"
                   :downloading="downloading.select"
                   @closeModal="closeModal"
                   v-bind:header-title="'Перечень продукции и материалов'" :select="select"></users-table>
    </div>

  </div>
</template>
<script>

import Table from "@/components";
import {ITEM} from "@/repository/repository";

export default {
  components: {
    Table
  },
  data () {
    return {
      state: 'row',
      object: {},
      id: null,
      selectPool: [],
      asParent: {},
      authorities: [],
      password: '',
      departments: [],
      selectDepartments: [],
      categories: [],
      selectCategories: [],
      stocks: [],
      selectStocks: [],
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}, authority: {state: false},
        department: {state: false}, category: {state: false}, stock: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    header: null,
    select: false,
    col: {},
    hide_login: false
  },
  methods: {
    async fullView() {
      if (this.object.id > 0 && this.select) {
        this.selectObject();
      } else if (this.object.id > 0) {
        this.object = await this.$repo.justGetData('user/' + this.object.id, this.downloading);
        this.object.password = '';
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    stockNameFunction(object) {
      return object.name + ' (' + object.contact.name + ')';
    },
    async save() {
      if (this.object.password.length === 0
        || (this.password.length > 5 && this.password === this.object.password)) {
        let object = await this.$repo.save('user/', null, this.object, this.downloading);
        if (object) {
          this.object = object;
          this.state = 'full';
          this.$emit('updated', this.object);
        }
      }
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      await this.$repo.justGetList('task/users', this.selectPool, null, this.downloading.select);
      this.callModal()
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
  },
  mounted: async function () {
    if (this.start_state !== 'col') {
      this.authorities = [];
      await this.$repo.getList('user/authority', null, this.authorities, null, this.downloading.authority);
    }
    this.state = this.start_state;
    this.object = this.start_object ? this.start_object : null;
    this.id = this._uid;
  }
}
</script>
