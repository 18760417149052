<template>
  <div class="content">
    <div class="container-fluid">
      <company-table :objects="companies" :header="'Департаменты'"
                     :downloading="downloading"
                     :header-title="'Основные подразделения и депортаменты'"
                     :department="true"
                     @update="getCompanies"></company-table>
    </div>
  </div>
</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'

export default {
  name: 'companies',
  data () {
    return {
      companies: [],
      key: authHeader(),
      sortBy: {key: 'name', asc: true},
      filter: new Map,
      tableKey: 0,
      downloading: {state: false, history: {state: false}},

    }
  },
  methods: {
    async getCompanies() {
      this.companies = [];
      await this.$repo.getList('contact/departments', 'company', this.companies, this.sortBy, this.downloading);
    },
    sort() {
      sorter(this.companies, this.sortBy);
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    }
  },
  created() {
    this.getCompanies();
  }
}
</script>
<style>
</style>
