<template>
  <div class="row">
    <div class="col-12">
      <card body-classes="table-full-width" style="margin: 5px">
        <template slot="header">
          <h4 class="card-title unselectable" v-on:click="headerClick()">{{ header }}</h4>
          <p class="card-category unselectable">{{ headerTitle }}</p>
        </template>
        <table class="table table-hover table-striped">
          <thead>
          <slot name="columns">
            <tr>
              <th class="row-title">
                <div class="row" style="width: 100%">
                  <div class="col-4 unselectable">
                        <span v-on:click="sortBy = {key: 'name', asc: !sortBy.asc}">
                          Статус заказа
                        </span>
                  </div>
                </div>
              </th>
            </tr>
          </slot>
          </thead>
          <tbody :key="tableKey">
          <tr v-for="(object, index) in objects" :key="index" v-if="fitFilter(object)">
            <td>
              <order-status v-if="object.id > 0" :start_object="object" :start_state="'row'" :default_state="'row'"
                        :select="select" @select="selectObject"></order-status>
            </td>
          </tr>
          <tr v-if="downloading && downloading.state">
            <downloading :downloading="downloading">
              Downloading
            </downloading>
          </tr>
          </tbody>
        </table>
      </card>
    </div>
  </div>
</template>
<script>
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import {authHeader} from "@/auth";
import Item from "../elements/Item";
import {sorter, fitFilter, updateFilter} from '@/services'

export default {
  components: {
    LTable,
    Card
  },
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'name', asc: true},
      filter: new Map,
      tableKey: 0
    }
  },
  props: {
    objects: [],
    header: null,
    headerTitle: null,
    select: false,
    parent: null,
    downloading: null,
  }
  ,
  methods: {
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    selectObject(object) {
      this.$emit('select', object);
    },
    headerClick() {
      if (this.select) {
        this.$emit('closeModal');
      } else {
        this.$emit('element');
        this.$emit('update');
      }
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    },
    objects: function (){
      this.tableKey += 1;
    }
  }
}
</script>
<style>
</style>
