import downloading from "@/components/Downloading";

export class ElementInterface {

  constructor(that, type, downloading) {
    this.type = type;
    this.that = that;
    this.downloading = downloading;
    this.history = {show: false, data: null};
  }

  startEdit(state) {
    this.history.show = false;
    this.that.state = state ? state : 'edit';
  }

  asDate(val) {
    if (val !== undefined && val !== null && val > 0) {
      let date = new Date(val);
      return date.customFormat('#DD#/#MM#/#YYYY#');
    } else {
      return '-'
    }
  }

  async controlHistory() {
    this.history.show = !this.history.show;
    await this.that.$repo.getHistory(this.type, this.that.object.id, this.history, this.downloading);
  }
}
