<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.item" class="row row-title" v-on:click="fullView()">
      <div class="col-6">
        {{ object.item.name }}
      </div>
      <div class="col-3">
        {{ object.plans ? object.plans.length : '-'}}
      </div>
      <div class="col-3">
        {{ object.quotationElements ? object.quotationElements.length : '-'}}
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="`История по ${object.item.name}`" :select="select" :hide-edit="true"
                                @regularState="state = default_state ? default_state : start_state">
                Header
              </full-view-header>

              <div class="row item-bcg margin-for-inner-obj">
                <item :start_object="object.item" :header="'Номенклатура'"
                         :start_state="'text'" :default_state="'text'"></item>
              </div>

              <div v-if="object.quotationElements && object.quotationElements.length > 0" class="row note-bcg margin-5">
                <div class="col-12 unselectable">
                  <span v-if="!showQE" v-on:click="showQE = true">История предложений</span>
                  <quotation-element-table v-else @element="hideElement('qe')" :header="'История предложений'"
                                           :header-title="`История предложений по позиции ${object.item.name}`"
                                           :objects="object.quotationElements">QuotationElements</quotation-element-table>
                </div>
              </div>

              <div v-if="object.plans && object.plans.length > 0" class="row plan-bcg margin-5">
                <div class="col-12 unselectable">
                  <span v-if="!showPlan" v-on:click="showPlan = true">История заказов</span>
                  <plans-commercial-table v-else @element="hideElement('plan')" :header="'История заказов'"
                                    :header-title="`История заказов по позиции ${object.item.name}`"
                                    :objects="object.plans">Plans</plans-commercial-table>
                </div>
              </div>

            </div>
          </div>
        </td>
      </tr>
    </table>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import SidebarPlugin from "@/components";
import {CELL, ITEM} from "@/repository/repository";

export default {
  components: {
    SidebarPlugin,
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      id: null,
      selectPool: [],
      showPlan: false,
      showQE: false,
      viewKey: 0,
      flag: this.$flag,
      downloading: {state: false, select: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
    hidePrice: false
  },
  methods: {
    dispatcher() {
      this.fullView()
    },
    fullView() {
      this.state = 'full'
    },
    hideElement(element) {
      if (element === 'plan') {
        this.showPlan = false;
      } else if (element === 'qe') {
        this.showQE = false;
      }
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object;
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
  },
  watch: {
  }
}
</script>
<style>
</style>
