import DashboardLayout from '../layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue'

import LoginPage from "@/login/LoginPage";

// Admin pages
import Items from '@/pages/Items.vue'
import Companies from '@/pages/Companies.vue'
import Departments from "@/pages/Departments"
import PlansLeft from "@/pages/PlansLeft";
import Icons from 'src/pages/Icons.vue'
import Orders from "@/pages/Orders";
import ItemCells from "@/pages/ItemCells";
import Employees from "@/pages/Employees";
import Equipments from "@/pages/Equipments";
import WorkTime from "@/elements/WorkTime";
import WorkTimes from "@/pages/WorkTimes";
import Users from "@/pages/Users";
import Enquires from "@/pages/Enquires";
import Purchases from "@/pages/Purchases";
import Invoices from "@/pages/Invoices";
import StockMan from "@/pages/StockMan";
import SalesMan from "@/pages/SalesMan";
import Reports from "@/pages/Reports";
import CertificatesData from "@/pages/CertificatesData";
import CRM from "@/pages/CRM";
import Quotations from "@/pages/Quotations";
import Transactions from "@/pages/Transactions";
import Specifications from "@/pages/Specifications";
const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/ruler/item'
  },
  {
    path: '/login', component: LoginPage
  },
  {
    path: '/ruler',
    component: DashboardLayout,
    redirect: '/ruler/item',
    children: [
      {
        path: 'item',
        name: 'Item',
        component: Items
      },
      {
        path: 'stockman',
        name: 'Stockman',
        component: StockMan
      },
      {
        path: 'salesman',
        name: 'SalesMan',
        component: SalesMan
      },
      {
        path: 'quotations',
        name: 'Quotations',
        component: Quotations
      },
      {
        path: 'company',
        name: 'Company',
        component: Companies
      },
      {
        path: 'department',
        name: 'Department',
        component: Departments
      },
      {
        path: 'plans_left',
        name: 'PlansLeft',
        component: PlansLeft
      },
      {
        path: 'invoices',
        name: 'Invoices',
        component: Invoices
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders
      },
      {
        path: 'item_cells',
        name: 'ItemCells',
        component: ItemCells
      },
      {
        path: 'enquiries',
        name: 'Enquiries',
        component: Enquires
      },
      {
        path: 'purchases',
        name: 'Purchases',
        component: Purchases
      },
      {
        path: 'employees',
        name: 'Employees',
        component: Employees
      },
      {
        path: 'equipments',
        name: 'Equipments',
        component: Equipments
      },
      {
        path: 'work_times',
        name: 'WorkTimes',
        component: WorkTimes
      },
      {
        path: 'icons',
        name: 'Icons',
        component: Icons
      },
      {
        path: 'users',
        name: 'Users',
        component: Users
      },
      {
        path: 'reports',
        name: 'Reports',
        component: Reports
      },
      {
        path: 'certificates_data',
        name: 'CertificatesData',
        component: CertificatesData
      },
      {
        path: 'crm',
        name: 'CRM',
        component: CRM
      },
      {
        path: 'transactions',
        name: 'Transactions',
        component: Transactions
      },
      {
        path: 'specifications',
        name: 'Specifications',
        component: Specifications
      },
      // otherwise redirect to home
      { path: '*', redirect: '/' }
    ]
  },
  { path: '*', component: NotFound }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/


export default routes
