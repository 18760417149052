<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card body-classes="table-full-width" style="margin: 5px">
          <base-input v-model="textSearch" style="margin-left: 15px; width: 50%;"></base-input>
          <template slot="header">
          <span v-if="!select && !add_mode" class="float-right unselectable" v-on:click="addNew">
            new
            <i class="nc-icon nc-simple-add"></i>
          </span>
            <span v-if="add_mode" class="float-right unselectable" v-on:click="openSelectModal">
            Добавить
            <i class="nc-icon nc-simple-add"></i>
          </span>

            <h4 class="card-title unselectable" v-on:click="headerClick()">{{ header }}</h4>
            <p class="card-category unselectable">{{ headerTitle }}</p>
          </template>
          <table class="table table-hover table-striped">
            <thead>
            <slot name="columns">
              <tr>
                <th class="row-title">
                  <div class="row" style="width: 100%">
                    <div class="col-12 unselectable">
                        <span v-on:click="sortBy = {key: 'name', asc: !sortBy.asc}">
                          Наименование
                        </span>
                    </div>
                  </div>
                </th>
              </tr>
            </slot>
            </thead>
            <tbody :key="tableKey">
            <tr v-if="newObj">
              <td>
                <company :start_object="newObj" :start_state="'edit'" :default_state="'row'"
                         :department="department"
                         @close="cancelNew" :select="select" @updated="addToList"></company>
              </td>
            </tr>
            <tr v-for="(object, index) in objects" :key="index" v-if="fitFilter(object)">
              <td>
                <company v-if="add_mode && object" :start_object="object" :start_state="'row_add'" :default_state="'row_add'"
                         :department="department"
                         @updated="addToList" @remove="drop(object, index)"></company>

                <company v-else-if="object.id > 0" :start_object="object" :start_state="'row'" :default_state="'row'"
                         :department="department"
                         :select="select" @select="selectItem" @updated="addToList"></company>
              </td>
            </tr>
            <tr v-if="downloading && downloading.state">
              <downloading :downloading="downloading">
                Downloading
              </downloading>
            </tr>
            </tbody>
          </table>
        </card>
      </div>

    </div>

    <div class="modal" :id="id">
      <div v-if="selectPool" class="modal-content-50">
        <company-table :objects="selectPool" :header="'Компании'" @select="selectItemFromTable"
                       :select="true" @closeModal="closeModal"
                       :department="department"
                       :header-title="'Список котрагентов и подразделений'"></company-table>
      </div>
    </div>

  </div>


</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter, textContainsText, addToList} from '@/services'

export default {
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'name', asc: true},
      filter: new Map,
      tableKey: 0,
      id: null,
      textSearch: '',
      newObj: null
    }
  },
  props: {
    objects: [],
    header: null,
    headerTitle: null,
    select: false,
    parent: null,
    add_mode: false,
    selectPool: null,
    department: false,
    downloading: null,
  }
  ,
  methods: {
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter) && textContainsText(object.name, this.textSearch);
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    async addNew() {
      if (!this.newObj) {
        this.newObj = await this.$repo.justGetData('contact/model', this.downloading);
      }
    },
    addToList(object) {
      addToList(object, this);
    },
    cancelNew() {
      this.newObj = null;
    },
    selectItem(item) {
      this.$emit('select', item);
    },
    headerClick() {
      if (this.select) {
        this.objects = []
        this.$emit('closeModal');
      } else {
        this.$emit('update');
      }
    },
    async drop(object, index) {
      if (this.parent) {
        let url = this.parent.type + '/contact/' + this.parent.id + '/' + object.id;
        if (await this.$repo.drop(url, this.downloading)) {
          this.objects.splice(index, 1);
        }
      } else {
        this.objects.splice(index, 1);
      }
    },
    closeModal() {
      let modal = document.getElementById(this.id);
      modal.style.display = "none";
    },
    async openSelectModal() {
      this.selectPool = [];
      if (!this.selectBounds) {
        await this.$repo.justGetList('contact/all', this.selectPool, this.sortBy, this.downloading);
      } else {
        this.selectPool = this.$repo.save('contact/bounds/', null, this.selectBounds, this.downloading);
        sorter(this.selectPool, this.sortBy);
      }
      this.callModal();
    },
    selectItemFromTable(object) {
      this.$emit("add", object);
      this.closeModal();
    },
    callModal() {
      let modal = document.getElementById(this.id);
      modal.style.display = "block";
      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      }
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    },
    objects: function (){
      this.tableKey += 1;
    }
  },
  mounted() {
    this.id = this._uid;
  }
}
</script>
<style>
</style>
