<template>
  <div class="padding-header">
    <i class="nc-icon nc-simple-remove input-title" v-on:click="fullView"></i>
  </div>
</template>
<script>
  export default {
    props: {
      header: null
    },
    data () {
      return {
        text: null
      }
    },
    methods: {
      fullView () {
        return this.$emit('fullView');
      },
      save () {
        return this.$emit('save');
      }
    }
  }
</script>
<style>
</style>
