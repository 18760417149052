<template>
  <div class="padding-header">
    <span v-on:click="updateState" class="unselectable">
      <i v-if="!hideUpdateState" class="nc-icon nc-stre-up"></i>
      {{ header ? header : '' }}
    </span>

    <button v-if="historical"
            class="btn btn-light btn-fill btn-usm float-right margin-right-icon"
            v-on:click="returnAction(historyKey.action)">
      {{ historyKey.name }}
    </button>

    <button v-if="!select && !hideAction && !hideEdit && !saveAndCancel"
            class="btn btn-light btn-fill btn-usm float-right margin-right-icon" v-on:click="editState">
      Редактировать
    </button>
    <button v-else-if="!hideAction && select"
            class="btn btn-light btn-fill btn-usm float-right margin-right-icon" v-on:click="selected">
      Выбрать
    </button>

    <span v-else-if="saveAndCancel">
      <button class="btn btn-light btn-fill btn-usm float-right margin-right-icon" v-on:click="returnAction('save')">
        Сохранить
      </button>
      <button class="btn btn-light btn-fill btn-usm float-right margin-right-icon" v-on:click="returnAction('cancel')">
        Отмена
      </button>
    </span>

    <button v-if="!hideAction && extraKeys"
            v-for="(key, index) in extraKeys" :key="index"
            class="btn btn-light btn-fill btn-usm float-right margin-right-icon"
            v-on:click="returnAction(key.action)">
      {{ key.name }}
    </button>

  </div>
</template>
<script>
  export default {
    props: {
      header: null,
      select: false,
      hideAction: false,
      hideEdit: false,
      saveAndCancel: false,
      extraKeys: null,
      hideUpdateState: false,
      historical: false,
      multiselect: false
    },
    data () {
      return {
        text: null,
        historyKey: {name: 'История', action: 'history'}
      }
    },
    methods: {
      updateState () {
        return this.$emit('regularState');
      },
      editState () {
        return this.$emit('editState');
      },
      selected () {
        return this.$emit('select');
      },
      returnAction(action) {
        this.$emit(action)
      }
    }
  }
</script>
<style>
</style>
