import {vue} from "@/main";
import {authHeader} from "@/auth";
import {API_URL} from "@/repository/repository";

export const userService = {
    login,
    logout,
    authHeader,
    userHasAccess
};

let socket;

function login(login, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ login, password })
    };

    return fetch(`${API_URL}auth/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.accessToken) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
              console.log(user)
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('expired', user.expired);
                vue.$http.defaults.headers = authHeader();
            }
            return user;
        });
}

function userHasAccess(level) {
  let role = JSON.parse(localStorage.getItem('user')).roles[0];
  return (
    (level === 'ADMIN' && role === 'ROLE_ADMIN') ||
    role === 'ROLE_ROOT'
  )
}

export default function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('expired');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          console.log("Bad ")
          let exp = localStorage.getItem('expired') < new Date().getTime();
            if (response.status === 401 || exp) {
                // auto logout if 401 response returned from api
                logout();
                location.reload();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
