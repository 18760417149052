<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id > 0" class="row row-title" v-on:click="fullView()">
      <div class="col-12">
        {{ object.name }}
        <i v-if="select" class="nc-icon nc-check-2 float-right" @click="selectObject()"></i>
      </div>
    </div>

    <div v-if="state === 'row_add' && object && object.id > 0" class="row row-title" v-on:click="fullView()">
      <div class="col-10">
        {{ object.name }}
        <i v-if="select" class="nc-icon nc-check-2 float-right" @click="selectObject()"></i>
      </div>
      <div class="col-2">
        <span class="unselectable" v-on:click="remove">
          УДАЛИТЬ
        </span>
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.name }}
      </div>
      <div v-else class="col-9">
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Компания'" :select="select"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="state = 'edit'" @select="selectObject">
                Header
              </full-view-header>

              <row :header="'Наименование:'" :value="object.name"></row>
              <row :header="'Юр. наименование:'" :value="object.legalShortName"></row>
              <row :header="'Описание:'" :value="object.description"></row>
              <row v-if="object.hiddenStock" :header="'Ставка НДС, %:'" :value="object.hiddenStock.tax" :as-value="true"></row>
              <row :header="'Контакты:'" :value="(object.email ? object.email : '') + ' ' + (object.phone ? object.phone : '')"></row>

              <div v-if="!select">
                <div class="row stock-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showStock" v-on:click="getStock">Склады и подразделения</span>
                    <stock-table v-if="showStock" @element="hideElement('stock')" :header="'Склады и подразделения'"
                                 :downloading="downloading.stock"
                                 :header-title="'Склады и подразделения относящиеся к компании'"
                                 :objects="stocks" :parent="asParent">Stocks</stock-table>
                  </div>
                </div>
                <div class="row note-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showDocument" v-on:click="getDocument">Исходящие документы</span>
                    <document-table v-if="showDocument" @element="hideElement('document')" :header="'Исходящие документы'"
                                    :downloading="downloading.document"
                                    :header-title="'Исходящие документы по выполненным отгрузкам'"
                                    :objects="documents" :parent="asParent">Documents out</document-table>
                  </div>
                </div>
                <div class="row content-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showOrder" v-on:click="getOrder">Заказы</span>
                    <order-table v-if="showOrder" @element="hideElement('order')" :header="'Заказы'"
                                 :downloading="downloading.order"
                                 :header-title="'Заказы размещенные в компании'"
                                 :objects="orders" :parent="asParent">Orders</order-table>
                  </div>
                </div>

                <div class="row light-grey-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showCategories" v-on:click="getCategories">Поставщик в товарных категориях</span>
                    <item-category-table v-if="showCategories"
                                         :downloading="downloading.category"
                                         @add="addCategory" @remove="dropCategory"
                                         @element="hideElement('category')" :header="'Категории'"
                                         :header-title="'Поставщик в товарных категориях'"
                                         :objects="categories" :parent="asParent">Categories</item-category-table>
                  </div>
                </div>

                <div class="row sign-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showSigns" v-on:click="getSigns">Подписи в документах</span>
                    <sign-table v-if="showSigns"
                                :downloading="downloading.sign"
                                @element="hideElement('sign')" :header="'Подписи'"
                                :header-title="'Подписи используемые в документах'"
                                :objects="signs" :parent="asParent">Signs</sign-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Наименование:'" class="full-width"></input-row>

            <input-row v-model="object.description" :header="'Описание:'" class="full-width"></input-row>

            <input-row v-model="object.legalShortName" :header="'Сокращенное юр. наименование:'" class="full-width"></input-row>

            <input-row v-model="object.legalFullName" :header="'Полное юр. наименование:'" class="full-width"></input-row>

            <input-row v-if="object.hiddenStock" v-model="object.hiddenStock.tax" :header="'Ставка НДС, %:'" :number="true" class="full-width"></input-row>

            <div class="row">
              <div class="col-md-6">
                <input-row v-model="object.email" :header="'e-mail:'" class="full-width"></input-row>
              </div>
              <div class="col-md-6">
                <input-row v-model="object.phone" :header="'тел:'" class="full-width"></input-row>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <input-row v-model="object.inn" :header="'ИНН:'" class="full-width"></input-row>
              </div>
              <div class="col-md-4">
                <input-row v-model="object.kpp" :header="'КПП:'" class="full-width"></input-row>
              </div>
              <div class="col-md-4">
                <input-row v-model="object.ogrn" :header="'ОГРН:'" class="full-width"></input-row>
              </div>
            </div>

            <input-row v-model="object.address" :header="'Юридический адрес:'" class="full-width"></input-row>

            <input-row v-model="object.postAddress" :header="'Почтовый адрес:'" class="full-width"></input-row>

            <input-row v-model="object.bank" :header="'Банк:'" class="full-width"></input-row>

            <div class="row">
              <div class="col-md-4">
                <input-row v-model="object.bik" :header="'БИК:'" class="full-width"></input-row>
              </div>
              <div class="col-md-4">
                <input-row v-model="object.account" :header="'р/с:'" class="full-width"></input-row>
              </div>
              <div class="col-md-4">
                <input-row v-model="object.koraccount" :header="'к/с:'" class="full-width"></input-row>
              </div>
            </div>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <company-table :objects="selectPool" :header="'Компании'" @select="selectItemFromTable"
                     @closeModal="closeModal"
                     :downloading="downloading.select"
                     :header-title="'Список котрагентов и подразделений'" :select="select"></company-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import ItemCategorySelect from "../selectable/ItemCategorySelect";
import ContentsTable from "@/tables/ContentsTable";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import EditBottom from "@/components/Inputs/EditBottom";
import {COMPANY, CATEGORY, ORDER, DOCUMENT, STOCK, SIGN} from "@/repository/repository";

export default {
  components: {
    EditBottom,
    CompaniesTable,
    Table,
    ContentsTable,
    ItemCategorySelect
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      orders: [],
      showOrder: false,
      stocks: [],
      showStock: false,
      documents: [],
      showDocument: false,
      categories: [],
      showCategories: false,
      signs: [],
      showSigns: false,
      id: null,
      selectPool: [],
      asParent: {},
      viewKey: 0,
      flag: this.$flag,
      showSelect: false,
      downloading: {state: false, select: {state: false}, stock: {state: false}, order: {state: false}, document: {state: false},
        category: {state: false}, sign: {state: false}, history: {state: false}}
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    header: null,
    parent: null,
    department: false
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    async fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    async getCategories() {
      this.categories = [];
      await this.$repo.getList('contact/categories/' + this.object.id, 'category', this.categories, null, this.downloading.category);
      this.showCategories = true;
    },
    async addCategory(category) {
      let url = 'contact/category/' + this.object.id + '/' + category.id;
      category = await this.$repo.post(url,null, this.downloading);
      this.categories.unshift(category);
    },
    async dropCategory(response) {
      let url = 'contact/category/' + this.object.id + '/' + response.object.id;
      if (await this.$repo.drop(url, this.downloading)) {
        this.categories.splice(response.index, 1)
      }
    },
    async getOrder() {
      this.orders = [];
      this.showOrder = true;
      await this.$repo.getList('contact/order/' + this.object.id, 'order', this.orders, null, this.downloading.order);
    },
    async getStock() {
      this.stocks = [];
      this.showStock = true;
      await this.$repo.getList('contact/stock/' + this.object.id, 'stock', this.stocks, null, this.downloading.stock);
    },
    async getDocument() {
      this.documents = [];
      this.showDocument = true;
      await this.$repo.getList('contact/document/' + this.object.id, 'document', this.documents, null, this.downloading.document);
    },
    async getSigns() {
      this.signs = [];
      this.showSigns = true;
      await this.$repo.getList('contact/sign/' + this.object.id, 'sign', this.signs, null, this.downloading.sign);
    },
    hideElement(element) {
      if (element === ORDER) {
        this.showOrder = false;
        this.orders = [];
      } else if (element === STOCK) {
        this.showStock = false;
        this.stocks = [];
      } else if (element === DOCUMENT) {
        this.showDocument = false;
        this.documents = [];
      } else if (element === CATEGORY) {
        this.showCategories = false;
        this.categories = [];
      } else if (element === SIGN) {
        this.showSigns = false;
        this.signs = [];
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setCategory(category) {
      this.object.category = category;
    },
    save() {
      let url = this.department ? 'contact/department' : 'contact/'
      this.$repo.uniSave(this, COMPANY, url, this.downloading);
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectItemFromTable(item) {
      this.closeModal();
      this.object = item;
      this.selectObject();
    },
    setImage(image) {
      this.object.image = image;
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      this.callModal();
      if (this.parent) {
        let url = 'contact/top/' + this.parent.type + '/' + this.parent.id;
        await this.$repo.getList(url, this.parent.type, this.selectPool, this.downloading.select);
      } else if (!this.selectBounds) {
        await this.$repo.justGetList('contact/all', this.selectPool, null, this.downloading.select);
      } else {
        this.selectPool = await this.$repo.save('contact/bounds/',this.selectBounds, null, this.downloading.select);
      }
    },
    giveData(type) {
      if (type === 'lnk') {
        this.getStock(false);
      }
    },
    remove() {
      this.$emit('remove');
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(COMPANY, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: COMPANY, id: this.object.id}
      }
    },
    state: function (value) {
      this.$repo.watchState(this, COMPANY, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, COMPANY, val);
    }
  }
}
</script>
<style>
</style>
s
