<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id" class="row row-title" v-on:click="fullView()">
      <div class="col-6">
        {{ object.item.name }}
      </div>
      <div class="col-2">
        {{ asValue(object.projectValue) + '/' + asValue(object.value) + ' ' + object.item.measure}}
      </div>
      <div class="col-2">
        {{ asDate(object.projectDate) }}
      </div>
      <div class="col-2">
        {{ asDate(object.date) }}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.item.name + ' - ' + asValue(object.value) + ', ' + object.item.measure + ' ' + asDate(object.projectDate) + '/' + asDate(object.date)}}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object && object.id" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Выпуск продукции'" :select="select"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="state = 'edit'" @select="selectObject">
                Header
              </full-view-header>

              <row :header="'Комментарий, документ о выпуске'" :value="object.name"> Name </row>

              <div class="row stock-bcg margin-for-inner-obj">
                <stock :start_object="object.stock" :header="'Производитель'"
                            :start_state="'text'" :default_state="'text'"></stock>
              </div>

              <div class="row order-bcg margin-for-inner-obj">
                <order :start_object="object.order" :header="'Заказ'"
                       :start_state="'text'" :default_state="'text'"></order>
              </div>

              <div class="row item-bcg margin-for-inner-obj">
                <item :start_object="object.item" :header="'Продукция'"
                       :start_state="'text'" :default_state="'text'"></item>
              </div>

              <row :header="'Планираемый объем, ' + object.item.measure" :value="object.projectValue" :as-value="true"> Project value </row>

              <row :header="'Выпуск, ' + object.item.measure" :value="object.value" :as-value="true"> Value </row>

              <div class="row">
                <div class="col-md-6">
                  <row :header="'Плановая дата'" :value="asDate(object.projectDate)"> Date </row>
                </div>
                <div class="col-md-6">
                  <row :header="'Дата'" :value="asDate(object.date)"> Date </row>
                </div>
              </div>

              <div v-if="!select">

                <div class="row reserve-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showRes" v-on:click="getRes(true)">Ресурсы</span>
                    <res-table v-if="showRes" @element="hideElement('res')" :header="'Ресурсы'"
                               :downloading="downloading.res"
                               :header-title="'Использованные ресурсы, материалы и комплектующие '"
                               :objects="res" :parent="asParent"> Resources </res-table>
                  </div>
                </div>

                <div class="row work-time-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showWorkTime" v-on:click="getWorkTime">Рабочеее время</span>
                    <work-times-table v-if="showWorkTime" @element="hideElement('worktime')" :header="'Рабочее время'"
                                      :downloading="downloading.workTime"
                                      :header-title="'Использовние рабочего времени в часах'"
                                      :objects="workTime" :parent="asParent">WorkTime</work-times-table>
                  </div>
                </div>

                <div class="row note-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showNote" v-on:click="getNote(true)">Заметки</span>
                    <note-table v-if="showNote" @element="hideElement('note')" :header="'Заметки'"
                                :downloading="downloading.note"
                                :header-title="'Заметки и примечания'" :show-all="true"
                                :notes="notes" :parent="asParent"> Notes </note-table>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Комментарий, документ о выпуске:'" class="full-width"></input-row>

            <div :key="selectZone">
              <div v-if="object.id === 0 && parent.type !== 'stock'" class="row margin-for-inner-obj">
                <stock :start_object="object.stock" :start_state="'text'" :default_state="'text'"
                       :select="true" @select="setStock" :header="'Производитель:'" :selectBounds="getBounds('stock')"> Stock </stock>
              </div>

              <div v-if="object.id === 0 && parent.type !== 'order'" class="row margin-for-inner-obj">
                <order :start_object="object.order" :start_state="'text'" :default_state="'text'"
                       :select="true" @select="setOrder" :header="'Заказ:'" :selectBounds="getBounds('order')"> Order </order>
              </div>

              <div v-if="object.id === 0" class="row margin-for-inner-obj">
                <item :start_object="object.item" :start_state="'text'" :default_state="'text'"
                      :select="true" @select="setItem" :header="'Продукция:'" :selectBounds="getBounds('item')"> Item </item>
              </div>
            </div>

            <input-row v-model="object.projectValue" :number="true" :header="'План выпуска:'" class="full-width"></input-row>

            <input-row v-model="object.value" :number="true" :header="'Количество:'" class="full-width"></input-row>

            <input-row v-model="object.projectDate" :header="'Плановая дата:'" :date="true" class="full-width" ></input-row>

            <input-row v-model="object.date" :header="'Дата:'" :date="true" class="full-width" ></input-row>

            <edit-bottom @fullView="fullView" @save="save" @big_save="bigSave"
                         :extraKeys="[{name: 'Сохранить с ресурсами', action: 'big_save'}]">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <productions-table v-if="selectPool && selectPool.length > 0" v-bind:objects="selectPool" v-bind:header="'Производство'"
                         :downloading="downloading.select"
                         @select="selectObjectFromTable" @closeModal="closeModal"
                         v-bind:header-title="'Выпуск продукции и оказание услуг'" :select="select"></productions-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import {asDate, parentToParams} from "@/services";
import {COMPANY, ITEM, NOTE, ORDER, ORDER_STATUS, PRODUCTION, RES, STOCK, WORK_TIME} from "@/repository/repository";

export default {
  components: {
    Table
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      id: null,
      selectPool: [],
      asParent: {},
      moneyValue: 0,
      workTime: [],
      showWorkTime: false,
      notes: [],
      showNote: false,
      res: [],
      showRes: false,
      selectZone: 0,
      viewKey: 0,
      flag: this.$flag,
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}, workTime: {state: false},
        note: {state: false}, res: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
    selectUrl: null
  },
  methods: {
    asDate: function (val) {
      return asDate(val ? val : this.object.date);
    },
    asValue(data) {
      return data % 1 === 0 ? data : (Math.round(data * 1000) / 1000).toFixed(3);
    },
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    getBounds(forWhom) {
      if (forWhom === ORDER) {
        if (this.object.stock && this.object.stock.id) {
          return [{includes: true, type: COMPANY, id: this.object.stock.contact.id}, {includes: true, type: ORDER_STATUS, id: 2}];
        } else {
          return undefined;
        }
      } else if (forWhom === ITEM) {
        if (this.object.order && this.object.order.id) {
          return [{includes: true, type: ORDER, id: this.object.order.id}, {includes: true, type: PRODUCTION, id: 0}];
        } else {
          return undefined;
        }
      } else if (forWhom === STOCK) {
        if (this.object.order && this.object.order.id) {
          return [{includes: true, type: COMPANY, id: this.object.order.contact.id}];
        } else {
          return undefined;
        }
      }
      return undefined;
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    async getNote(show) {
      this.notes =[];
      this.showNote = true;
      await this.$repo.getList('production/note/' + this.object.id, NOTE, this.notes, 'date', this.downloading.note);
    },
    async getRes(show) {
      this.res = [];
      this.showRes = true;
      await this.$repo.getList('production/res/' + this.object.id, RES, this.res, 'cell.item.name', this.downloading.res);
    },
    async getWorkTime(show) {
      this.workTime =[];
      this.showWorkTime = true;
      await this.$repo.getList('worktime/production/' + this.object.id, WORK_TIME, this.workTime, {key: 'date', asc: false}, this.downloading.workTime);
    },
    hideElement(element) {
      if (element === NOTE) {
        this.showNote = false;
        this.notes = [];
      } else if (element === RES) {
        this.showRes = false;
        this.res = [];
      } else if (element === WORK_TIME) {
        this.showWorkTime = false;
        this.workTime = [];
      }
    },
    setStock(stock) {
      if (this.parent && this.parent.type !== STOCK) {
        this.object.stock = stock;
        if (this.object.order && this.object.order.contact.id !== stock.contact.id) {
          this.object.order = undefined;
        }
      }
      this.selectZone += 1;
    },
    setOrder(order) {
      if (this.parent && this.parent.type !== ORDER) {
        this.object.order = order;
      }
      this.selectZone += 1;
    },
    setItem(item) {
      this.object.item = item;
      this.selectZone += 1;
    },
    async bigSave() {
      await this.save(true);
    },
    save(big) {
      if (this.object.item &&
        (!this.object.order || (this.object.order.contact.id === this.object.stock.contact.id))) {
        let url = big ? 'production/resources' : 'production/';
        this.$repo.uniSave(this, PRODUCTION, url, this.downloading);
      }
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      let url = this.selectUrl;
      if (!url) {
        url = 'production/' + parentToParams(this.parent);
      }
      await this.$repo.getList(url, PRODUCTION, this.selectPool, {key: 'date', asc: false}, this.downloading.select);
      this.callModal();
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(PRODUCTION, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
    if (!this.object && !this.select) {
      this.object = await this.$repo.justGetData('production/model', this.downloading);
    }
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: PRODUCTION, id: this.object.id};
        if (this.state === 'edit') {
          this.moneyValue = this.object.price * this.object.value;
        }
      }
    },
    state: function (value) {
      this.$repo.watchState(this, PRODUCTION, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, PRODUCTION, val);
    }
  }
}
</script>
<style>
</style>
