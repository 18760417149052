<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id > 0" class="row row-title"
         :key="rowKey"
         v-on:click="select ? addToSelection() : fullView()"
         v-on:dblclick="fullView">
      <div class="col-2" :style="selected ? 'font-weight: bold' : ''">
        {{ toDate(object.date) }}
      </div>
      <div class="col-3" :style="selected ? 'font-weight: bold' : ''">
        {{ object.sender.name + " (" + object.sender.stock.name + "/" + object.sender.stock.contact.name + "/)" }}
      </div>
      <div class="col-3" :style="selected ? 'font-weight: bold' : ''">
        {{ object.receiver.name + " (" + object.receiver.stock.name + "/" + object.receiver.stock.contact.name + "/)" }}
      </div>
      <div class="col-2" :style="selected ? 'font-weight: bold' : ''">
        {{ object.name }}
      </div>
      <div class="col-2" :style="selected ? 'font-weight: bold' : ''">
        {{ object.value.toFixed(2)}}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher">
      <div class="col-3">
        <a class="input-title">
          Платеж
        </a>
      </div>
      <div class="col-9" v-if="object && object.id ">
        {{ object.name + " на сумму " + object.value.toFixed(2) + " от " + toDate(object.date)}}
      </div>
      <div class="col-9" v-if="!object">
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object && object.id > 0" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header='object.name + " на сумму " + object.value.toFixed(2) + " от " + toDate(object.date)'
                                :select="select"
                                :historical="true"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="irc.startEdit()" @select="selectObject"
                                @history="irc.controlHistory()">
                Header
              </full-view-header>

              <row :header="'Назначение платежа:'" :value="object.name">Name</row>

              <row :header="'Дата:'" :value="object.date" :as-date="true">Date</row>

              <row :header="'Сумма:'" :value="object.value" :as-money="true">Sum</row>

              <div class="row item-bcg margin-for-inner-obj">
                <account :start_object="object.sender" :header="'Отправитель'"
                      :start_state="'text'" :default_state="'text'"></account>
              </div>

              <div class="row item-bcg margin-for-inner-obj">
                <account :start_object="object.receiver" :header="'Получатель'"
                         :start_state="'text'" :default_state="'text'"></account>
              </div>

              <div v-if="!select_modal">

                <div class="row lnk-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showLnk" v-on:click="getLnk">Файлы</span>
                    <lnk-table v-if="showLnk" @element="hideElement" :header="'Файлы'"
                               :downloading="downloading.lnk"
                               :header-title="'Связные файлы и изображения'"
                               :objects="lnks" :parent="asParent">Lnk</lnk-table>
                  </div>
                </div>

                <event-table v-if="irc.history.show && irc.history.data"
                             :objects="irc.history.data" :downloading="downloading.history"></event-table>

              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Назначение платежа:'" class="full-width"></input-row>

            <div :key="selectZone">
              <div class="row margin-for-inner-obj">
                <account :start_object="object.sender" :start_state="'text'" :default_state="'text'"
                         :multiselect="false"
                         :select="true" @select="setSender" :header="'Отправитель:'">Отправитель</account>
              </div>
              <div class="row margin-for-inner-obj">
                <account :start_object="object.receiver" :start_state="'text'" :default_state="'text'"
                         :multiselect="false"
                         :select="true" @select="setReceiver" :header="'Получатель:'">Получатель</account>
              </div>
            </div>

            <input-row v-model="object.value" :header="'Сумма:'" :number="true" class="full-width"></input-row>

            <input-row v-model="object.date" :header="'Дата:'" :date="true" class="full-width"></input-row>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
        <transactions-table v-bind:objects="selectPool" v-bind:header="'Платежи'"
                            :downloading="downloading.select"
                            :entity-type="entityType"
                            @select="selectObjectFromTable"
                            @multiselect="multiSelect"
                            @closeModal="closeModal" :select_modal="true"
                            v-bind:header-title="'Совершенные транзакции и платежи'"
                            :select="select" :multiselect="multiselect"></transactions-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {ElementInterface} from "@/models/elementInteface";
import {ACCOUNT, ENQUIRE, ITEM, LNK, NOTE, PURCHASE, RECEIPT, RESERVE, TRANSACTION} from "@/repository/repository";
import {asDate} from "@/services";
import Account from "@/elements/Account";
import TransactionsTable from "@/tables/TransactionsTable";

export default {
  components: {
    Table
  },
  computed: {
    plan: function () {
      let assembly = this.object.assembly;
      while (!assembly.plan && assembly.parent) {
        assembly = assembly.parent;
      }
      return assembly.plan;
    }
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      lnks: [],
      showLnk: false,
      id: null,
      selectPool: [],
      asParent: {},
      selectZone: 0,
      viewKey: 0,
      flag: this.$flag,
      irc: null,
      showHistory: false,
      history: null,
      selected: false,
      rowKey: 0,
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}, lnk: {state: false}},
    }
  },
  props: {
    entityType: {
      default: TRANSACTION,
      type: String
    },
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    multiselect: false,
    selectBounds: null,
    parent: null,
    create: false,
    select_modal: false
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else if (this.create && !this.object) {
        this.createModel();
      } else {
        this.fullView()
      }
    },
    toDate(date) {
      return asDate(date);
    },
    startEdit() {
      this.state = 'edit';
      this.showHistory = false;
    },
    fullView() {
      let id = this.object.id;
      if (id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
        if (this.create) {
          this.object = null;
          this.state = 'text';
        }
      }
    },
    async createModel() {
      let url = this.entityType + '/model';
      this.object = await this.$repo.getSingle(url, this.entityType, this.downloading);
      this.state = 'edit';
    },
    async getLnk() {
      this.lnks = [];
      this.showLnk = true;
      await this.$repo.getList(this.entityType + '/lnk/' + this.object.id, LNK, this.lnks, null, this.downloading.lnk);
    },
    hideElement(element) {
      if (element === LNK) {
        this.showLnk = false;
        this.lnks = [];
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setSender(account) {
      this.object.sender = account;
      this.selectZone += 1;
    },
    setReceiver(account) {
      this.object.receiver = account;
      this.selectZone += 1;
    },
    save() {
      this.$repo.uniSave(this, this.entityType, this.entityType + '/', this.downloading);
    },
    selectObject(object) {
      this.$emit('select', object ? object : this.object);
    },
    async selectObjectFromTable(object) {
      if (this.start_state !== "selector") {
        this.closeModal();
        this.object = object;
        this.selectObject(this.object);
      }
    },
    multiSelect(idList) {
      this.closeModal();
      this.$emit('multiselect', idList);
    },
    setImage(image) {
      this.object.image = image;
    },
    addToSelection() {
      this.selected = !this.selected;
      if (this.selected) {
        this.$emit("multiselect", this.object)
      } else {
        this.$emit("removeselect", this.object)
      }
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      let url = this.entityType + '/';
      if (this.parent) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      await this.$repo.getList(url, this.entityType, this.selectPool, {key: 'date', asc: false}, this.downloading.select)
      this.callModal();
    },
    getBounds() {
      if (this.parent) {
        return [{includes: true, type: this.parent.type, id: this.parent.id}]
      } else {
        return undefined;
      }
    },
    async controlHistory() {
      await this.$repo.getHistory(this, this.entityType, this.object.id, this.downloading);
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.irc = new ElementInterface(this, this.entityType, this.downloading.history);
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(this.entityType, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (!this.object) {
      let url = this.entityType + '/model';
      if (this.parent) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.object = await this.$repo.justGetData(url, this.downloading);
    }
    if (!this.default_state){
      this.default_state = this.start_state !== 'selector' ? this.start_state : 'row';
    }
    if (this.start_state === 'selector') {
      await this.openSelectModal();
    }

  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: this.entityType, id: this.object.id};
      } else {
        this.$emit('delete')
      }
    },
    state: function (value) {
      this.$repo.watchState(this, this.entityType, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, this.entityType, val);
    }
  }
}
</script>
<style>
</style>
