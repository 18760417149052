<template>
  <div :class="'col-' + col">
    <div class="row" style="font-size: 0.68rem; margin-left: 10px; margin-right: 10px">
      <div class="col-12">
        {{ header }}
      </div>
    </div>
    <div class="row" style="margin-left: 10px; margin-right: 10px">
      <div class="col-12" style="padding-bottom: 15px">
        <slot>
          <input v-if="date"
                 :value="dateData"
                 type="date"
                 v-bind="$attrs"
                 v-on="listeners"
                 class="form-control-sm"
                 aria-describedby="addon-right addon-left">
          <input v-else-if="!rows"
                 :value="value"
                 v-bind="$attrs"
                 v-on="listeners"
                 class="form-control-sm"
                 aria-describedby="addon-right addon-left">
          <textarea v-else-if="rows && rows>1"
                    :value="value" v-bind="$attrs"
                    v-on="listeners" :rows="rows" class="full-width" ></textarea>
        </slot>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  inheritAttrs: false,
  name: "base-input",
  props: {
    label: {
      type: String,
      description: "Input label"
    },
    value: {
      type: [String, Number],
      description: "Input value",
      default: ""
    },
    addonRightIcon: {
      type: String,
      description: "Input icon on the right"
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left"
    },
    date: false,
    number: false,
    header: null,
    rows: null,
    col: 12
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      dateData: null,
      digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    }
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      }
    }
  },
  methods: {
    onInput(evt) {
      if (this.date) {
        let date = new Date(evt.target.value);
        this.dateData = date.toISOString().slice(0, 10);
        this.$emit('input', date.getTime());
      } else {
        let value = evt.target.value;
        let data = evt.data;
        if (this.number && !this.digits.includes(data) && evt.inputType !== 'deleteContentBackward'
          && evt.inputType !== 'deleteContentForward') {
          if (value.length > 1 && value[0] === '0' && value[1] !=='.') {
            value = value.substring(1);
          }
          value = value.slice(0, -1);
          evt.target.value = value;
          if (!value.includes('.')) {
            if (data === '-' && value.length === 0) {
              value += '-';
            } else if (data !== '-') {
              value += '.';
            }
          }
        }
        this.$emit('input', value);
      }
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    }
  },
  mounted() {
    if (this.date) {
      if (this.value && this.value > 0) {
        this.dateData = new Date(this.value).toISOString().slice(0, 10);
      } else {
        this.dateData = new Date().toISOString().slice(0, 10);
        this.$emit('input', new Date().getTime());
      }
    } else if (!this.value) {
      this.$emit('input', this.number ? 0 : '');
    }
  }
}
</script>
<style>

</style>
