<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object" class="row row-title" v-on:click="fullView()">
      <div class="col-3">
        {{ object.employee ? object.employee.name : '-'}}
      </div>
      <div class="col-3">
        {{ object.production ? object.production.item.name : '-'}}
      </div>
      <div class="col-2">
        {{ object.equipment ? object.equipment.name : '-'}}
      </div>
      <div class="col-2">
        {{ asDate(object.date) }}
      </div>
      <div class="col-2">
        {{ object.hours  }}
        <span v-if="select">
          <i class="nc-icon nc-check-2 float-right" v-on:click="selectObject()"></i>
        </span>
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.employee.name + ' ' + object.equipment.name + ' ' +  object.production.item.name + ' ' + object.hours + 'ч'}}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Заказ'" :select="select"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="state = 'edit'" @select="selectObject">
                Header
              </full-view-header>

              <div class="row">
                <div class="col-3">
                  Описание заказа:
                </div>
                <div class="col-9">
                  {{ object.name }}
                </div>
              </div>

              <div class="row company-bcg margin-for-inner-obj">
                <employee :start_object="object.employee" :header="'Сотрудник'"
                         :start_state="'text'" :default_state="'text'"></employee>
              </div>
              <div class="row stock-bcg margin-for-inner-obj">
                <equipment :start_object="object.equipment" :header="'Оборудование'"
                       :start_state="'text'" :default_state="'text'"></equipment>
              </div>
              <div class="row stock-bcg margin-for-inner-obj">
                <production :start_object="object.production" :header="'Производство'" :parent = "asParent"
                           :start_state="'text'" :default_state="'text'"></production>
              </div>

              <row :header="'Дата и количество часов:'" :value="asDate(object.date) + ' - ' + object.hours +  'ч'"></row>
              <row :header="'Количество:'" :value="object.value" :as-value="true"></row>
              <row :header="'Комментарий:'" :value="object.note" :as-value="true"></row>

            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <div class="row margin-for-inner-obj" v-if="!parent || parent.type !== 'employee'">
              <employee :start_object="object.employee" :start_state="'text'" :default_state="'text'"
                        :select="true" @select="setEmployee" :header="'Сотрудник:'"> Employee </employee>
            </div>

            <div class="row margin-for-inner-obj" v-if="!parent || parent.type !== 'equipment'">
              <equipment :start_object="object.equipment" :start_state="'text'" :default_state="'text'"
                         :select="true" @select="setEquipment" :header="'Оборудование:'"> Equipment </equipment>
            </div>

            <div class="row margin-for-inner-obj" v-if="!parent || parent.type !== 'production'">
              <production :start_object="object.production" :start_state="'text'" :default_state="'text'"
                          :select-url="'production/month'"
                          :select="true" @select="setProduction" :header="'Производство:'"> Production </production>
            </div>

            <input-row v-model="object.date" :date="true" :header="'Дата:'" class="full-width">
            </input-row>

            <input-row v-model="object.hours" :number="true" :header="'Время в часах:'" class="full-width">
            </input-row>

            <input-row v-model="object.value" :number="true" :header="'Количество:'" class="full-width">
            </input-row>

            <div class="row">
              <div class="col-3">
                <span class="input-title">
                  Заметка:
                </span>
              </div>
              <div class="col-9">
                <textarea v-model="object.note" rows="6" class="full-width" ></textarea>
              </div>
            </div>

            <edit-bottom v-if="!inSave" @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>

        </td>
      </tr>
    </table>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {CELL, ITEM, PLAN, WORK_TIME} from "@/repository/repository";

export default {
  components: {
    Table
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      id: null,
      selectPool: [],
      asParent: {},
      inSave: false,
      viewKey: 0,
      flag: this.$flag,
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
  },
  methods: {
    asDate(date) {
      if (date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setEquipment(equipment) {
      this.object.equipment = equipment;
    },
    setEmployee(employee) {
      this.object.employee = employee;
    },
    setProduction(production) {
      this.object.production = production;
    },
    async save() {
      if (this.parent && !this.object[this.parent]) {
        this.object[this.parent.type] = {id: this.parent.id};
      }
      let object = await this.$repo.save('worktime/', WORK_TIME, this.object, this.downloading);
      if (object) {
        this.object = object;
        this.state = 'full';
        this.$emit('updated', this.object);
      }
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(WORK_TIME, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
    if (!this.object) {
      this.object = await this.$repo.justGetData('worktime/model', this.downloading);
    }
    if (!this.object.note) {
      this.object.note = '';
    }
  },
  watch: {
    object: function () {
      this.asParent = {type: WORK_TIME, id: this.object.id ? this.object.id : 0}
    },
    state: function (value) {
      this.$repo.watchState(this, WORK_TIME, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, WORK_TIME, val);
    }
  }
}
</script>
<style>
</style>
