<template>
  <div :class="'col-' + col">
    <div class="row" style="font-size: 0.68rem">
      <div class="col-12">
        {{ header }}
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        {{ text }}
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      header: null,
      value: null,
      asValue: false,
      asMoney: false,
      asDate: false,
      col: null
    },
    data () {
      return {
        text: null
      }
    },
    methods: {
      hasValue (item, column) {
        return item[column.toLowerCase()] !== 'undefined'
      },
      itemValue (item, column) {
        return item[column.toLowerCase()]
      }
    },
    mounted: function () {
      if (!this.value) {
        this.text = '-';
      } else if (this.asValue && this.value % 1 !== 0) {
        this.text = (Math.round(this.value * 1000) / 1000).toFixed(3);
      } else if (this.asMoney) {
        this.text = (Math.round(this.value * 100) / 100).toFixed(2);
      } else if (this.asDate) {
        let date = new Date(this.value);
        this.text = date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        this.text = this.value;
      }
    }
  }
</script>
<style>
</style>
