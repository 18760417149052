<template>
  <div :style="color">
    {{ asDate }}
  </div>
</template>
<script>
  export default {
    props: {
      value: null
    },
    computed: {
      asDate: function () {
        if (this.value !== undefined) {
          let date = new Date(this.value);
          return date.customFormat('#DD#/#MM#/#YYYY#');
        } else {
          return '-'
        }
      },
      color: function () {
        if (this.value !== undefined) {
          let days = (this.value - Date.now())/86400000;
          if (days < 2) {
            return 'color: red;';
          } else if(days < 5) {
            return 'color: darkorange;';
          } else {
            return 'color: black;';
          }
        } else {
          return 'color: black;';
        }
      }
    }
  }
</script>
<style>
</style>
