<template>
  <div class="content">
    <div class="container-fluid">
      <plan-left-table :objects="plans_left" :header="'К отгрузкам по заказам'"
                       :downloading="downloading"
                       :header-title="'Продукция к отгрузкам по заказам'" :hide-price="true"
                       @update="getPlansLeft"></plan-left-table>
    </div>
  </div>
</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'
import {PLAN, warning} from "@/repository/repository";

export default {
  name: 'plans-left',
  data () {
    return {
      plans_left: [],
      key: authHeader(),
      sortBy: {key: 'item.name', asc: true},
      filter: new Map,
      tableKey: 0,
      warning: null,
      downloading: {state: false, history: {state: false}},
    }
  },
  methods: {
    async getPlansLeft() {
      this.plans_left = [];
      await this.$repo.getList('planleft/', PLAN, this.plans_left, this.sortBy, this.downloading);
    },
    sort() {
      sorter(this.plans_left, this.sortBy);
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    }
  },
  mounted() {
    this.getPlansLeft();
    this.warning = warning;
  }
}
</script>
<style>
</style>
