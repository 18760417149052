<template>
  <div class="row">
    <div class="col-12">
      <br/>
      <card body-classes="table-full-width" style="margin: 5px">
        <template slot="header">
          <h5 class="card-title unselectable" v-on:click="headerClick()">{{ header ? header : 'История'}}</h5>
          <p class="card-category">{{ headerTitle ?  headerTitle : 'Хронология создания и внесения изменений' }}</p>
        </template>
        <table class="table table-hover table-striped">
          <thead>
          <slot name="columns">
            <tr>
              <th class="row-title">
                <div class="row" style="width: 100%">
                  <div class="col-2 unselectable">
                        <span v-on:click="sortBy = {key: 'date', asc: !sortBy.asc}">
                          Дата
                        </span>
                  </div>
                  <div class="col-2 unselectable">
                        <span v-on:click="sortBy = {key: 'author.name', asc: !sortBy.asc}">
                          Автор
                        </span>
                  </div>
                  <div class="col-8 unselectable">
                        <span>
                          Действие
                        </span>
                  </div>
                </div>
              </th>
            </tr>
          </slot>
          </thead>
          <tbody :key="tableKey">
          <tr v-for="(obj, index) in objects" :key="index">
            <td >
              <div class="row" style="width: 100%">
                <div class="col-2 unselectable">
                  {{ asDate(obj.date)}}
                </div>
                <div class="col-2 unselectable">
                  {{ obj.author.name }}
                </div>
                <div class="col-8 unselectable">
                  {{ obj.logg }}
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="downloading && downloading.state">
            <downloading :downloading="downloading">
              Downloading
            </downloading>
          </tr>
          </tbody>
        </table>
      </card>
    </div>
  </div>
</template>
<script>
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'

export default {
  components: {
    LTable,
    Card
  },
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'date', asc: false},
      filter: new Map,
      tableKey: 0,
      newObj: null,
    }
  },
  props: {
    objects: [],
    header: null,
    headerTitle: null,
    select: false,
    parent: null,
    limit: 0,
    showAll: false,
    downloading: null,
  }
  ,
  methods: {
    asDate: function (data) {
      if (data !== undefined) {
        let date = new Date(data);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    headerClick() {
      if (this.select) {
        this.$emit('closeModal');
      } else if (this.limit > 0) {
        this.showAll = !this.showAll;
        this.sortBy = {key: 'date', asc: false};
      } else {
        this.$emit('element', 'event');
      }
    },
  },
  watch: {
    sortBy: function () {
      this.sort()
    },
    notes: function (){
      console.log(`tableKey=${this.tableKey}`);
      this.tableKey += 1;
    }
  },
  async mounted() {

  }
}
</script>
<style>
</style>
