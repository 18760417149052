<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id > 0" class="row row-title" v-on:click="fullView()">
      <div class="col-5">
        {{ object.buyer.name + (object.name ? " " + object.name :"") }}
      </div>
      <div class="col-3">
        {{ object.seller.name }}
      </div>
      <div class="col-2">
        {{ asDate }}
      </div>
      <div class="col-2">
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          Коммерческое предложение
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ `${object.buyer.name}${object.name ? (' ' + object.name) : ''} от ${asDate} ${object.seller.name}`}}
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Коммерческое предложение'" :select="select"
                                :extraKeys="[{name: 'Скачать ТКП', action: 'report'}]"
                                :historical="true"
                                @history="irc.controlHistory()"
                                @regularState="state = default_state ? default_state : start_state"
                                @report="getReport"
                                @editState="irc.startEdit()" @select="selectObject">
                Header
              </full-view-header>

              <row :header="'Наименование запроса:'" :value="object.name"></row>

              <row :header="'Дата:'" :value="object.date" :as-date="true"></row>

              <div class="row company-bcg margin-for-inner-obj">
                <company :start_object="object.buyer" :header="'Покупатель'"
                         :start_state="'text'" :default_state="'text'"></company>
              </div>

              <div class="row company-bcg margin-for-inner-obj">
                <company :start_object="object.seller" :header="'Поставщик'"
                         :start_state="'text'" :default_state="'text'"></company>
              </div>

              <div>

                <div class="row content-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span class="input-title" v-if="!showQuotationElement" v-on:click="getQuotationElement()">Позиции по предложению</span>
                    <quotation-element-table v-if="showQuotationElement" @element="hideElement('quotation_element')" :header="'Позиции'"
                                             :downloading="downloading.quotationElement"
                                             :header-title="`Предлагаемые изделия, материалы и услуги для '${object.buyer.name} от ${asDate}`"
                                             :objects="quotationElements" :parent="asParent">Orders</quotation-element-table>
                  </div>
                </div>

                <div class="row lnk-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showLnk" v-on:click="getLnk(true)">Файлы</span>
                    <lnk-table v-if="showLnk" @element="hideElement" :header="'Файлы'"
                               :downloading="downloading.lnk"
                               :header-title="`Связные файлы и изображения для предложения ${object.buyer.name} от ${asDate}`"
                               :objects="lnks" :parent="asParent">Lnk</lnk-table>
                  </div>
                </div>

              </div>

              <row v-model="object.comment" :header="'Комментарий:'" class="full-width" ></row>

              <event-table v-if="irc.history.show && irc.history.data"
                           :objects="irc.history.data" :downloading="downloading.history"></event-table>

            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Наименование запроса:'" class="full-width"></input-row>

            <input-row v-model="object.date" :header="'Дата:'" :date="true" class="full-width"></input-row>

            <div :key="selectZone">
              <div class="row margin-for-inner-obj">
                <company :start_object="object.buyer" :start_state="'text'" :default_state="'text'"
                         :select="true" @select="setBuyer" :header="'Покупатель:'"> Buyer </company>
              </div>

              <div class="row margin-for-inner-obj">
                <company :start_object="object.seller" :start_state="'text'" :default_state="'text'"
                         :select="true" @select="setSeller" :header="'Поставщик:'"> Seller </company>
              </div>

              <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>
            </div>

            <input-row v-model="object.comment" :header="'Комментарий:'" class="full-width"></input-row>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <document-table v-bind:companies="selectPool" v-bind:header="'Коммерческие предложения'"
                      :downloading="downloading.select"
                      @closeModal="closeModal" @select="selectObjectFromTable"
                      v-bind:header-title="'Коммерческие преложения по запросам и торгам'" :select="select"></document-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {DOCUMENT, ITEM, LNK, QUOTATION} from "@/repository/repository";
import {asDate} from "@/services";
import {ElementInterface} from "@/models/elementInteface";

export default {
  components: {
    CompaniesTable,
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    }
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      quotationElements: [],
      showQuotationElement: false,
      lnks: [],
      showLnk: false,
      id: null,
      selectPool: [],
      asParent: {},
      selectZone: 0,
      showWarning: false,
      warningHeader: null,
      warningKeys: null,
      showNumber: false,
      counter: null,
      viewKey: 0,
      flag: this.$flag,
      irc: null,
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}, quotationElement: {state: false}, lnk: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    async fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    async getQuotationElement() {
      this.quotationElements = [];
      this.showQuotationElement = true;
      await this.$repo.getList(`${QUOTATION}/elements/${this.object.id}`,'portion', this.quotationElements, null, this.downloading.quotationElement);
    },
    async getLnk(show) {
      this.lnks = [];
      this.showLnk = true;
      await this.$repo.getList(`${QUOTATION}/lnk/${this.object.id}`, LNK, this.lnks, null, this.downloading.lnk);
    },
    hideElement(element) {
      if (element === 'quotation_element') {
        this.showQuotationElement = false;
        this.quotationElements = [];
      } else if (element === 'lnk') {
        this.showLnk = false;
        this.lnks = [];
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setSeller(contact) {
      this.object.seller = contact;
      this.selectZone += 1;
    },
    setBuyer(contact) {
      this.object.buyer = contact;
      this.selectZone += 1;
    },
    save() {
      this.$repo.uniSave(this, QUOTATION, `${QUOTATION}/`, this.downloading);
    },
    async getForm() {
      let url = 'document/form/' + this.object.id;
      if (this.counter.id === 0) {
        await this.saveCounter();
      }
      this.showNumber = false;
      await this.$repo.downloadFile(url, 'УПД №' + this.counter.number + ' ' + this.object.receiver.legalShortName + '.xls', this.downloading);
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    setImage(image) {
      this.object.image = image;
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      await this.$repo.getSelectPoll(QUOTATION, this.selectBounds, this.selectPool, this.downloading.select);
      this.callModal()
    },
    getBounds() {
      if (this.parent) {
        return [{includes: true, type: this.parent.type, id: this.parent.id}]
      } else {
        return undefined;
      }
    },
    async getReport() {
      await this.$repo.downloadFile(`${QUOTATION}/report/${this.object.id}`, `ТКП ${this.object.buyer.name} от ${this.asDate}.zip`, this.downloading);
    }
  },
  mounted: async function () {
    this.irc = new ElementInterface(this, QUOTATION, this.downloading.history);
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(QUOTATION, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (!this.object) {
      this.object = await this.$repo.justGetData(`${QUOTATION}/model`, this.downloading);
    }
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: QUOTATION, id: this.object.id};
      }
    },
    state: async function (value) {
      this.$repo.watchState(this, QUOTATION, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, QUOTATION, val);
    }
  }
}
</script>
<style>
</style>
