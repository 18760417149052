<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id > 0" class="row row-title"
         :key="rowKey"
         v-on:click="select ? addToSelection() : fullView()"
         v-on:dblclick="fullView"
         :style="(object.urgent && object.urgent==='urgent') ? 'color:red' : 'color:black'">
      <div class="col-4" :style="selected ? 'font-weight: bold' : ''">
        {{ object.assembly.item.name}}
      </div>
      <div class="col-2" :style="selected ? 'font-weight: bold' : ''">
        {{ object.value.toFixed(2)}}

        <span v-if="object.executedValue > 0">
          /
          <span style="color: #0a6715">
            {{object.executedValue.toFixed(2)}}
          </span>
        </span>

        <span v-if="object.executedValue> 0 && object.executedValue < object.value">
          /
          <span style="color: #670a5c">
            {{(object.value - object.executedValue).toFixed(2)}}
          </span>
        </span>

        {{(object.assembly.item.measure ? object.assembly.item.measure : '') }}
      </div>
      <div class="col-2" :style="selected ? 'font-weight: bold' : ''">
        {{ irc.asDate(object.deadline) }}
      </div>
      <div class="col-2" :style="selected ? 'font-weight: bold' : ''">
        {{ irc.asDate(object.date) }}
      </div>
      <div class="col-2" :style="selected ? 'font-weight: bold' : ''">
        {{ object.finished ? 'Закрыта' : object.purchase ? 'В работе' : 'Не обработана'}}
      </div>
    </div>

    <div v-if="state === 'selector' && object && object.id === -1" class="row">
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher">
      <div class="col-3">
        <a class="input-title">
          Заявка
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0 && object.assembly">
        {{ object.assembly.item.name + ' - '
            + object.value + (object.assembly.item.measure ? object.assembly.item.measure : '')
            + ' от ' + irc.asDate(object.date) + (object.deadline ? ' до ' + irc.asDate(object.deadline) : '') }}
      </div>
      <div class="col-9" v-if="!object">
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object && object.id > 0" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Заявка от ' + irc.asDate(object.date) + (object.deadline ? ' срок исполнения до ' + irc.asDate(object.deadline) : '')
               + ', разместил ' + object.user.name"
                                :select="select"
                                :historical="true"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="irc.startEdit()" @select="selectObject"
                                @makeUrgent="urgentState('urgent')"
                                @makeNotUrgent="urgentState('ok')"
                                @history="irc.controlHistory()"
                                :extra-keys="(object.urgent && object.urgent==='urgent') ? [{name: 'Не срочно', action: 'makeNotUrgent'}] : [{name: 'Срочно', action: 'makeUrgent'}]"
                                :style="(object.urgent && object.urgent==='urgent') ? 'color:red' : 'color:black'">
                Header
              </full-view-header>

              <div class="row item-bcg margin-for-inner-obj">
                <assembly :start_object="object.assembly" :header="'Номенклатура'"
                      :start_state="'text'" :default_state="'text'"></assembly>
              </div>

              <div class="row">
                <div class="col-3">
                  {{'Количество, ' + (object.assembly.item.measure ? object.assembly.item.measure : '') +':'}}
                </div>
                <div class="col-9">
                  {{ object.value.toFixed(3) }}

                  <span v-if="object.executedValue > 0">
                    /
                    <span style="color: #0a6715">
                      {{object.executedValue.toFixed(3)}}
                    </span>
                  </span>

                  <span v-if="object.executedValue> 0 && object.executedValue < object.value">
                    /
                    <span style="color: #670a5c">
                      {{(object.value - object.executedValue).toFixed(3)}}
                    </span>
                  </span>

                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  Статус заявки:
                </div>
                <div class="col-9">
                  {{ object.finished ? 'Закрыта' : object.purchase ? 'В работе' : 'Не обработана'}}
                </div>
              </div>

              <div class="row company-bcg margin-for-inner-obj">
                <company :start_object="object.supplier" :header="'Рекомендуемый поставщик:'"
                      :start_state="'text'" :default_state="'text'"></company>
              </div>

              <div v-if="object.order" class="row order-bcg margin-for-inner-obj">
                <order :start_object="object.order" :header="'Относится к заказу:'"
                         :start_state="'text'" :default_state="'text'"></order>
              </div>

              <div v-if="plan" class="row plan-bcg margin-for-inner-obj">
                <plan :start_object="plan" :header="'Конечная позиция'"
                      :start_state="'text'" :default_state="'text'"></plan>
              </div>

              <div v-if="object.assembly.parent" class="row assembly-bcg margin-for-inner-obj">
                <assembly :start_object="object.assembly.parent" :header="'Необходим для выпуска'"
                          :start_state="'text'" :default_state="'text'"></assembly>
              </div>

              <div v-if="!parent || (parent && parent.type!=='purchase')" class="row purchase-bcg margin-for-inner-obj">
                <purchase :start_object="object.purchase" :header="'Размещено в закупке:'"
                       :start_state="'text'" :default_state="'text'"></purchase>
              </div>

              <div v-if="!select_modal">

                <div class="row receipt-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showReceipt" v-on:click="getReceipt">
                      Поступления
                      <button v-if="!object.finished && object.value > object.executedValue"
                              class="btn btn-light btn-fill btn-usm float-right margin-right-icon"
                              v-on:click="handleReceipts">
                        Автоподбор
                      </button>
                    </span>
                    <receipts-table v-if="showReceipt" @element="hideElement('receipt')" :header="'Поступления'"
                                    :downloading="downloading.receipt"
                                    :header-title="'Поступления в рамках номенклатуры'"
                                    :objects="receipts" :parent="asParent">Receipts</receipts-table>
                  </div>
                </div>

                <div class="row note-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showNote" v-on:click="getNote">Заметки</span>
                    <note-table v-if="showNote" @element="hideElement('note')" :header="'Заметки'"
                                :downloading="downloading.note"
                                :header-title="'Заметки по заявке'" :show-all="true"
                                :notes="notes" :parent="asParent">Notes</note-table>
                  </div>
                </div>

                <event-table v-if="irc.history.show && irc.history.data"
                             :objects="irc.history.data" :downloading="downloading.note"></event-table>

              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object && object.id >= 0" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <div :key="selectZone">
              <div class="row margin-for-inner-obj">
                <company :start_object="object.supplier" :start_state="'text'" :default_state="'text'"
                         :select="true" @select="setSupplier" :header="'Рекомендуемый поставщик:'"> Recommended supplier </company>
              </div>
              <div class="row margin-for-inner-obj">
                <purchase :start_object="object.purchase" :start_state="'text'" :default_state="'text'"
                         :select="true" @select="setPurchase" :header="'Размещен в закупке:'"> Purchase </purchase>
              </div>
            </div>

            <input-row v-model="object.value" :header="'Количество:'" :number="true" class="full-width"></input-row>

            <input-row v-model="object.deadline" :header="'Дата исполнения до:'" :date="true" class="full-width"></input-row>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <enquiries-table v-bind:objects="selectPool" v-bind:header="'Заявки'"
                       :downloading="downloading.select"
                       @select="selectObjectFromTable"
                       @multiselect="multiSelect"
                       @closeModal="closeModal" :select_modal="true"
                       v-bind:header-title="'Актуальные не размещенные заявки'"
                       :select="select" :multiselect="multiselect"></enquiries-table>

    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {ElementInterface} from "@/models/elementInteface";
import {ENQUIRE, ITEM, NOTE, PURCHASE, RECEIPT, RESERVE} from "@/repository/repository";

export default {
  components: {
    CompaniesTable,
    Table
  },
  computed: {
    plan: function () {
      let assembly = this.object.assembly;
      while (!assembly.plan && assembly.parent) {
        assembly = assembly.parent;
      }
      return assembly.plan;
    }
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      receipts: [],
      showReceipt: false,
      reserves: [],
      showReserve: false,
      notes: [],
      showNote: false,
      id: null,
      selectPool: [],
      asParent: {},
      selectZone: 0,
      viewKey: 0,
      flag: this.$flag,
      irc: null,
      showHistory: false,
      history: null,
      selected: false,
      rowKey: 0,
      showSelect: false,
      downloading: {state: false, select: {state: false}, receipt: {state: false}, reserve: {state: false}, note: {state: false}, history: {state: false}}
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    multiselect: false,
    selectBounds: null,
    parent: null,
    create: false,
    select_modal: false
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else if (this.create && !this.object) {
        this.createModel();
      } else {
        this.fullView()
      }
    },
    startEdit() {
      this.state = 'edit';
      this.showHistory = false;
    },
    fullView() {
      let id = this.object.id;
      if (id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
        if (this.create) {
          this.object = null;
          this.state = 'text';
        }
      }
    },
    async createModel() {
      let url = 'enquire/model?type=' + this.parent.type + '&id=' + this.parent.id;
      this.object = await this.$repo.getSingle(url, ENQUIRE, this.downloading);
      this.state = 'edit';
    },
    async getNote() {
      this.notes = [];
      this.showNote = true;
      await this.$repo.getList('enquire/note/' + this.object.id, NOTE, this.notes, {key: 'date', asc: false}, this.downloading.note);
    },
    async getReserve() {
      this.reserves = [];
      this.showReserve = true;
      await this.$repo.getList('enquire/reserve/' + this.object.id, RESERVE, this.reserves, {key: 'cell.item.name', asc: true}, this.downloading.reserve);
    },
    async getReceipt() {
      this.receipts = [];
      this.showReceipt = true;
      await this.$repo.getList('enquire/receipt/' + this.object.id, RECEIPT, this.receipts, null, this.downloading.receipt);
    },
    hideElement(element) {
      if (element === NOTE) {
        this.showNote = false;
        this.notes = [];
      } else if (element === RESERVE) {
        this.showReserve = false;
        this.reserves = [];
      } else if (element === RECEIPT) {
        this.showReceipt = false;
        this.receipts = [];
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setSupplier(company) {
      this.object.supplier = company;
      this.selectZone += 1;
    },
    setPurchase(purchase) {
      this.object.purchase = purchase;
      this.selectZone += 1;
    },
    save() {
      this.$repo.uniSave(this, ENQUIRE, 'enquire/', this.downloading);
    },
    selectObject(object) {
      this.$emit('select', object ? object : this.object);
    },
    async selectObjectFromTable(object) {
      if (this.start_state !== "selector") {
        this.closeModal();
        this.object = object;
        this.selectObject(this.object);
      } else {
        if (this.parent.type === PURCHASE) {
          object.purchase = await this.$repo.get(PURCHASE, this.parent.id, this.downloading);
          let obj = await this.$repo.save('enquire/', ENQUIRE, object, this.downloading);
          if (obj && obj.id > 0) {
            this.$emit("select", obj);
            this.state = 'row';
          }
        } else {
          this.$emit('close', true);
        }
      }
    },
    multiSelect(idList) {
      this.closeModal();
      this.$emit('multiselect', idList);
    },
    setImage(image) {
      this.object.image = image;
    },
    addToSelection() {
      this.selected = !this.selected;
      if (this.selected) {
        this.$emit("multiselect", this.object)
      } else {
        this.$emit("removeselect", this.object)
      }
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      let url = 'enquire/';
      if (this.parent) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      await this.$repo.getList(url, ENQUIRE, this.selectPool, {key: 'date', asc: false}, this.downloading.select)
      this.callModal();
    },
    getBounds() {
      if (this.parent) {
        return [{includes: true, type: this.parent.type, id: this.parent.id}]
      } else {
        return undefined;
      }
    },
    async urgentState(state) {
      await this.$repo.justGetData(`${ENQUIRE}/urgent/${this.object.id}/${state}`, this.downloading)
    },
    handleReceipts() {
      this.$repo.post(`${ENQUIRE}/hrfe/${this.object.id}`, this.downloading)
    },
    async controlHistory() {
      await this.$repo.getHistory(this, ENQUIRE, this.object.id, this.downloading.history);
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.irc = new ElementInterface(this, ENQUIRE, this.downloading.history);
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(ENQUIRE, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (!this.object) {
      let url = 'enquire/model';
      if (this.parent) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.object = await this.$repo.justGetData(url, this.downloading);
    }
    if (!this.default_state){
      this.default_state = this.start_state !== 'selector' ? this.start_state : 'row';
    }
    if (this.start_state === 'selector') {
      await this.openSelectModal();
    }

  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: ENQUIRE, id: this.object.id};
      } else {
        this.$emit('delete')
      }
    },
    state: function (value) {
      this.$repo.watchState(this, ENQUIRE, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, ENQUIRE, val);
    }
  }
}
</script>
<style>
</style>
