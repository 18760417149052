<template>
  <div class="content">
    <div class="container-fluid">
      <quotation-table :objects="objects" :header="'Предложения'"
                       :downloading="downloading"
                       :header-title="'Коммерческие предложения по запросам'"
                       :active="true"
                       @update="getQuotations()">
      </quotation-table>
    </div>
  </div>
</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'
import ItemsTable from "../tables/ItemsTable";
import {QUOTATION, warning} from "@/repository/repository";

export default {
  name: 'quotations',
  data () {
    return {
      objects: [],
      key: authHeader(),
      sortBy: {key: 'buyer.name', asc: true},
      filter: new Map,
      tableKey: 0,
      warning: null,
      downloading: {state: false, history: {state: false}},
    }
  },
  methods: {
    async getQuotations() {
      this.objects = [];
      await this.$repo.getList(`${QUOTATION}/`, QUOTATION, this.objects, this.sortBy, this.downloading);
    },
    sort() {
      sorter(this.date, this.sortBy);
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    }
  },
  mounted() {
    this.getQuotations();
  }
}
</script>
<style>
</style>
