<template>
  <div>
    <div v-if="state === 'view'" v-on:click="state='edit'">
      {{object[field] ? object[field] : "-"}}
    </div>

    <div v-else class="row">
      <div class="col-md-8">
        <active-input  v-if="" v-model="value" :date="date" :moment="moment" :number="number" @enter="setValue" @esc="toView"/>
      </div>
      <div class="col-md-4">
        <i class="nc-icon nc-simple-remove" style="margin-left: 10px" v-on:click="toView"></i>
      </div>
    </div>

  </div>
</template>
<script>
  export default {
    props: {
      object: null,
      field: null,
      date: false,
      number: false,
      moment: false
    },
    data () {
      return {
        value: null,
        state: "view"
      }
    },
    methods: {
      setValue() {
        this.object[this.field] = this.value;
        this.state = 'view';
        this.$emit('update');
      },
      toView() {
        this.value = this.object[this.field];
        this.state = 'view';
      }
    },
    mounted: function () {
      this.value = this.object[this.field];
    }
  }
</script>
<style>
</style>
