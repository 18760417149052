<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.enquire" class="row row-title" v-on:click="fullView">
      <div class="col-8">
        {{ object.enquire && object.enquire.order ? (object.enquire.order.name + " ") : "" + object.enquire.assembly.item.name}}
      </div>
      <div class="col-4">
        {{ object.value}}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher">
      <div class="col-3">
        <a class="input-title">
          Наименование
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.portion.out.item.name}}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Распределение поставки'" :select="select"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="startEdit" @select="selectObject">
                Header
              </full-view-header>

              <div v-if="!parent || parent.type !== 'enquire'" class="row enquire-bcg margin-for-inner-obj">
                <enquire :start_object="object.enquire" :header="'Заявка'" :parent="asParent"
                      :start_state="'text'" :default_state="'text'"></enquire>
              </div>

              <div class="row item-bcg margin-for-inner-obj">
                <portion :start_object="object.portion ? object.portion : null" :header="'Поставка'" :parent="asParent"
                         :start_state="'text'" :default_state="'text'"></portion>
              </div>

              <div class="row">
                <div class="col-3">
                  Количество:
                </div>
                <div class="col-9">
                  {{ object.value }}
                </div>
              </div>

            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <div :key="selectZone">

              <div v-if="!parent || parent.type !== 'enquire'" class="row margin-for-inner-obj">
                <enquire :start_object="eObject.enquire" :start_state="'text'" :default_state="'text'"
                         :select="true" @select="setEnquire" :header="'Заявка:'"> Sender </enquire>
              </div>

              <div class="row margin-for-inner-obj">
                <portion :start_object="eObject.portion" :start_state="'text'" :default_state="'text'"
                         :parent="parent"
                      :select="true" @select="setPortion" :header="'Поступление:'"> Sender </portion>
              </div>

            </div>

            <input-row v-model="eObject.value" :header="'Распределенный объём:'" :number="true" class="full-width"></input-row>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>
          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <document-table v-bind:companies="selectPool" v-bind:header="'Распеределение'" @select="selectObjectFromTable"
                      :downloading="downloading.select"
                      @closeModal="closeModal"
                      v-bind:header-title="'Распределение поступлений по закупкам'" :select="select"></document-table>

    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import {ITEM, PLAN, RECEIPT} from "@/repository/repository";

export default {
  components: {
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    }
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      eObject: {},
      id: null,
      selectPool: [],
      asParent: {type:'receipt', id: 0},
      selectZone: 0,
      viewKey: 0,
      flag: this.$flag,
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    create: false,
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else if (this.create && !this.object) {
        this.createModel();
      } else {
        this.fullView()
      }
    },
    fullView() {
      if (this.object.enquire.id && this.object.portion && this.object.portion.id) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
        if (this.create) {
          this.object = null;
          this.state = 'text';
        }
      }
    },
    async createModel() {
      let url = 'receipt/model?type=' + this.parent.type + '&id=' + this.parent.id;
      this.object = await this.$repo.getSingle(url, null, this.downloading);
      this.startEdit();
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setEnquire(enquire) {
      this.object.enquire = enquire;
      this.selectZone += 1;
    },
    setPortion(portion) {
      if (this.eObject) {
        this.eObject.portion = portion;
        this.selectZone += 1;
      }
    },
    save() {
      this.$repo.uniSave(this, RECEIPT, 'receipt/', this.downloading);
      this.object = this.start_object;
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    setImage(image) {
      this.object.image = image;
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      if (this.parent) {
        await this.$repo.justGetList('receipt/?type=' + this.parent.type + '&id=' + this.parent.id, this.selectPool, null, this.downloading.select);
      } else {
        await this.$repo.justGetList('receipt/', this.selectPool, null, this.downloading.select);
      }
      this.callModal();
    },
    getBounds() {
      if (this.parent) {
        return [{includes: true, type: this.parent.type, id: this.parent.id}]
      } else {
        return undefined;
      }
    },
    startEdit(){
      this.eObject = JSON.parse(JSON.stringify(this.object));
      this.state = "edit";
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(RECEIPT, [this.start_object.id], this.downloading) : this.start_object;
    if (this.state === "edit") {
      this.startEdit();
    }
    this.id = this._uid;
    /*
    if (!this.object) {
      let url = 'receipt/model';
      if (this.parent) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.object = await this.$repo.getSingle(url);
    }*/
    if (!this.default_state){
      this.default_state = this.start_state;
    }
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: RECEIPT, id: this.object.id}
      }
      this.viewKey++;
    },
    state: function (value) {
      this.$repo.watchState(this, RECEIPT, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, RECEIPT, val);
    }
  }
}
</script>
<style>
</style>
