<template>
  <div class="padding-header">
    <button v-if="extraKeys" v-for="(key, index) in extraKeys" :key="index" style="margin-bottom: 15px"
            class="btn btn-light btn-fill btn-usm float-right margin-right-icon margin-top-bottom" v-on:click="returnAction(key.action)">
      {{ key.name }}
    </button>

    <button class="btn btn-light btn-fill btn-usm float-right margin-right-icon margin-top-bottom" v-on:click="save">
      Сохранить
    </button>
    <button class="btn btn-light btn-fill btn-usm float-right margin-right-icon margin-top-bottom" v-on:click="fullView">
      Отменить
    </button>
  </div>
</template>
<script>
  export default {
    props: {
      header: null,
      extraKeys: null,
      size: ''
    },
    data () {
      return {
        text: null,
      }
    },
    methods: {
      fullView () {
        return this.$emit('fullView');
      },
      save () {
        return this.$emit('save');
      },
      returnAction(action) {
        this.$emit(action)
      }
    }
  }
</script>
<style>
</style>
