<template>
  <div class="content">
    <div class="container-fluid">
      <stock-cell-table :objects="item_cells" :header="'Складские остатки'"
                        :downloading="downloading"
                        :header-title="'Остатки по складу и контроль неснижаемых остатков'" :hide-price="true"
                        @update="getStock"></stock-cell-table>
    </div>
  </div>
</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'
import {CELL} from "@/repository/repository";

export default {
  name: 'item_cells-left',
  data () {
    return {
      item_cells: [],
      key: authHeader(),
      sortBy: {key: 'item.name', asc: true},
      filter: new Map,
      tableKey: 0,
      downloading: {state: false, history: {state: false}},
    }
  },
  methods: {
    async getStock() {
      this.item_cells = [];
      await this.$repo.getList('stockcell/item_cells/', CELL, this.item_cells, this.sortBy, this.downloading);
    },
    sort() {
      sorter(this.item_cells, this.sortBy);
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    }
  },
  created() {
    this.getStock();
  }
}
</script>
<style>
</style>
