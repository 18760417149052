<template>
  <div class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state_mod === 'row' && object" class="row row-title" v-on:click="state_mod = 'full'">
      <div class="col-4">
        {{ object.item.name }}
      </div>
      <div class="col-4">
        {{ object.item.category ? object.item.category.name : '-'}}
      </div>
      <div class="col-2">
        {{ object.value }}
      </div>
      <div class="col-2">
        {{ object.item.measure }}
      </div>
    </div>

    <div v-if="state_mod === 'full' && object" class="full-title">
      <full-view-header :header="'Комплектация'" :select="select" :hide-action="true"
                        @regularState="state_mod = 'row'">
        Header
      </full-view-header>

      <item v-bind:start_object="object.item" v-bind:start_state="'text'" class="item-bcg"> Item </item>
      <div class="row">
        <div class="col-3">
          Количество:
        </div>
        <div class="col-9">
          {{ object.value }}
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import ItemCategorySelect from "../selectable/ItemCategorySelect";

export default {
  components: {
    ItemCategorySelect
  },
  data () {
    return {
      key: authHeader(),
      state_mod: null,
      downloading: {state: false, select: {state: false}}
    }
  },
  props: {
    object: {},
    state: '',
    select: false,
    parent: null
  },
  methods: {
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    onClickSelect(category) {
      this.item.category = category;
    },
    async save() {
      let url = 'content/';
      if (this.parent) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      let object = await this.$repo.save(url, null, this.object, this.downloading);
      if (object) {
        this.object = object;
        this.fullView(this.object.id);
      }
    },
    setItem(item) {
      this.object.item = item;
    }
  },
  mounted() {
    this.state_mod = this.state;
  }
}
</script>
<style>
</style>
