<template>
  <div>
    <span v-on:click="callModal()">
      <span v-if="!category" class="unselectable">
        {{add_mode ? 'Добавить' : 'Выбрать категорию'}}
      </span>
      <span v-else class="unselectable">
        {{ category.name }}
      </span>
      <i v-if="add_mode" class="nc-icon nc-simple-add"></i>
      <i v-else class="nc-icon nc-settings-gear-64"></i>
    </span>

    <div class="modal" :id="id">
      <div class="modal-content-50">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <card class="strpied-tabled-with-hover"
                    body-classes="table-full-width table-responsive">
                <template slot="header">
                  <i class="nc-icon nc-simple-remove float-right" v-on:click="closeModal"></i>
                  <h4 class="card-title" v-on:click="closeModal">Категории продукции</h4>
                  <p class="card-category" v-on:click="closeModal">Перечень категорий продукции и материалов</p>
                </template>
                <table class="table table-hover table-striped">
                  <thead>
                  <slot name="columns">
                    <tr>
                      <th>
                        <div class="row" style="width: 100%">
                          <div class="col-12 unselectable">
                            Наименование
                            <span class="float-right unselectable" v-on:click="addNew()">
                              new
                              <i class="nc-icon nc-simple-add"></i>
                            </span>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </slot>
                  </thead>
                  <tbody>
                  <tr v-for="(cate, index) in objects" :key="index" >
                    <td>
                      <item-category v-bind:category="cate" v-if="cate.id > 0" @category="returnCategory" @update="getCategories" v-bind:state="'row'"></item-category>
                      <item-category v-bind:category="cate" v-if="cate.id === 0" @category="returnCategory" @close="cancelNew" @update="getCategories" v-bind:state="'edit'"></item-category>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {authHeader} from "@/auth";
import ItemCategory from "@/elements/ItemCategory"
import {sorter} from '@/services'

export default {
  components: {
    ItemCategory,
  },
  data () {
    return {
      key: authHeader(),
      objects: [],
      sortBy: {key: 'name', asc: true},
      id: null
    }
  },
  props: {
    category: {},
    add_mode: false
  },
  methods: {
    async getCategories () {
      this.objects = [];
      await this.$repo.justGetList('item_category/all', this.objects, this.sortBy);
    },
    sort() {
      sorter(this.objects, this.sortBy);
    },
    returnCategory(cate) {
      this.category = cate;
      this.closeModal();
      this.$emit('category', cate);
    },
    callModal() {
      let modal = document.getElementById(this.id);
      modal.style.display = "block";
      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      }
    },
    closeModal() {
      let modal = document.getElementById(this.id);
      modal.style.display = "none";
    },
    async addNew() {
      if (this.objects.length === 0 || this.objects[0].id > 0) {
        let object = await this.$repo.justGetData('item_category/model');
        this.objects.unshift(object);
      }
    },
  cancelNew() {
    if (this.objects[0].id < 1) {
      this.objects.shift();
    }
  }
  },
  mounted() {
    this.getCategories();
    this.id = this._uid;
  }
}
</script>
<style scoped>

</style>
