<template>
  <div>

    <div class="row">
      <div class="col-12">
        <card body-classes="table-full-width" style="margin: 5px">
          <table class="full-width" :key="inputKey">
            <tr>
              <td class="full-width">
                <div class="full-width">

                  <div class="row margin-for-inner-obj" style="margin-bottom: 20px">
                    <item :start_object="item" :start_state="'text'" :default_state="'text'"
                           :select="true" :header="'Номенклатура:'" @select="setItem"> Stock </item>
                  </div>

                  <input-row v-model="value"
                             :header="'Количество:'"
                             :number="true" class="full-width"></input-row>

                  <div class="row margin-for-inner-obj">
                    <stock :start_object="stock" :start_state="'text'" :default_state="'text'"
                           :select="true" :header="'Склад:'" @select="setStock"> Stock </stock>
                  </div>

                  <button class="btn btn-light btn-fill btn-sm float-right margin-right-icon" v-on:click="save">
                    Добавить поступление
                  </button>

                </div>
              </td>
            </tr>
          </table>

        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card body-classes="table-full-width" style="margin: 5px">
          <base-input v-model="textSearch" style="margin-left: 15px; width: 50%;"></base-input>
          <template slot="header">
            <h4 class="card-title unselectable" v-on:click="headerClick()">{{ header }}</h4>
            <p class="card-category unselectable">{{ headerTitle }}</p>
          </template>
          <table class="table table-hover table-striped" :key="tableKey">
            <thead>
            <slot name="columns">
              <tr>
                <th class="row-title">
                  <div v-if="realStock" class="row" style="width: 100%">
                    <div class="col-4 unselectable">
                        <span v-on:click="sortBy = {key: 'item.name', asc: !sortBy.asc}">
                          Номенклатура
                        </span>
                      <item-category @filter="catchFilter" v-bind:field="'item.category'"> Category Selector </item-category>
                    </div>
                    <div class="col-5 unselectable">
                        <span v-on:click="sortBy = {key: 'stock.contact.name', asc: !sortBy.asc}">
                          Место хранения
                        </span>
                    </div>
                    <div class="col-3 unselectable">
                        <span>
                          Наличие
                        </span>
                    </div>
                  </div>

                  <div v-else class="row" style="width: 100%">
                    <div class="col-12 unselectable">
                        <span v-on:click="sortBy = {key: 'item.name', asc: !sortBy.asc}">
                          Номенклатура
                        </span>
                      <item-category @filter="catchFilter" v-bind:field="'item.category'"> Category Selector </item-category>
                    </div>
                  </div>

                </th>
              </tr>
            </slot>
            </thead>
            <tbody :key="tableKey">
            <tr v-for="(object, index) in objects" :key="index" v-if="object && fitFilter(object)">
              <td>
                <stock-man-cell v-if="realStock" :start_object="object" :start_state="'row'" :default_state="'row'"
                                :select="select" @select="selectObject"></stock-man-cell>
              </td>
            </tr>
            <tr v-if="downloading && downloading.state">
              <downloading :downloading="downloading">
                Downloading
              </downloading>
            </tr>
            </tbody>
          </table>
        </card>
      </div>
    </div>

  </div>
</template>
<script>
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter, textContainsText} from '@/services'

export default {
  components: {
    LTable,
    Card
  },
  data () {
    return {
      inputKey: 0,
      key: authHeader(),
      sortBy: {key: 'item.name', asc: true},
      filter: new Map,
      tableKey: 0,
      realStock: true,
      textSearch: '',
      stock: null,
      item: null,
      value: 0
    }
  },
  props: {
    objects: [],
    header: null,
    headerTitle: null,
    select: false,
    parent: null,
    downloading: null,
  }
  ,
  methods: {
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter) && textContainsText(object.item.name, this.textSearch);
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    selectObject(object) {
      this.$emit('select', object);
    },
    headerClick() {
      if (this.select) {
        this.objects = []
        this.$emit('closeModal');
      } else {
        this.$emit('element');
        this.$emit('update');
      }
    },
    async save() {
      if (this.value !== 0 && this.stock && this.stock.id > 0 && this.item && this.item.id > 0) {
        let income = await this.$repo.post('income/new?value=' + this.value + '&stockId=' + this.stock.id
          + '&itemId=' + this.item.id, this.downloading);
        this.item = {};
        this.value = 0;
        this.updateCells(await this.$repo.getSingle('stockcell/' + income.cell.id, 'cell', this.downloading));
        ++this.inputKey;
      } else {
        console.log('Saving error')
      }
    },
    setStock(stock) {
      this.stock = stock;
    },
    setItem(item) {
      this.item =item;
    },
    async getStock() {
      let tmp = [];
      await this.$repo.getList('stock/', 'stock', tmp, this.downloading);
      if (tmp.length > 0) {
        this.stock = tmp[0];
        ++this.inputKey;
      }
    },
    updateCells(cell) {
      if (this.objects && this.objects.length > 0) {
        for (let i = 0; i < this.objects.length; i++) {
          if (this.objects[i] && this.objects[i].id === cell.id) {
            this.objects[i] = null;
            break;
          }
        }
        this.objects.unshift(cell);
        ++this.tableKey;
      } else {
        this.$emit('update')
      }
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    }
  },
  mounted: async function () {
    await this.getStock();
  }
}
</script>
<style>
</style>
