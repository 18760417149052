<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id > 0" class="row row-title" v-on:click="fullView()">
      <div class="col-6">
        {{ object.name }}
      </div>
      <div class="col-6">
        {{ object.contact.name }}
      </div>
    </div>

    <div v-if="state === 'row_select' && object" class="row row-title" v-on:click="selectObject()">
      <div class="col-6">
          {{ object.contact.name }}
      </div>
      <div class="col-6">
        {{ object.name }}
        <i class="nc-icon nc-check-2 float-right"></i>
      </div>
    </div>

    <div v-if="state === 'text' " class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.contact.name + ' - ' + object.name }}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Склад или подразделение'" :select="select"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="state = 'edit'" @select="selectObject">
                Header
              </full-view-header>

              <row :header="'Наименование:'" :value="object.name"></row>
              <row :header="'Юр. наименование:'" :value="object.legalShortName"></row>

              <div class="row company-bcg margin-for-inner-obj">
                <company :start_object="object.contact" :header="'Принадлежность'" :default_state="'text'"
                         :start_state="'text'"></company>
              </div>

              <row :header="'Ставка НДС, %:'" :value="object.tax" :as-value="true"></row>

              <div v-if="!select">
                <div class="row lnk-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showDocument" v-on:click="getDocument">Входящие документы</span>
                    <document-table v-if="showDocument" @element="hideElement('document')"
                                    :downloading="downloading.document"
                                    :header="'Входящие документы'"
                                    :header-title="'Входящие накладные, акты выполненных работ и прочее'"
                                    :objects="documents" :parent="asParent">Stocks</document-table>
                  </div>
                </div>
                <div class="row production-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showProduction" v-on:click="getProduction(true)">Выпуск продукции</span>
                    <productions-table v-if="showProduction" @element="hideElement('production')"
                                       :downloading="downloading.production"
                                       :header="'Выпуск продукции'"
                                       :header-title="'Выпуск продукции подразделением'"
                                       :objects="productions" :parent="asParent">Stocks</productions-table>
                  </div>
                </div>
                <div class="row note-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showCell" v-on:click="getCell">Складские остатки</span>
                    <stock-cell-table v-if="showCell" @element="hideElement('stockcell')" :header="'Складские остатки'"
                                      :downloading="downloading.cell"
                                      :header-title="'Текущие не нулевые остатки по складу, в том числе зарезервированные'"
                                      :objects="cells" :parent="asParent">Stock cells</stock-cell-table>
                  </div>
                </div>
                <div class="row content-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showOrder" v-on:click="getOrder">Размещенные заказы</span>
                    <order-table v-if="showOrder" @element="hideElement('order')" :header="'Размещенные заказы'"
                                 :downloading="downloading.order"
                                 :header-title="'Заказы на поставку размещенные в других компаниях'"
                                 :objects="orders" :parent="asParent">Orders</order-table>
                  </div>
                </div>
                <div class="row content-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showAccount" v-on:click="getAccount">Счета</span>
                    <accounts-table v-if="showAccount" @element="hideElement('account')" :header="'Счета'"
                                    :downloading="downloading.account"
                                    :entity-type="'account'"
                                    :header-title="'Счета и прочие расчетные источники'"
                                    :objects="accounts" :parent="asParent">Accounts</accounts-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Наименование:'" class="full-width"></input-row>

            <input-row v-model="object.legalShortName" :header="'Сокращенное юр. наименование:'" class="full-width"></input-row>

            <input-row v-model="object.legalFullName" :header="'Полное юр. наименование:'" class="full-width"></input-row>

            <input-row v-model="object.tax" :header="'Ставка НДС, %:'" :number="true" class="full-width"></input-row>

            <div class="row">
              <div class="col-md-6">
                <input-row v-model="object.email" :header="'e-mail:'" class="full-width"></input-row>
              </div>
              <div class="col-md-6">
                <input-row v-model="object.phone" :header="'тел:'" class="full-width"></input-row>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <input-row v-model="object.inn" :header="'ИНН:'" class="full-width"></input-row>
              </div>
              <div class="col-md-4">
                <input-row v-model="object.kpp" :header="'КПП:'" class="full-width"></input-row>
              </div>
              <div class="col-md-4">
                <input-row v-model="object.ogrn" :header="'ОГРН:'" class="full-width"></input-row>
              </div>
            </div>

            <input-row v-model="object.address" :header="'Юридический адрес:'" class="full-width"></input-row>

            <input-row v-model="object.postAddress" :header="'Почтовый адрес:'" class="full-width"></input-row>

            <input-row v-model="object.bank" :header="'Банк:'" class="full-width"></input-row>

            <div class="row">
              <div class="col-md-4">
                <input-row v-model="object.bik" :header="'БИК:'" class="full-width"></input-row>
              </div>
              <div class="col-md-4">
                <input-row v-model="object.account" :header="'р/с:'" class="full-width"></input-row>
              </div>
              <div class="col-md-4">
                <input-row v-model="object.koraccount" :header="'к/с:'" class="full-width"></input-row>
              </div>
            </div>

            <div class="row">
              <div class="col-3">
                <a class="input-title">
                  Автосборка:
                </a>
              </div>
              <div class="col-1">
                <input type="checkbox" id="scales" name="scales" v-model="object.autobuild" checked>
                <span style="margin-left: 10px">
                  {{object.autobuild ? 'Да' : 'Нет'}}
                </span>
              </div>
              <div class="col-3">
                <a class="input-title">
                  Приоритет данных кладовщика:
                </a>
              </div>
              <div class="col-1">
                <input type="checkbox" id="scales" name="scales" v-model="object.stockDataPriority">
                <span style="margin-left: 10px">
                  {{object.stockDataPriority ? 'Да' : 'Нет'}}
                </span>
              </div>
            </div>
            <br/>

            <edit-bottom @fullView="fullView" :extra-keys="[{name: 'Использовать данные компании', action: 'clone'}]"
                         @clone="cloneLegalData" @save="save">bottom</edit-bottom>
          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <stock-table :objects="selectPool" :header="'Склады'" @select="selectObjectFromTable"
                   :downloading="downloading.select"
                   @closeModal="closeModal"
                   v-bind:header-title="'Склады и подразделения'" :select="select"></stock-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import ItemCategorySelect from "../selectable/ItemCategorySelect";
import ContentsTable from "@/tables/ContentsTable";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import Production from "@/elements/Production";
import {
  ACCOUNT,
  CELL,
  COMPANY,
  DEPARTMENT,
  DOCUMENT,
  ITEM,
  LNK,
  ORDER,
  PLAN,
  PRODUCTION,
  STOCK
} from "@/repository/repository";

export default {
  name: 'stock',
  components: {
    Production,
    CompaniesTable,
    Table,
    ContentsTable,
    ItemCategorySelect
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      orders: [],
      showOrder: false,
      documents: [],
      showDocument: false,
      productions: [],
      showProduction: false,
      accounts: [],
      showAccount: false,
      cells: [],
      showCell: false,
      id: null,
      selectPool: [],
      asParent: {},
      viewKey: 0,
      flag: this.$flag,
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}, order: {state: false},
        document: {state: false}, production: {state: false}, account: {state: false}, cell: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    async getOrder() {
      this.showOrder = true;
      this.orders = await this.$repo.getCollection('stock/order/' + this.object.id, ORDER, this.downloading.order);
    },
    async getDocument() {
      this.showDocument = true;
      this.documents = await this.$repo.getCollection('stock/document/' + this.object.id, DOCUMENT, this.downloading.document);
    },
    async getProduction() {
      this.showProduction = true;
      this.productions = await this.$repo.getCollection('stock/production/' + this.object.id, PRODUCTION, this.downloading.production);
    },
    async getCell() {
      this.showCell = true;
      this.cells = await this.$repo.getCollection('stock/cell/' + this.object.id, CELL, this.downloading.cell);
    },
    async getAccount() {
      this.showAccount = true;
      this.accounts = await this.$repo.getCollection(ACCOUNT + '/stock/' + this.object.id, ACCOUNT, this.downloading.account);
    },
    hideElement(element) {
      if (element === ORDER) {
        this.showOrder = false;
        this.orders = [];
      } else if (element === DOCUMENT) {
        this.showDocument = false;
        this.documents = [];
      } else if (element === CELL) {
        this.showCell = false;
        this.cells = [];
      } else if (element === PRODUCTION) {
        this.showProduction = false;
        this.productions = [];
      } else if (element === ACCOUNT) {
        this.showAccount = false;
        this.accounts = [];
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    async cloneLegalData() {
      let id = this.object.contact ? this.object.contact.id
        : this.parent && [COMPANY, DEPARTMENT].includes(this.parent.type) ? this.parent.id : 0;
      if (id > 0) {
        let legalData = await this.$repo.justGetData('contact/ld/' + this.parent.id, this.downloading);
        for (let prop in legalData) {
          if (Object.prototype.hasOwnProperty.call(legalData, prop) && prop !== 'id' && prop !== 'description') {
            this.object[prop] = legalData[prop];
          }
        }
      }
    },
    save() {
      let url = 'stock/';
      if (this.parent && this.object.id === 0) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.$repo.uniSave(this, STOCK, url, this.downloading);
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    setImage(image) {
      this.object.image = image;
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      this.callModal();
      if (this.parent) {
        let url = 'stock/top/' + this.parent.type + '/' + this.parent.id;
        await this.$repo.getList(url, this.parent.type, this.selectPool, null, this.downloading.select);
      } else {
        await this.$repo.getSelectPoll(STOCK, this.selectBounds, this.selectPool, this.downloading.select);
      }
    },
    giveData(type) {
      if (type === LNK) {
        this.getDocument(false);
      }
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(STOCK, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: STOCK, id: this.object.id};
      }
    },
    state: function (value) {
      this.$repo.watchState(this, STOCK, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, STOCK, val);
    }
  }
}
</script>
<style>
</style>
s
