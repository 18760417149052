<template>
  <div>
    <verte menuPosition="bottom" v-model="object[field]" model="hex"></verte>
  </div>
</template>
<script>
  import verte from 'verte';
  import 'verte/dist/verte.css';
  export default {
    components: {
    verte,
    },
    props: {
      object: {},
      field: ''
    },
    data () {
      return {
        color: 0,
      }
    },
    methods: {
    },
    mounted: function () {
      this.color = this.object[this.field] ? this.object[this.field] : 0;
    }
  }
</script>
<style>
</style>
