<template>
    <select v-model="selected" v-on:mousedown="getCategories()">
      <option disabled value="">Категория</option>
      <option value="null">Все</option>
      <option v-for="(cate, index) in categories" :key="index" v-bind:value="cate"> {{ cate.name }}</option>
    </select>
</template>

<script>
import {authHeader} from "@/auth";
import {sorter} from '@/services'

export default {
  data () {
    return {
      key: authHeader(),
      categories: [],
      selected: null,
      sortBy: {key: 'name', asc: true},
      dataNotLoaded: true
    }
  },
  props: {
    category: {},
    field: null
  },
  methods: {
    async getCategories() {
      if (this.dataNotLoaded) {
        this.categories =[];
        await this.$repo.justGetList('item_category/all', this.categories);
        this.dataNotLoaded = false;
      }
    }
  },
  watch: {
    selected: function () {
      this.$emit('filter', {key: this.field, value: this.selected});
    }
  }
}
</script>

<style scoped>

</style>
