<template>
  <div class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object" class="row row-title" v-on:click="fullView()">
      <div class="col-9">
        {{ object.name }}
      </div>
      <div class="col-3">
        {{ object.counter }}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div v-if="object && object.id > 0" class="col-12">
        {{ asTextPreview }}
      </div>
      <div v-else class="col-12">
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Счетчик паспортов качества'" :select="select"
                                @editState="state = 'edit'"
                                @select="selectObject"
                                @regularState="state = start_state">
                Header
              </full-view-header>

              <div class="row">
                <div class="col-3">
                  Принадлежность к продукции:
                </div>
                <div class="col-9">
                  {{ object.name }}
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  Текущий номер:
                </div>
                <div class="col-9">
                  {{ object.counter }}
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object && object.id >= 0" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Принадлежность к продукции:'" class="full-width"></input-row>

            <input-row v-model="object.counter" :header="'Текущий номер:'" :number="true" class="full-width"></input-row>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <certificate-counter-table v-bind:objects="selectPool" v-bind:header="'Счетчики'" @select="selectObjectFromTable"
                                 :downloading="downloading.select"
                                 @closeModal="closeModal"
                                 v-bind:header-title="'Счетчики нумерации паспортв качества'" :select="select"></certificate-counter-table>
    </div>
  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import {CERTIFICATE_COUNTER, ITEM, NOTE} from "@/repository/repository";

export default {
  components: {
    Table
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      id: null,
      selectPool: [],
      asParent: {},
      showSelect: false,
      downloading: {state: false, select: {state: false}}
    }
  },
  computed: {
    asDate: function () {
      let date = new Date(this.object.date);
      return date.customFormat('#DD#/#MM#/#YYYY# #hh#:#mm#');
    },
    asTextPreview: function () {
      let text = '';
      if (this.object.name) {
        if (this.object.name.length > 60) {
          text = this.object.name.substring(0, 60) + '...';
        } else {
          text = this.object.name;
        }
      }
      return text;
    }
  },
  props: {
    parent: null,
    start: {},
    start_state: '',
    select: false,
    selectBounds: null
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    async fullView() {
      if (this.object.id > 0) {
        this.object = await this.$repo.getSingle(CERTIFICATE_COUNTER + '/' + this.object.id, CERTIFICATE_COUNTER, this.downloading);
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    async save() {
      let obj = await this.$repo.save(CERTIFICATE_COUNTER + '/', CERTIFICATE_COUNTER, this.object, this.downloading);
      if (obj) {
        this.$emit('updated', obj);
        if (this.start_state === 'edit') {
          this.start_state = 'row';
          this.state = 'row';
        } else {
          this.state = 'full';
        }
      }
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      await this.$repo.getSelectPoll(CERTIFICATE_COUNTER, null, this.selectPool, this.downloading.select);
      this.callModal()
    },
  },
  mounted: function () {
    this.state = this.start_state;
    this.object = this.start;
    this.id = this._uid;
    if (!this.object) {
      this.object = this.$repo.justGetData(CERTIFICATE_COUNTER + '/model', this.downloading);
    }
  },
  watch: {
    note: function () {
      this.asParent = {type: CERTIFICATE_COUNTER, id: this.object.id}
    }
  }
}
</script>
<style>
</style>
