<template>
  <div class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && category">
      <div class="col-12">
        <span v-on:click="selectCategory()" class="unselectable">
          <a class="row-title">
            {{ category.name }}
          </a>
        </span>
        <i class="nc-icon nc-settings-gear-64 float-right margin-right-icon" v-on:click="state = 'edit'"></i>
      </div>
    </div>

    <div v-if="state === 'edit' && category">
      <edit-header @fullView="fullView" @save="save">header</edit-header>

      <input-row v-model="category.name" :header="'Наименование:'" class="full-width"></input-row>

      <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

    </div>
  </div>
</template>
<script>

import {authHeader} from "@/auth";
import {CATEGORY, ITEM} from "@/repository/repository";

export default {
  name: 'item-category',
  data () {
    return {
      key: authHeader(),
      downloading: {stat: false, select: {state: false}},
    }
  },
  props: {
    category: {},
    state: ''
  },
  methods: {
    selectCategory() {
      this.$emit('category', this.category);
    },
    async rowView() {
      if (this.category.id > 0) {
        this.category = await this.$repo.getSingle('item_category/' + this.category.id, CATEGORY);
        this.state = 'row';
        this.$emit('update');
      } else {
        this.$emit('close');
      }
    },
    async save() {
      let category = await this.$repo.save('item_category/', CATEGORY, this.category, this.downloading);
      if (category) {
        this.category = category;
        this.state = 'row';
        this.$emit('updated', this.category);
      }
    }
  }
}
</script>
<style>
</style>
