import { render, staticRenderFns } from "./PlansLeftTable.vue?vue&type=template&id=86cc0550"
import script from "./PlansLeftTable.vue?vue&type=script&lang=js"
export * from "./PlansLeftTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports