<template>
  <div class="row">
    <div class="col-12">
      <card body-classes="table-full-width" style="margin: 5px">
        <template slot="header">

          <span v-if="!select && parent && parent.type === 'assembly'" class="float-right unselectable" v-on:click="addNew()">
            new
            <i class="nc-icon nc-simple-add"></i>
          </span>

          <span v-if="parent && parent.type === 'purchase'" class="float-right unselectable" v-on:click="getSelector()">
            добавить
            <i class="nc-icon nc-simple-add"></i>
          </span>

          <span v-if="multiselect" class="float-right unselectable" style="font-weight: bold" v-on:click="multiSelect">
            Выбрать выделенные
          </span>

          <h4 class="card-title unselectable" v-on:click="headerClick">{{ header }}</h4>
          <p class="card-category unselectable">{{ headerTitle }}</p>

        </template>
        <table class="table table-hover table-striped">
          <thead>
          <slot name="columns">
            <tr>
              <th class="row-title">
                <div class="row" style="width: 100%">
                  <div class="col-4 unselectable">
                        <span v-on:click="sortBy = {key: 'assembly.item.name', asc: !sortBy.asc}">
                          Запрошенная номенклатура
                        </span>
                  </div>
                  <div class="col-2 unselectable">
                        <span>
                          Потребность
                        </span>
                  </div>
                  <div class="col-2 unselectable" v-on:click="sortBy = {key: 'deadline', asc: !sortBy.asc}">
                        <span>
                          Срок поставки
                        </span>
                  </div>
                  <div class="col-2 unselectable" v-on:click="sortBy = {key: 'date', asc: !sortBy.asc}">
                        <span>
                          Размещена
                        </span>
                  </div>
                  <div class="col-2 unselectable">
                    <span v-on:click="status = status > 2 ? statusLow : (status + 1)">
                      Статус
                    </span>
                  </div>
                </div>
              </th>
            </tr>
          </slot>
          </thead>
          <tbody :key="tableKey">

          <tr v-if="newObj">
            <td>
              <enquire v-if="newObj.id === 0 && (!parent || parent.type !== 'purchase')"  :start_object="newObj" :start_state="'edit'" :default_state="'row'"
                       @close="cancelNew" :parent="parent" :select="select" @updated="addToList" @drop="drop(index)"></enquire>
              <enquire v-else-if="newObj.id === -1 && (parent && parent.type === 'purchase')"
                       :start_object="newObj"
                       :multiselect="true"
                       :start_state="'selector'" :default_state="'row'"
                       @close="cancelNew" :parent="parent" :select="true"
                       @select="addToList" @multiselect="addToParent"></enquire>
            </td>
          </tr>

          <tr v-for="(object, index) in objects" :key="index" v-if="fitFilter(object)">
            <td v-if="(!object.finished && status === 0 && !object.purchase)
              || (!object.finished && status === 1 && object.purchase)
              || (object.finished && status === 2) || status === 3 || object.id < 1">

              <enquire v-if="object.id > 0" :start_object="object" :start_state="'row'" :default_state="'row'"
                       :select="select" :select_modal="select_modal"
                       :updateview="tableKey + 1" :parent="parent"
                       @select="selectObject"
                       @multiselect="addToSelection"
                       @removeselect="removeFromSelection"
                       @updated="addToList" @drop="drop(index)"></enquire>
            </td>
          </tr>
          <tr v-if="downloading && downloading.state">
            <downloading :downloading="downloading">
              Downloading
            </downloading>
          </tr>
          </tbody>
        </table>
      </card>
    </div>
  </div>
</template>
<script>
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter, addToList} from '@/services'
import {ENQUIRE, PURCHASE} from "@/repository/repository";

export default {
  components: {
    LTable,
    Card
  },
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'date', asc: true},
      filter: new Map,
      tableKey: 0,
      status: 0,
      statusLow: 0,
      newObj: null,
      selection: {}
    }
  },
  props: {
    objects: [],
    header: null,
    headerTitle: null,
    select: false,
    multiselect: false,
    parent: null,
    select_modal: false,
    downloading: null,
  }
  ,
  methods: {
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    async addNew() {
      if (!this.newObj) {
        let url = 'enquire/model';
        if (this.parent) {
          url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
        }
        this.newObj = await this.$repo.getSingle(url, 'enquire', this.downloading);
        this.tableKey += 1;
      }
    },
    getSelector() {
      if (!this.newObj) {
        this.newObj = {id:-1};
        this.tableKey += 1;
        }
    },
    addToList(object) {
      addToList(object, this);
    },
    cancelNew() {
      this.newObj = null;
    },
    drop(index) {
      if (index === 0) {
        this.objects.shift();
      } else if (index === this.objects.length - 1) {
        this.objects.pop();
      } else {
        for (let i = index; i < this.objects.length - 1; i++) {
          this.objects[i] = this.objects[i + 1];
        }
        this.objects.pop();
      }
    },
    selectObject(object) {
      this.$emit('select', object);
    },
    multiSelect() {
      let rsl = [];
      this.objects.forEach(obj => {
        if (this.selection[obj.id]) {
          rsl.push(obj.id);
        }
      })
      this.$emit('multiselect', rsl);
    },
    addToSelection(object) {
      this.selection[object.id] = true;
    },
    removeFromSelection(object) {
      this.selection[object.id] = false;
    },
    async addToParent(idList) {
      let rsl = [];
      if (this.parent && this.parent.type === PURCHASE) {
        rsl = await this.$repo.post(`${ENQUIRE}/add/${this.parent.id}`, idList, this.downloading);
      }
      if (rsl && rsl.length > 0) {
        this.objects.push(...rsl);
        this.tableKey++;
      }
    },
    headerClick() {
      if (this.select) {
        this.$emit('closeModal');
      } else {
        this.$emit('element');
        this.$emit('update');
      }
      this.$emit('element');
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    },
    objects: function (){
      this.tableKey += 1;
    },
    status: function (){
      this.tableKey += 1;
    }
  },
  mounted() {
    if (this.parent) {
      if (this.parent.type === 'purchase') {
        this.status = 1;
        this.statusLow = 1;
      } else if (this.parent.type === 'assembly') {
        this.status = 3;
      }
    }

  }
}
</script>
<style>
</style>
