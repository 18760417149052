<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card body-classes="table-full-width" style="margin: 5px">
          <div class="row">
            <div class="col-8">
              <base-input v-model="textSearch" style="margin-left: 15px; width: 80%;"></base-input>
            </div>
            <div class="col-4 unselectable">
            </div>
          </div>
          <template slot="header">
              <span v-if="!select" class="float-right unselectable" v-on:click="addNew()">
                new
                <i class="nc-icon nc-simple-add"></i>
              </span>
            <h4 class="card-title unselectable" v-on:click="headerClick()">{{ header }}</h4>
            <p class="card-category unselectable">{{ headerTitle }}</p>
          </template>
          <table class="table table-hover table-striped">
            <thead>
            <slot name="columns">
              <tr>
                <th class="row-title">
                  <div class="row" style="width: 100%">
                    <div class="col-5 unselectable">
                        <span v-on:click="sortBy = {key: 'buyer.name', asc: !sortBy.asc}">
                          Покупатель
                        </span>
                    </div>
                    <div class="col-3 unselectable">
                        <span v-on:click="sortBy = {key: 'seller.name', asc: !sortBy.asc}">
                          Поставщик
                        </span>
                    </div>
                    <div class="col-2 unselectable">
                        <span v-on:click="sortBy = {key: 'date', asc: !sortBy.asc}">
                          Дата
                        </span>
                    </div>
                    <div class="col-2 unselectable">
                    </div>
                  </div>
                </th>
              </tr>
            </slot>
            </thead>
            <tbody :key="tableKey">
            <tr v-if="newObj">
              <td>
                <quotation :start_object="newObj" :start_state="'edit'" :default_state="'row'"
                          @close="cancelNew" :parent="parent" :select="select" @updated="addToList"></quotation>
              </td>
            </tr>
            <tr v-for="(object, index) in objects" :key="index" v-if="fitFilter(object)">
              <td>
                <quotation v-if="object.id > 0" :start_object="object" :start_state="'row'" :default_state="'row'"
                          :select="select" @select="selectObject" @updated="addToList"></quotation>
              </td>
            </tr>
            <tr v-if="downloading && downloading.state">
              <downloading :downloading="downloading">
                Downloading
              </downloading>
            </tr>
            </tbody>
          </table>
        </card>
      </div>
    </div>

  </div>
</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter, addToList, textContainsText} from '@/services'
import {QUOTATION} from "@/repository/repository";

export default {
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'name', asc: true},
      filter: new Map,
      tableKey: 0,
      id: null,
      selectPool: null,
      showModal: false,
      newObj: null,
      textSearch: '',
    }
  },
  props: {
    objects: [],
    header: null,
    headerTitle: null,
    select: false,
    parent: null,
    callSelect: false,
    downloading: null,
  }
  ,
  methods: {
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter) &&
        (textContainsText(object.buyer.name + (object.search ? object.search : ""), this.textSearch));
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    async addNew() {
      if (!this.newObj) {
        let url = `${QUOTATION}/model`;
        if (this.parent) {
          url = `${url}?type=${this.parent.type}&id=${this.parent.id}`;
        }
        this.newObj = await this.$repo.justGetData(url, this.downloading);
      }
    },
    addToList(object) {
      addToList(object, this);
    },
    cancelNew() {
      this.newObj = null;
    },
    selectObject(object) {
      this.$emit('select', object);
    },
    headerClick() {
      if (this.select) {
        this.$emit('closeModal');
      } else {
        this.$emit('element');
        this.$emit('update');
      }
    },
    callModal() {
      let modal = document.getElementById(this.id);
      this.showModal = true;
      modal.style.display = "block";
      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
          this.showModal = false;
        }
      }
    },
    closeModal() {
      let modal = document.getElementById(this.id);
      this.showModal = false;
      modal.style.display = "none";
    },
    async openSelectModal() {
      this.selectPool = [];
      if (this.parent) {
        let url = `${QUOTATION}/${this.parent.type}/${this.parent.id}`;
        await this.$repo.getList(url, QUOTATION, this.selectPool, this.downloading);
        this.callModal();
      }
    },
    selectObjectFromTable(object) {
      this.$emit("add", object);
      this.closeModal();
    },
  },
  watch: {
    sortBy: function () {
      this.sort()
    },
    objects: function (){
      this.tableKey += 1;
    },
    callSelect: function () {
      if (this.callSelect) {
        this.openSelectModal();
        this.$emit('callSelectBack');
      }
    }
  },
  mounted() {
    this.id = this._uid;
  }
}
</script>
<style>
</style>
