<template>
  <div class="row">
    <div class="col-12">
      <card body-classes="table-full-width" style="margin: 5px">
        <base-input v-model="textSearch" style="margin-left: 15px; width: 50%;"></base-input>
        <template slot="header">
          <h4 class="card-title unselectable" v-on:click="headerClick()">{{ header }}</h4>
          <p class="card-category unselectable">{{ headerTitle }}</p>
        </template>
        <table class="table table-hover table-striped">
          <thead>
          <slot name="columns">
            <tr>
              <th class="row-title">
                <div v-if="realStock" class="row" style="width: 100%">
                  <div class="col-4 unselectable">
                        <span v-on:click="sortBy = {key: 'item.name', asc: !sortBy.asc}">
                          Номенклатура
                        </span>
                    <item-category @filter="catchFilter" v-bind:field="'item.category'"> Category Selector </item-category>
                  </div>
                  <div class="col-5 unselectable">
                        <span v-on:click="sortBy = {key: 'stock.contact.name', asc: !sortBy.asc}">
                          Место хранения
                        </span>
                  </div>
                  <div class="col-3 unselectable">
                        <span>
                          Доступно / Резерв / Склад
                        </span>
                  </div>
                </div>

                <div v-else class="row" style="width: 100%">
                  <div class="col-12 unselectable">
                        <span v-on:click="sortBy = {key: 'item.name', asc: !sortBy.asc}">
                          Номенклатура
                        </span>
                    <item-category @filter="catchFilter" v-bind:field="'item.category'"> Category Selector </item-category>
                  </div>
                </div>

              </th>
            </tr>
          </slot>
          </thead>
          <tbody :key="tableKey">
          <tr v-for="(object, index) in objects" :key="index" v-if="fitFilter(object)">
            <td>
              <stock-cell v-if="realStock" :start_object="object" :start_state="'row'" :default_state="'row'"
                        :select="select" @select="selectObject"></stock-cell>
              <stock-cell v-else :start_object="object" :start_state="'row_light'" :default_state="'row_light'"
                          :select="select" @select="selectObject"></stock-cell>
            </td>
          </tr>
          <tr v-if="downloading && downloading.state">
            <downloading :downloading="downloading">
              Downloading
            </downloading>
          </tr>
          </tbody>
        </table>
      </card>
    </div>
  </div>
</template>
<script>
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import {authHeader} from "@/auth";
import Item from "../elements/Item";
import {sorter, fitFilter, updateFilter, textContainsText} from '@/services'

export default {
  components: {
    LTable,
    Card
  },
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'item.name', asc: true},
      filter: new Map,
      tableKey: 0,
      realStock: true,
      textSearch: ''
    }
  },
  props: {
    objects: [],
    header: null,
    headerTitle: null,
    select: false,
    parent: null,
    downloading: null,
  }
  ,
  methods: {
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter) && textContainsText(object.item.name, this.textSearch);
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    selectObject(object) {
      this.$emit('select', object);
    },
    headerClick() {
      if (this.select) {
        this.$emit('closeModal');
      } else {
        this.$emit('element');
        this.$emit('update');
      }
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    },
    objects: function () {
      if (this.objects && this.objects.length > 0) {
        if (this.objects[0].id > 0) {
          this.realStock = true;
        } else {
          this.realStock = false;
        }
      }
      this.tableKey++;
    }
  }
}
</script>
<style>
</style>
