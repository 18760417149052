<template>
  <div class="content">
    <div class="container-fluid">
      <h4 class="card-title unselectable">
        Отчеты
      </h4>
      <br/>

      <div class="row">
        <div class="col-12">
          <h5 class="card-title unselectable" v-on:click="getPBCReport">
            Реализации по категориям
          </h5>
          <downloading v-if="downloading.pbc && downloading.pbc.state" :single="true" :downloading="downloading.pbc">
            Downloading
          </downloading>
          <div v-else :key="pbcKey">
            <a v-for="(object, index) in pbcCategories" :key="index">
              {{object.name}}
              <i class="nc-icon nc-simple-remove" v-on:click="removePBCCategory(index)"></i>
            </a>
          </div>
          <br/>
          <div class="row">
            <div class="col-1">
              Добавить
            </div>
            <div class="col-11">
              <item-category-select v-bind:category="pbcCategory" @category="addPBCCategory"> Добавить </item-category-select>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 40px">
        <div class="col-12">
          <h5 class="card-title unselectable" v-on:click="getPLCReport">
            К отгрузке по категориям
          </h5>
          <downloading v-if="downloading.plc && downloading.plc.state" :single="true" :downloading="downloading.plc">
            Downloading
          </downloading>
          <div v-else :key="plcKey">
            <a v-for="(object, index) in plcCategories" :key="index">
              {{object.name}}
              <i class="nc-icon nc-simple-remove" v-on:click="removePLCCategory(index)"></i>
            </a>
          </div>
          <br/>
          <div class="row">
            <div class="col-1">
              Добавить
            </div>
            <div class="col-11">
              <item-category-select v-bind:category="plcCategory" @category="addPLCCategory"> Добавить </item-category-select>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'
import ItemsTable from "../tables/ItemsTable";
import {getList} from "@/repository/repository";

export default {
  name: 'employees',
  components: {
    ItemsTable,
  },
  data () {
    return {
      objects: [],
      key: authHeader(),
      sortBy: {key: 'name', asc: true},
      filter: new Map,
      tableKey: 0,
      pbcCategories: [],
      pbcCategory: {},
      pbcKey: 0,
      plcKey: 0,
      plcCategories: [],
      plcCategory: {},
      downloading: {plc: {state: false}, pbc: {state: false}}
    }
  },
  methods: {
    async getObjects() {
      this.objects = [];
      await this.$repo.getList('employee/all', 'employee', this.objects);
      this.sort();
    },
    sort() {
      sorter(this.objects, this.sortBy);
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    removePBCCategory(index) {
      this.pbcCategories.splice(index, 1);
      this.pbcKey++;
    },
    addPBCCategory(category) {
      this.pbcCategories.push(category);
      this.pbcKey++;
    },
    async getPBCReport() {
      await this.$repo.postWithData('report/pbc', this.pbcCategories, 'report.xls', this.downloading.pbc);
    },
    removePLCCategory(index) {
      this.plcCategories.splice(index, 1);
      this.plcKey++;
    },
    addPLCCategory(category) {
      this.plcCategories.push(category);
      this.plcKey++;
    },
    async getPLCReport() {
      await this.$repo.postWithData('report/plc', this.plcCategories, 'report.xls', this.downloading.plc);
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    }
  },
  created() {
    this.getObjects();
  }
}
</script>
<style>
</style>
