<template>


  <div class="row">
    <input v-if="date"
           :value="dateData"
           type="date"
           v-bind="$attrs"
           v-on="listeners"
           class="input-sm full-width"
           aria-describedby="addon-right addon-left">
    <input v-if="moment"
           :value="dateData"
           type="date"
           v-bind="$attrs"
           v-on="listeners"
           class="input-sm full-width"
           aria-describedby="addon-right addon-left">
    <input v-else
           :value="value"
           v-bind="$attrs"
           v-on="listeners"
           class="input-sm full-width"
           aria-describedby="addon-right addon-left">
  </div>

</template>
<script>
import moment from "moment";

export default {
  inheritAttrs: false,
  name: "base-input",
  props: {
    value: {
      type: [String, Number],
      description: "Input value",
      default: ""
    },
    date: false,
    moment: false,
    number: false
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      dateData: null,
      digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    }
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
        keyup: this.onKey
      }
    }
  },
  methods: {
    onInput(evt) {
      if (this.date) {
        let date = new Date(evt.target.value);
        this.dateData = date.toISOString().slice(0, 10);
        this.$emit('input', date.getTime());
      } else if (this.moment) {
        let date = new Date(evt.target.value);
        this.dateData = date.toISOString().slice(0, 10);
        this.$emit('input', this.dateData);
      } else {
        let value = evt.target.value;
        if (this.number && !this.digits.includes(evt.data)) {
          value = value.slice(0, -1);
          evt.target.value = value;
          if (!value.includes('.')) {
            value += '.';
          }
        }
        this.$emit('input', value);
      }
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    onKey(evt) {
      if (this.focused) {
        if (evt.keyCode === 13) {
          this.$emit('enter');
        } else if (evt.keyCode === 27) {
          this.$emit('esc');
        }
      }
    }
  },
  mounted() {
    if (this.date) {
      if (this.value && this.value > 0) {
        this.dateData = new Date(this.value).toISOString().slice(0, 10);
      } else {
        this.dateData = new Date().toISOString().slice(0, 10);
        this.$emit('input', new Date().getTime());
      }
    } else if (this.moment) {
      if (this.value && this.value.length > 0) {
        this.dateData = new Date(this.value).toISOString().slice(0, 10);
      } else {
        this.dateData = new Date().toISOString().slice(0, 10);
        this.$emit('input', this.dateData);
      }
    }else if (!this.value) {
      this.$emit('input', this.number ? 0 : '');
    }
  }
}
</script>
<style>

</style>
