<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card body-classes="table-full-width" style="margin: 5px">
          <template slot="header">
            <h4 class="card-title unselectable" v-on:click="hide()">{{ header}}</h4>
            <p class="card-category">{{ headerTitle }}</p>
          </template>
          <table class="table table-hover table-striped">
            <thead>
            <slot name="columns">
              <tr>
                <th class="row-title">
                  <div class="row" style="width: 100%">
                    <div class="col-4 unselectable">
                        <span v-on:click="sortBy = {key: 'item.name', asc: !sortBy.asc}">
                          Наименование
                        </span>
                    </div>
                    <div class="col-4 unselectable">
                        <span v-on:click="sortBy = {key: 'item.category.name', asc: !sortBy.asc}">
                          Категория
                        </span>
                    </div>
                    <div class="col-2 unselectable">
                      Количество
                    </div>
                    <div class="col-2 unselectable">
                      Единица измерения
                    </div>
                  </div>
                </th>
              </tr>
            </slot>
            </thead>
            <tbody :key="tableKey">
            <tr v-for="(object, index) in objects" :key="index">
              <td>
                <supplier-request :object="object"></supplier-request>
              </td>
            </tr>
            <tr v-if="downloading && downloading.state">
              <downloading :downloading="downloading">
                Downloading
              </downloading>
            </tr>
            </tbody>
          </table>
        </card>
      </div>
    </div>
    <br/>
    <div class="row margin-for-inner-obj">
      <company :start_object="request.department" :start_state="'text'" :default_state="'text'"
               :select="true" @select="setDepartment" :header="'Заказчик:'"
               :parent="parent"> Consumer </company>
    </div>
    <br/>
    <div class="row">
      <div class="col-md-6">
        <button class="btn btn-light btn-fill btn-sm margin-right-icon" style="margin: 5px" v-on:click="getContacts">
          Подобрать поставщиков
        </button>
      </div>
      <div class="col-md-6">
        <button v-if="contacts" class="btn btn-light btn-fill btn-sm float-right margin-right-icon"
                style="margin: 5px" v-on:click="sendRequest">
          Отправить запросы
        </button>
      </div>
    </div>
    <div v-if="contacts" class="row">
      <div class="col-md-12">
        <companies-table :objects="contacts" :header="'Поставщики'" :add_mode="true"
                         :header-title="'Список поставщиков для отправки запроса'"
                         @add="addSupplier">
          Suitable suppliers list
        </companies-table>
      </div>
    </div>
  </div>
</template>
<script>

import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'
import CompaniesTable from "@/tables/CompaniesTable";

export default {
  name: 'contents-table',
  components: {CompaniesTable},
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'item.name', asc: true},
      filter: new Map,
      tableKey: 0,
      contacts: null,
      request: {department: null, purchaseId: this.parent.id, contents: this.objects, suppliers: this.contacts}
    }
  },
  props: {
    objects: null,
    header: null,
    headerTitle: null,
    parent: null,
    downloading: null,
  }
  ,
  methods: {
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    hide() {
      this.$emit('element');
    },
    async getContacts() {
      this.contacts = [];
      let url = 'purchase/suppliers'
      this.contacts = await this.$repo.save(url, null, this.objects, this.downloading);
      this.request.suppliers = this.contacts;
    },
    addSupplier(company) {
      this.contacts.unshift(company);
    },
    async sendRequest() {
      if (this.request.suppliers.length > 0 && this.request.department) {
        await this.$repo.save('purchase/request', null, this.request, this.downloading);
      }
    },
    setDepartment(company) {
      this.request.department = company;
    }
  },
  watch: {
    sortBy: function () {
     // this.sort()
    },
    contacts: function () {
      this.tableKey += 1;
    }
  }
}
</script>
<style>
</style>
