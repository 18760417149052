<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object" class="row row-title" v-on:click="fullView()">
      <div class="col-4">
        {{ object.item.name }}
      </div>
      <div class="col-4">
        {{ object.item.category.name }}
      </div>
      <div class="col-2">
        {{ object.value }}
      </div>
      <div class="col-2">
        {{ object.item.measure }}
      </div>
    </div>

    <div v-if="state === 'full' && object" class="full-title">
      <full-view-header :header="'Комплектация'" :select="select"
                        @regularState="state = 'row'"
                        @editState="state = 'edit'">
        Header
      </full-view-header>

      <item v-bind:start_object="object.item" v-bind:start_state="'text'" class="item-bcg"> Item </item>

      <div class="row">
        <div class="col-3">
          Количество:
        </div>
        <div class="col-9">
          {{ object.value }}
        </div>
      </div>
    </div>

    <div v-if="state === 'edit' && object">
      <edit-header @fullView="fullView" @save="save">header</edit-header>

      <item v-if="object.item" v-bind:start_object="object.item"
            v-bind:start_state="'text'" v-bind:select="true" @select="setItem"> Item </item>
      <item v-else v-bind:start_state="'text'" v-bind:select="true" @select="setItem"> Item </item>

      <input-row v-model="object.value" :header="'Количество:'" :number="true" class="full-width"></input-row>

      <edit-bottom v-if="!inSave" @fullView="fullView" @save="save">bottom</edit-bottom>

    </div>
  </div>
</template>
<script>

import {authHeader} from "@/auth";
import ItemCategorySelect from "../selectable/ItemCategorySelect";
import {userService} from "@/services";
import {CONTENT, ITEM} from "@/repository/repository";

export default {
  name: 'item-content',
  components: {
    ItemCategorySelect
  },
  data () {
    return {
      key: authHeader(),
      inSave: false,
      object: {},
      state: '',
      viewKey: 0,
      flag: this.$flag,
      downloading: {state: false, select: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    parent: null
  },
  methods: {
    async fullView(id) {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', id);
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    onClickSelect(category) {
      this.item.category = category;
    },
    save() {
      let url = 'content/';
      if (this.parent) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.object.value = this.round(this.object.value);
      this.$repo.uniSave(this, CONTENT, url, this.downloading);
      this.object.value = this.round(this.object.value);
    },
    setItem(item) {
      this.object.item = item;
    },
    round(val) {
      val = (Math.round(val * 1000) / 1000);
      return val % 1 === 0 ? val : val.toFixed(3);
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(CONTENT, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    //this.access = userService.userHasAccess('ADMIN');
    if (!this.object) {
      this.object = await this.$repo.justGetData('content/model', this.downloading);
    }
    this.object.value = this.round(this.object.value);
  },
  watch: {
    state: function (value) {
      this.$repo.watchState(this, CONTENT, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, CONTENT, val);
    }
  }
}
</script>
<style>
</style>
