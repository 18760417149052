<template>
      <div class="row">
        <div class="col-12">
          <card body-classes="table-full-width" style="margin: 5px">
            <template slot="header">
              <span class="float-right unselectable">
                <item-category-select @category="addToList" :add_mode="true"> categories </item-category-select>
              </span>
              <h4 class="card-title unselectable" v-on:click="hide()">{{ header}}</h4>
              <p class="card-category">{{ headerTitle }}</p>
            </template>
            <table class="table table-hover table-striped">
              <thead>
              <slot name="columns">
                <tr>
                  <th class="row-title">
                    <div class="row" style="width: 100%">
                      <div class="col-10 unselectable">
                        <span v-on:click="sortBy = {key: 'item.name', asc: !sortBy.asc}">
                          Наименование
                        </span>
                      </div>
                      <div class="col-2 unselectable">
                        <span>
                          Удалить
                        </span>
                      </div>
                    </div>
                  </th>
                </tr>
              </slot>
              </thead>
              <tbody :key="tableKey">
              <tr v-for="(object, index) in objects" :key="index">
                <td>
                  <div class="row full-width">
                    <div class="col-md-10">
                      {{object.name}}
                    </div>
                    <div class="col-md-2">
                      <span v-on:click="removeFromList(object, index)">
                        УДАЛИТЬ
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-if="downloading && downloading.state">
                <downloading :downloading="downloading">
                  Downloading
                </downloading>
              </tr>
              </tbody>
            </table>
          </card>
        </div>
      </div>
</template>
<script>

import {sorter, fitFilter, updateFilter} from '@/services'

export default {
  data () {
    return {
      sortBy: {key: 'item.name', asc: true},
      filter: new Map,
      tableKey: 0
    }
  },
  props: {
    objects: null,
    header: null,
    headerTitle: null,
    parent: null,
    downloading: null,
  }
  ,
  methods: {
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    addToList(object) {
      this.$emit('add', object);
    },
    hide() {
      this.$emit('element');
    },
    removeFromList(object, index) {
      this.$emit('remove', {object: object, index: index});
    }
  },
  watch: {
    sortBy: function () {
     // this.sort()
    },
    objects: function () {
      this.tableKey += 1;
    }
  }
}
</script>
<style>
</style>
