<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.item" class="row row-title" v-on:click="fullView()">
      <div class="col-4">
        {{ object.item.name }}
      </div>
      <div class="col-2">
        {{ (object.value % 1 === 0 ? object.value : (Math.round(object.value * 1000) / 1000).toFixed(3)) + ' ' + object.item.measure}}
      </div>
      <div v-if="object.tax" class="col-2">
        {{ (Math.round(object.price * 100) / (100 + object.tax)).toFixed(2) + " / " + (Math.round(object.price * 100) / 100).toFixed(2)}}
      </div>
      <div v-else class="col-2">
        {{ (Math.round(object.price * 100) / 100).toFixed(2) }}
      </div>
      <div class="col-2">
        {{ (Math.round(object.price * object.value * 100) / 100).toFixed(2) }}
      </div>
      <div class="col-1">
        {{ toDate(object.quotation.date) }}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ `${object.item.name} - ${object.value}, ${object.item.measure}` }}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Предлагаемая позиция'" :select="select"
                                :historical="true"
                                @history="irc.controlHistory()"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="irc.startEdit()" @select="selectObject">
                Header
              </full-view-header>

              <div class="row item-bcg margin-for-inner-obj">
                <item :start_object="object.item" :header="'Номенклатура'"
                            :start_state="'text'" :default_state="'text'"></item>
              </div>

              <row v-if="object.requested" :header="'Запрашиваемое наименование'" :value="object.requested"> Requested </row>

              <row :header="'Количество, ' + object.item.measure + ':'" :value="object.value" :as-value="true"> Value </row>

              <row v-if="object.tax > 0" :header="'Цена без налога:'" :value="object.price / (1 + object.tax / 100)" :as-money="true"> Price </row>

              <row :header="'Цена:'" :value="object.price" :as-money="true"> Price </row>

              <row :header="'Сумма:'" :value="object.price * object.value" :as-money="true"> Sum </row>

              <row :header="'Налог:'" :value="object.tax" :as-money="true"> Tax </row>

              <div v-if="parent && (parent.type !== 'quotation')" class="row document-bcg margin-for-inner-obj">
                <quotation :start_object="object.quotation" :header="'Предложение'"
                          :start_state="'text'" :default_state="'text'"></quotation>
              </div>

              <row v-if="object.comment" :header="'Комментарий'" :value="object.comment"> Requested </row>

              <div class="row full-width full-title">
                <div class="col-12 unselectable">
                  <div v-if="history.length === 0">
                    <span style="margin-right: 15px">История предложений и поставок</span>
                    <button class="btn btn-light btn-fill btn-usm margin-right-icon margin-top-bottom" v-on:click="historyByAnalogue">
                      Подобрать по аналогу
                    </button>
                    <button class="btn btn-light btn-fill btn-usm margin-right-icon margin-top-bottom" v-on:click="historyBySample">
                      Подобрать по номенклатуре
                    </button>
                  </div>
                  <item-sale-history-table v-if="history.length > 0 || downloading.saleHistory.state"
                                           @element="history = []" :header="'История предложений'" :key="historyKey"
                                           :downloading="downloading.saleHistory"
                                           :header-title="`История предложений по позиции ${object.item.name}`"
                                           :objects="history">QuotationElements</item-sale-history-table>
                </div>
              </div>

              <event-table v-if="irc.history.show && irc.history.data"
                           :objects="irc.history.data" :downloading="downloading.history"></event-table>

            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>
            <div v-if="parent" class="row margin-for-inner-obj">
              <item :start_object="object.item" :start_state="'text'" :default_state="'text'"
                       :select="true" @select="setItem" :header="'Номенклатура:'" :parent="parent"> Stock cell </item>
            </div>

            <input-row v-model="object.requested" :header="'Запрашиваемое наименование:'" class="full-width" ></input-row>

            <input-row v-model="object.value" :header="'Количество:'" :number="true" class="full-width" ></input-row>

            <input-row v-model="priceTaxFree" :header="'Цена без налога:'" :number="true" class="full-width" ></input-row>

            <input-row v-model="object.price" :header="'Цена:'" :number="true" class="full-width" ></input-row>

            <input-row v-model="object.tax" :header="'Налог:'" :number="true" class="full-width" ></input-row>

            <input-row v-model="object.comment" :header="'Комментарий:'" class="full-width" ></input-row>

            <div class="row full-width full-title">
              <div class="col-12 unselectable">
                <div v-if="history.length === 0">
                  <span style="margin-right: 15px">История предложений и поставок</span>
                  <button class="btn btn-light btn-fill btn-usm margin-right-icon margin-top-bottom" v-on:click="historyByAnalogue">
                    Подобрать по аналогу
                  </button>
                  <button class="btn btn-light btn-fill btn-usm margin-right-icon margin-top-bottom" v-on:click="historyBySample">
                    Подобрать по номенклатуре
                  </button>
                </div>
                <item-sale-history-table v-if="history.length > 0 || downloading.saleHistory.state"
                                         @element="history = []" :header="'История предложений'" :key="historyKey"
                                         :downloading="downloading.saleHistory"
                                         :header-title="`История предложений по позиции ${object.item.name}`"
                                         :objects="history">QuotationElements</item-sale-history-table>
              </div>
            </div>


            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>

            <br/>
            <br/>


          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <quotation-table v-bind:objects="selectPool" v-bind:header="'Позиции по документам'" @select="selectObjectFromTable"
                       :downloading="downloading.select"
                       @closeModal="closeModal" :parent="parent"
                       v-bind:header-title="'Позициий в документе с количеством и ценой'" :select="select"></quotation-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import {CERTIFICATE, ITEM, PORTION, QUOTATION_ELEMENT, RECEIPT} from "@/repository/repository";
import Quotation from "@/elements/Quotation";
import {ElementInterface} from "@/models/elementInteface";

export default {
  components: {
    Quotation,
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      id: null,
      selectPool: [],
      asParent: {},
      moneyValue: 0,
      priceTaxFree: 0,
      viewKey: 0,
      history: [],
      flag: this.$flag,
      historyKey: 100000,
      irc: null,
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}, saleHistory: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    save() {
      let url = `${QUOTATION_ELEMENT}/`;
      if (this.parent && this.object.id === 0) {
        url = `${url}?type=${this.parent.type}&id=${this.parent.id}`;
      }
      this.$repo.uniSave(this, QUOTATION_ELEMENT, url, this.downloading);
    },
    hideElement(element) {
      if (element === RECEIPT) {
        this.showReceipt = false;
        this.receipts = [];
      }
    },
    async historyByAnalogue() {
      if (this.object.requested && this.object.requested.length > 1) {
        this.history = await this.$repo.justGetData(`${QUOTATION_ELEMENT}/data?analogue=${this.object.requested}`, this.downloading.saleHistory);
        this.historyKey++;
      }
    },
    async historyBySample() {
      if (this.object.item && this.object.item.id > 0) {
        this.history = await this.$repo.justGetData(`${QUOTATION_ELEMENT}/data/${this.object.item.id}`, this.downloading.saleHistory);
        this.historyKey++;
      }
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    setItem(item) {
      this.object.item = item;
    },
    toDate: function (data) {
      let date = new Date(data);
      return date.customFormat('#DD#/#MM#/#YYYY#');
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      if (this.parent) {
        await this.$repo.justGetList(`${QUOTATION_ELEMENT}/?type=${this.parent.type}&id=${this.parent.id}`, this.selectPool, null, this.downloading.select);
      } else {
        await this.$repo.justGetList('portion/', this.selectPool, null, this.downloading.select);
      }
      this.callModal();
    },
  },
  mounted: async function () {
    this.irc = new ElementInterface(this, QUOTATION_ELEMENT, this.downloading.history);
    this.state = this.start_state;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(QUOTATION_ELEMENT, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (this.object.tax > 0) {
      this.priceTaxFree = this.object.price / (1 + this.object.tax / 100);
    }
  },
  watch: {
    object: function () {
      if (this.object && this.object.id) {
        this.asParent = {type: QUOTATION_ELEMENT, id: this.object.id};
      }
      if (this.state === 'edit') {
        this.moneyValue = this.object.price * this.object.value;
      }
    },
    priceTaxFree: function () {
      if (!this.object.price > 0) {
        this.object.tax = 20;
      }
      this.object.price = this.priceTaxFree * (1 + this.object.tax / 100);
    },
    moneyValue: function () {
      this.object.price = this.moneyValue / this.object.value;
    },
    state: async function (value) {
      this.$repo.watchState(this, QUOTATION_ELEMENT, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, QUOTATION_ELEMENT, val);
    },
  }
}
</script>
<style>
</style>
