<template>
  <div class="row" :key="viewKey">
    <div class="col-12">
      <card body-classes="table-full-width" style="margin: 5px">
        <template slot="header">
              <span v-if="!select && parent && (parent.type === 'assembly' || parent.type === 'stockcell')" class="float-right unselectable" v-on:click="addNew()">
                new
                <i class="nc-icon nc-simple-add"></i>
              </span>
          <h4 class="card-title unselectable" v-on:click="headerClick()">{{ header }}</h4>
          <p class="card-category unselectable">{{ headerTitle }}</p>
        </template>
        <table class="table table-hover table-striped">
          <thead>
          <slot name="columns">
            <tr>
              <th class="row-title">
                <div class="row" style="width: 100%">
                  <div class="col-4 unselectable">
                        <span v-on:click="sortBy = {key: 'cell.item.name', asc: !sortBy.asc}">
                          Номенклатура
                        </span>
                  </div>
                  <div class="col-2 unselectable">
                        <span>
                          Количество
                        </span>
                  </div>
                  <div class="col-6 unselectable">
                    <span v-if="!groupBy || groupBy ==='cell'" v-on:click="sortBy = {key: 'cell.stock.contact.name', asc: !sortBy.asc}">
                      Склад, подразделение
                    </span>
                    <span v-else-if="groupBy && groupBy === 'assembly'">
                      Зарезервировано под
                    </span>
                  </div>
                </div>
              </th>
            </tr>
          </slot>
          </thead>
          <tbody :key="tableKey">
          <tr v-if="newObject">
            <reserve :start_object="newObject" :start_state="'edit'" :default_state="'row'" @close="cancelNew"
                     :parent="parent" @updated="addToList"> new </reserve>
          </tr>
          <tr v-if="groupBy" v-for="(key, index) in Object.keys(groups)" :key="index">
            <reserve-grouped v-if="groupBy" :start_object="groups[key]"
                             :select="select"
                             :start_state="'row'" :default_state="'row'" @select="selectObject"
                             :parent="parent"> group </reserve-grouped>
          </tr>
          <tr v-if="!groupBy && fitFilter(object)" v-for="(object, index) in objects" :key="index" >
            <td>
              <reserve v-if="object.id > 0" :start_object="object" :start_state="'row'" :default_state="'row'"
                        :select="select" @select="selectObject" @updated="addToList" @drop="drop(index)"> existing </reserve>
            </td>
          </tr>
          <tr v-if="downloading && downloading.state">
            <downloading :downloading="downloading">
              Downloading
            </downloading>
          </tr>
          </tbody>
        </table>
      </card>
    </div>
  </div>
</template>
<script>
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import {authHeader} from "@/auth";
import Item from "../elements/Item";
import {sorter, fitFilter, updateFilter} from '@/services'
import SidebarPlugin from "@/components";

export default {
  components: {
    SidebarPlugin,
    LTable,
    Card
  },
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'cell.item.name', asc: true},
      filter: new Map,
      tableKey: 0,
      groups: {},
      newObject: null,
      viewKey: 0
    }
  },
  props: {
    objects: [],
    header: null,
    headerTitle: null,
    select: false,
    parent: null,
    groupBy: null,
    defaultValues: null,
    downloading: null,
  },
  methods: {
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    async addNew() {
      if (!this.newObject) {
        let url = 'reserve/model';
        if (this.parent) {
          url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
        }
        let obj = await this.$repo.justGetData(url, this.downloading);
        if (this.defaultValues) {
          obj[this.defaultValues.field] = this.defaultValues.value;
        }
        this.newObject = obj;
      }
    },
    addToList(object) {
      if (this.groupBy) {
        this.addToGroup(object);
        this.newObject = null;
        this.tableKey++;
      } else {
        if (object && object.id > 0) {
          let notExist = true;
          for (let i = 0; i < this.objects.length; i++) {
            if (this.objects[i].id === object.id) {
              let val = object.value - this.objects[i].value;
              this.objects[i] = object;
              notExist = false;
              this.$emit("val", val)
              break;
            }
          }
          if (notExist) {
            this.newObject = null;
            this.objects.unshift(object);
            this.$emit("val", object.value)
          }
        }
      }
    },
    drop(index) {
      if (index === 0) {
        this.objects.shift();
      } else if (index === this.objects.length - 1) {
        this.objects.pop();
      } else {
        for (let i = index; i < this.objects.length - 1; i++) {
          this.objects[i] = this.objects[i + 1];
        }
        this.objects.pop();
      }
    },
    cancelNew() {
      this.newObject = null;
    },
    selectObject(object) {
      this.$emit('select', object);
    },
    headerClick() {
      if (this.select) {
        this.$emit('closeModal');
      } else {
        this.$emit('element');
        this.$emit('update');
      }
    },
    addToGroup(obj) {
      let grouper = obj[this.groupBy] ? this.groupBy : "order";
      let val = grouper + obj[grouper].id + ':' + obj.cell.item.id;
      if (!this.groups[val]) {
        this.groups[val] = {
          value: 0,
          item: obj.cell.item,
          cell: obj.cell,
          assembly: obj.assembly,
          reserves: [],
          groupBy: grouper,
          defaultVal: obj[grouper],
          defaultField: grouper
        }
      }
      this.groups[val].reserves.unshift(obj);
      this.groups[val].value = Number((this.groups[val].value + obj.value).toFixed(3));
    }
  },
  mounted() {
    if (this.groupBy) {
      this.objects.forEach(obj => this.addToGroup(obj));
    }
    this.tableKey++;
  },
  watch: {
    sortBy: function () {
      this.sort()
    },
    objects: function (){
      if (this.groupBy) {
        this.objects.forEach(obj => this.addToGroup(obj));
      }
      this.tableKey++;
    }
  }
}
</script>
<style>
</style>
