<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="object && item" class="row row-title" v-on:click="fullView()">
      <div class="col-4 unselectable">
        {{item.name}}
      </div>
      <div class="col-2 unselectable">
        <span style="color: #23262f">
          {{object.ordered}}
        </span>
        <span>
          {{' / '}}
        </span>
        <span style="color: #1b670a">
          {{object.shipped}}
        </span>
      </div>
      <div class="col-2 unselectable">
        <span style="color: #23262f">
          {{object.processing}}
        </span>
        <span>
          {{' / '}}
        </span>
        <span style="color: #1b670a">
          {{object.ready}}
        </span>
      </div>
      <div class="col-2 unselectable">
        <span style="color: #06327d">
          {{object.stockData}}
        </span>
        <span>
          {{' / '}}
        </span>
        <span style="color: grey">
          {{object.inStock}}
        </span>
        <span v-if="item.minimum">
          {{' / '}}
        </span>
        <span v-if="item.minimum" :style="item.minimum > object.stockData ? 'color: red' : 'color: grey'">
          {{item.minimum}}
        </span>
      </div>
      <div class="col-2 unselectable">
        <span v-on:click="makeShowPlans">
          {{ object.invoiceData }}
        </span>
      </div>
      <div v-if="showPlans" class="col-12 unselectable">

          <plan-table @element="makeShowPlans" :header="'Распределение'"
                      :header-title="'По счетам'"
                      :objects="plans">Plans</plan-table>

      </div>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {CELL, ITEM, PLAN} from "@/repository/repository";

export default {
  components: {
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      reserves: [],
      showReserve: false,
      id: null,
      selectPool: [],
      asParent: {},
      viewKey: 0,
      flag: this.$flag,
      item: null,
      showPlans: false,
      plans: null,
      downloading: {state: false, select: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else if (this.object.id > -100) {
        this.$emit('close', true);
      }
    },
    hideElement(element) {
      if (element === 'reserve') {
        this.showReserve = false;
        this.reserves = [];
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    async makeShowPlans() {
      if (this.showPlans) {
        this.showPlans = false
      } else {
        if (this.object.plansIds && this.object.plansIds.length > 0) {
          this.plans = [];
          await this.object.plansIds.forEach(async id => {
            let plan = await this.$repo.get(PLAN, id, this.downloading);
            this.plans.push(plan);
          }, Promise.resolve());
          this.showPlans = true;
        }
      }
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    callModal() {
      let modal = document.getElementById(this.id);
      modal.style.display = "block";
      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      }
    },
    closeModal() {
      let modal = document.getElementById(this.id);
      modal.style.display = "none";
    },
    async openSelectModal() {
      this.selectPool = [];
      let url = 'stockcell/';
      if (this.parent) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      await this.$repo.getList(url, CELL, this.selectPool, this.downloading.select);
      this.callModal();
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    if (this.start_object) {
      this.object = (this.start_object.id && this.start_object.id > 0) ? await this.$repo.get(CELL, [this.start_object.id], this.downloading) : this.start_object;
    }
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
    this.item = await this.$repo.get(ITEM, this.object.item.id, this.downloading);
  },
  watch: {
    start_object: function () {
      this.viewKey++;
    },
    object: function () {
      this.asParent = {type: CELL, id: this.object ? this.object.id : 0}
    },
    state: function (value) {
      this.$repo.watchState(this, CELL, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, CELL, val);
      if (val.type === ITEM && val.id === this.object.item.id) {
        this.item = this.$repo.get(ITEM, val.id, this.downloading);
      }
    }
  }
}
</script>
<style>
</style>
