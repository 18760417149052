<template>
      <div class="row">
        <div class="col-12">
          <card body-classes="table-full-width" style="margin: 5px">
            <template slot="header">
              <h4 class="card-title unselectable" v-on:click="hide()">{{ header}}</h4>
              <p class="card-category">{{ headerTitle }}</p>
            </template>
            <table class="table table-hover table-striped">
              <thead>
              <slot name="columns">
                <tr>
                  <th class="row-title">
                    <div class="row" style="width: 100%">
                      <div class="col-4 unselectable">
                        <span v-on:click="sortBy = {key: 'item.name', asc: !sortBy.asc}">
                          Наименование
                        </span>
                      </div>
                      <div class="col-4 unselectable">
                        <span v-on:click="sortBy = {key: 'item.category.name', asc: !sortBy.asc}">
                          Категория
                        </span>
                        <item-category @filter="catchFilter" v-bind:field="'item.category'"> Category Selector </item-category>
                      </div>
                      <div class="col-2 unselectable">
                        Количество
                      </div>
                      <div class="col-2 unselectable">
                        Единица измерения
                      </div>
                    </div>
                  </th>
                </tr>
              </slot>
              </thead>
              <tbody :key="tableKey">
              <tr v-for="(object, index) in objects" :key="index">
                <td>
                  <reserve-need v-if="!object.id || object.id === 0"  :object="object" :state="'row'"></reserve-need>
                </td>
              </tr>
              <tr v-if="downloading && downloading.state">
                <downloading :downloading="downloading">
                  Downloading
                </downloading>
              </tr>
              </tbody>
            </table>
          </card>
        </div>
      </div>
</template>
<script>
import Card from 'src/components/Cards/Card.vue'
import {authHeader} from "@/auth";
import ItemContent from "../elements/ItemContent";
import ItemCategory from "../elements/ItemCategory";
import {sorter, fitFilter, updateFilter} from '@/services'

export default {
  name: 'contents-table',
  components: {
    ItemContent,
    Card,
    ItemCategory
  },
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'item.name', asc: true},
      filter: new Map,
      tableKey: 0
    }
  },
  props: {
    objects: null,
    header: null,
    headerTitle: null,
    parent: null,
    downloading: null,
  }
  ,
  methods: {
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    fitFilter(object) {
      return fitFilter(object, this.filter)
    },
    catchFilter(filter) {
      this.filter = updateFilter(this.filter, filter);
      this.tableKey += 1;
    },
    hide() {
      this.$emit('element');
    }
  },
  watch: {
    sortBy: function () {
     // this.sort()
    },
    contents: function () {
      this.tableKey += 1;
    }
  }
}
</script>
<style>
</style>
